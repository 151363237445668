import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import './BuyTokenWizard.css';
import MFTTokenAbi from '../../assets/tokenabi/MFTTokenAbi.json';
import Web3 from 'web3';
import Select from 'react-select';
import Parse from 'parse';
import { 
  FaTimes, 
  FaWallet, 
  FaCoins, 
  FaCheck, 
  FaExclamationTriangle, 
  FaRegLightbulb, 
  FaArrowLeft, 
  FaArrowRight, 
  FaExternalLinkAlt,
  FaBitcoin,
  FaEthereum,
  FaUsers,
  FaStar,
  FaGem,
  FaChartLine
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

// Dil çevirileri
const translations = {
  tr: {
    welcome: "Hoş Geldiniz",
    connectWalletDescription: "Satın almak için önce cüzdanınızı bağlamanız gerekiyor.",
    connectWallet: "Cüzdanı Bağla",
    connected: "Bağlandı",
    next: "İleri",
    buyMFT: "MFT Satın Al",
    howManyMFT: "Kaç MFT satın almak istiyorsunuz?",
    paymentMethod: "Ödeme Yöntemi",
    equivalent: "Karşılık:",
    usdEquivalent: "USD Karşılığı:",
    back: "Geri",
    buy: "Satın Al",
    transactionComplete: "İşlem Tamamlandı",
    purchaseSuccessful: "MFT token satın alma işlemi başarılı.",
    amountToPay: "Ödenecek Tutar:",
    gasFee: "Gas Ücreti:",
    totalAmount: "Toplam Ödenecek:",
    proceedToPayment: "Ödeme Adımına Geç",
    minimum1000: "Minimum 1000 MFT satın almalısınız.",
    walletNotFound: "Cüzdan bulunamadı. Lütfen MetaMask yükleyin.",
    walletConnectionError: "Cüzdan bağlantısında hata. Lütfen tekrar deneyin.",
    selectAccount: "Hesap seçin",
    account: "Hesap",
    continue: "Devam Et",
    selectAccountDescription: "Lütfen hesabınızı seçerek devam edin",
    close: "Kapat",
    welcomePackage: "Merhaba Paketi",
    friendPackage: "Arkadaş Olalım Paketi",
    investorPackage: "Yatırımcı Paketi",
    bonusAmount: "Bonus MFT",
    connectYourWallet: "Cüzdanınızı Bağlayın",
    metamaskWallet: "MetaMask Cüzdanı",
    mostPopularWallet: "En popüler Ethereum cüzdanı",
    walletConnectCompatible: "WalletConnect Uyumlu",
    otherPopularWallets: "Diğer popüler cüzdanlar",
    purchaseSummary: "Satın Alma Özeti",
    tokenAmount: "Token Miktarı",
    exchangeRate: "Kur Oranı",
    youWillReceive: "Alacağınız Token",
    viewOnExplorer: "Explorer'da Görüntüle",
    transactionId: "İşlem ID",
    bnbSent: "Gönderilen BNB",
    processPayment: "Ödemeyi İşle",
    doYouWantBonus: "Bonus istiyor musunuz?",
    selectPackage: "Paket Seçin",
    orSelectCustomAmount: "veya İstediğiniz Miktarı Seçin",
    premiumPackage: "Premium Paket",
    standardPackage: "Standart Paket",
    customPackage: "Özel Miktar",
    joinNetwork: "MFT Network'e Katılın",
    includesNetworkAccess: "MFT Network'e Katılım",
    bonus: "Bonus",
    enterEmail: "Email adresinizi girin",
    joinNetworkDescription: "Network'e katılmak için e-posta adresinizi girin",
    emailAdded: "E-posta adresiniz kaydedildi. Network'e hoş geldiniz!",
    networkJoinSuccess: "MFT Network'e başarıyla katıldınız!",
    submitEmail: "Kaydet ve Devam Et",
    networkBenefits: "Network Avantajları",
    loginToNetwork: "Network'e Giriş Yap",
    packageSelection: "Paket Seçimi",
    dollarAmount: "$ Değeri",
    customAmount: "İstediğiniz Miktarı Girin"
  },
  en: {
    welcome: "Welcome",
    connectWalletDescription: "To buy, you need to connect your wallet first.",
    connectWallet: "Connect Wallet",
    connected: "Connected",
    next: "Next",
    buyMFT: "Buy MFT",
    howManyMFT: "How many MFT would you like to buy?",
    paymentMethod: "Payment Method",
    equivalent: "Equivalent:",
    usdEquivalent: "USD Equivalent:",
    back: "Back",
    buy: "Buy",
    transactionComplete: "Transaction Complete",
    purchaseSuccessful: "MFT token purchase was successful.",
    amountToPay: "Amount to Pay:",
    gasFee: "Gas Fee:",
    totalAmount: "Total Amount:",
    proceedToPayment: "Proceed to Payment",
    minimum1000: "You must buy a minimum of 1000 MFT.",
    walletNotFound: "Wallet not found. Please install MetaMask.",
    walletConnectionError: "Error connecting wallet. Please try again.",
    selectAccount: "Select an account",
    account: "Account",
    continue: "Continue",
    selectAccountDescription: "Please select your account to continue",
    close: "Close",
    welcomePackage: "Welcome Package",
    friendPackage: "Friend Package",
    investorPackage: "Investor Package",
    bonusAmount: "Bonus MFT",
    connectYourWallet: "Connect Your Wallet",
    metamaskWallet: "MetaMask Wallet",
    mostPopularWallet: "Most popular Ethereum wallet",
    walletConnectCompatible: "WalletConnect Compatible",
    otherPopularWallets: "Other popular wallets",
    purchaseSummary: "Purchase Summary",
    tokenAmount: "Token Amount",
    exchangeRate: "Exchange Rate",
    youWillReceive: "You Will Receive",
    viewOnExplorer: "View on Explorer",
    transactionId: "Transaction ID",
    bnbSent: "BNB Sent",
    processPayment: "Process Payment",
    doYouWantBonus: "Would you like a bonus?",
    selectPackage: "Select Package",
    orSelectCustomAmount: "or Select Custom Amount",
    premiumPackage: "Premium Package",
    standardPackage: "Standard Package",
    customPackage: "Custom Amount",
    joinNetwork: "Join MFT Network",
    includesNetworkAccess: "Includes Network Access",
    bonus: "Bonus",
    enterEmail: "Enter your email",
    joinNetworkDescription: "Enter your email to join the network",
    emailAdded: "Your email has been added. Welcome to the network!",
    networkJoinSuccess: "You have successfully joined the MFT Network!",
    submitEmail: "Save and Continue",
    networkBenefits: "Network Benefits",
    loginToNetwork: "Login to Network",
    packageSelection: "Package Selection",
    dollarAmount: "$ Value",
    customAmount: "Enter Custom Amount"
  },
  de: {
    welcome: "Willkommen",
    connectWalletDescription: "Um zu kaufen, müssen Sie zuerst Ihre Wallet verbinden.",
    connectWallet: "Wallet verbinden",
    connected: "Verbunden",
    next: "Weiter",
    buyMFT: "MFT kaufen",
    howManyMFT: "Wie viele MFT möchten Sie kaufen?",
    paymentMethod: "Zahlungsmethode",
    equivalent: "Gegenwert:",
    usdEquivalent: "USD-Gegenwert:",
    back: "Zurück",
    buy: "Kaufen",
    transactionComplete: "Transaktion abgeschlossen",
    purchaseSuccessful: "Der Kauf von MFT-Token war erfolgreich.",
    amountToPay: "Zu zahlender Betrag:",
    gasFee: "Gasgebühr:",
    totalAmount: "Gesamtbetrag:",
    proceedToPayment: "Weiter zur Zahlung",
    minimum1000: "Sie müssen mindestens 1000 MFT kaufen.",
    walletNotFound: "Wallet nicht gefunden. Bitte installieren Sie MetaMask.",
    walletConnectionError: "Fehler beim Verbinden der Wallet. Bitte versuchen Sie es erneut.",
    selectAccount: "Konto auswählen",
    account: "Konto",
    continue: "Weiter",
    selectAccountDescription: "Bitte wählen Sie Ihr Konto aus, um fortzufahren",
    close: "schließen",
    welcomePackage: "Willkommenspaket",
    friendPackage: "Freundschaftspaket",
    investorPackage: "Anlegerpaket",
    bonusAmount: "Bonus MFT",
    connectYourWallet: "Verbinden Sie Ihre Wallet",
    metamaskWallet: "MetaMask Wallet",
    mostPopularWallet: "Beliebteste Ethereum-Wallet",
    walletConnectCompatible: "WalletConnect-kompatibel",
    otherPopularWallets: "Andere beliebte Wallets",
    purchaseSummary: "Kaufübersicht",
    tokenAmount: "Token-Betrag",
    exchangeRate: "Wechselkurs",
    youWillReceive: "Sie erhalten",
    viewOnExplorer: "Im Explorer anzeigen",
    transactionId: "Transaktions-ID",
    bnbSent: "BNB gesendet",
    processPayment: "Zahlung verarbeiten",
    doYouWantBonus: "Möchten Sie einen Bonus?",
    selectPackage: "Paket auswählen",
    orSelectCustomAmount: "oder benutzerdefinierte Menge wählen",
    premiumPackage: "Premium-Paket",
    standardPackage: "Standard-Paket",
    customPackage: "Benutzerdefinierte Menge",
    joinNetwork: "Dem MFT-Netzwerk beitreten",
    includesNetworkAccess: "Inklusive Netzwerkzugang",
    bonus: "Bonus",
    enterEmail: "E-Mail eingeben",
    joinNetworkDescription: "Geben Sie Ihre E-Mail ein, um dem Netzwerk beizutreten",
    emailAdded: "Ihre E-Mail wurde hinzugefügt. Willkommen im Netzwerk!",
    networkJoinSuccess: "Sie sind dem MFT-Netzwerk erfolgreich beigetreten!",
    submitEmail: "Speichern und Fortfahren",
    networkBenefits: "Netzwerkvorteile",
    loginToNetwork: "Anmeldung am Netzwerk",
    packageSelection: "Paketauswahl",
    dollarAmount: "$ Wert",
    customAmount: "Benutzerdefinierte Menge eingeben"
  }
};

const mockPrices = {
  binancecoin: { usd: 300 },
  ethereum: { usd: 2000 }
};

const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  if (language.startsWith('tr')) return 'tr';
  if (language.startsWith('de')) return 'de';
  return 'en';
};

const language = getBrowserLanguage();
const t = translations[language];

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const WalletStep = ({ previousStep, nextStep, setConnected, setAccount }) => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [error, setError] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checkWalletConnection();
  }, []);

  const checkWalletConnection = async () => {
    if (typeof window.ethereum !== 'undefined') {
      const web3 = new Web3(window.ethereum);
      try {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setWalletConnected(true);
          setAccounts(accounts.map((account, index) => ({
            value: account,
            label: `${t.account} ${index + 1}: ${account.slice(0, 6)}...${account.slice(-4)}`
          })));
        }
      } catch (error) {
        console.error("Error checking wallet connection:", error);
      }
    }
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      setIsLoading(true);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccounts(accounts.map((account, index) => ({
            value: account,
            label: `${t.account} ${index + 1}: ${account.slice(0, 6)}...${account.slice(-4)}`
          })));
          setWalletConnected(true);
          setError('');
        } else {
          setError(t.walletNotFound);
        }
      } catch (error) {
        console.error("Wallet connection error:", error);
        setError(t.walletConnectionError);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError(t.walletNotFound);
    }
  };

  const handleAccountSelection = (selectedOption) => {
    setSelectedAccount(selectedOption);
    setAccount(selectedOption.value);
    setConnected(true);
  };

  return (
    <div className="token-wizard-body">
      <h2 className="token-wizard-step-title">{t.connectYourWallet}</h2>
      <p className="token-wizard-step-description">{t.connectWalletDescription}</p>
      
      {!walletConnected ? (
        <motion.div
          className="wallet-connect-options"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.div 
            className="wallet-option"
            onClick={connectWallet}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="wallet-option-icon">
              <FaWallet />
            </div>
            <div className="wallet-option-info">
              <div className="wallet-option-name">{t.metamaskWallet}</div>
              <div className="wallet-option-description">{t.mostPopularWallet}</div>
            </div>
          </motion.div>
          
          <motion.div 
            className="wallet-option"
            onClick={connectWallet}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="wallet-option-icon">
              <FaCoins />
            </div>
            <div className="wallet-option-info">
              <div className="wallet-option-name">{t.walletConnectCompatible}</div>
              <div className="wallet-option-description">{t.otherPopularWallets}</div>
            </div>
          </motion.div>
        </motion.div>
      ) : (
        <motion.div
          className="account-select-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <p className="token-wizard-step-description">{t.selectAccountDescription}</p>
          <Select
            options={accounts}
            value={selectedAccount}
            onChange={handleAccountSelection}
            placeholder={t.selectAccount}
            className="account-select"
            classNamePrefix="account-select"
          />
        </motion.div>
      )}
      
      {error && (
        <motion.div 
          className="token-wizard-error"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <FaExclamationTriangle className="token-wizard-error-icon" />
          {error}
        </motion.div>
      )}
      
      {isLoading && (
        <div className="token-wizard-loading">
          <div className="loading-spinner"></div>
          <p className="loading-text">Cüzdan bağlanıyor...</p>
        </div>
      )}

      <div className="token-wizard-footer">
        {previousStep && (
          <motion.button 
            className="token-wizard-button secondary"
            onClick={previousStep}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            disabled={isLoading}
          >
            <FaArrowLeft /> {t.back}
          </motion.button>
        )}
        
        {walletConnected && selectedAccount ? (
          <motion.button 
            className="token-wizard-button"
            onClick={nextStep}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            disabled={!selectedAccount}
          >
            {t.continue} <FaArrowRight />
          </motion.button>
        ) : (
          !walletConnected && !previousStep && (
            <motion.button 
              className="token-wizard-button"
              onClick={connectWallet}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={isLoading}
            >
              {t.connectWallet} <FaWallet />
            </motion.button>
          )
        )}
      </div>
    </div>
  );
};

const PurchaseStep = ({ previousStep, nextStep, amount, setAmount, selectedToken, setSelectedToken, tokenEquivalent, setTokenEquivalent, mftToUsdRate, prices, setPrices, account, selectedPackage, joinNetwork, setTransactionData }) => {
  const recipientAddress = "0x0A93b1B79d90B8c2b6C987012287A2e4fBeFEA4E";
  const contractAddress = "0x1523930EdC9255B33392288f43Fd3B63d7aed7Cb";
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [gasEstimate, setGasEstimate] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState('');
  const [bonusAmount, setBonusAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');

  useEffect(() => {
    setPrices(mockPrices);
    updateTokenEquivalent(amount, mockPrices);
    
    if (selectedPackage?.bonus) {
      const tokenAmount = parseFloat(amount.replace(/,/g, ''));
      setBonusAmount(Math.floor(tokenAmount * (selectedPackage.bonus / 100)));
    }
  }, []);

  useEffect(() => {
    updateTokenEquivalent(amount, prices);
    const numAmount = parseFloat(amount.replace(/,/g, ''));
    // Token minimum limiti 
    setIsAmountValid(!isNaN(numAmount) && numAmount >= 1000);
    estimateGas();
  }, [amount, selectedToken, prices]);


  const saveTransferToParseDB = async (walletId, mftAmount, bnbAmount) => {
    try {
      const Transfer = Parse.Object.extend("Transfer");
      const transfer = new Transfer();

      transfer.set("walletId", walletId);
      transfer.set("mftAmount", mftAmount);
      transfer.set("bnbAmount", bnbAmount);
      transfer.set("date", new Date());

      await transfer.save();
      console.log('Transfer saved to Parse DB successfully');
    } catch (error) {
      console.error('Error saving transfer to Parse DB:', error);
    }
  };

  
  const updateTokenEquivalent = (newAmount, currentPrices) => {
    if (!currentPrices) return;

    const numAmount = parseFloat(newAmount.replace(/,/g, ''));
    if (!isNaN(numAmount) && currentPrices && selectedToken) {
      const selectedTokenPrice = currentPrices[selectedToken === 'bnb' ? 'binancecoin' : 'ethereum']?.usd;
      if (selectedTokenPrice) {
        // MFT miktarı * MFT USD fiyatı / seçili kripto USD fiyatı
        const equivalent = (numAmount * mftToUsdRate) / selectedTokenPrice;
        setTokenEquivalent(equivalent);
        
        // Gas ücretini de ekleyerek toplam tutarı hesapla
        const gasEstimateInToken = gasEstimate;
        setTotalPaymentAmount(equivalent + gasEstimateInToken);
      }
    }
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleAmountChange = (event) => {
    const newAmount = event.target.value.replace(/,/g, '');
    setAmount(formatNumber(newAmount));
    const numAmount = parseFloat(newAmount);
    setIsAmountValid(!isNaN(numAmount) && numAmount >= 1000);
    
    if (selectedPackage?.bonus) {
      setBonusAmount(Math.floor(numAmount * (selectedPackage.bonus / 100)));
    }
  };

  const handleTokenSelection = (token) => {
    setSelectedToken(token);
  };

  const estimateGas = async () => {
    if (isAmountValid && window.ethereum && account) {
      const web3 = new Web3(window.ethereum);
      try {
        const gasPrice = await web3.eth.getGasPrice();
        const gasLimit = 21000; // Temel transfer için gas limiti
        const gasCost = web3.utils.fromWei((gasPrice * gasLimit).toString(), 'ether');
        setGasEstimate(parseFloat(gasCost));
      } catch (error) {
        console.error("Gas estimate error:", error);
        setGasEstimate(0.000030); // Fallback gas ücreti
      }
    }
  };

  const addTokenToWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const tokenAddress = '0x1523930EdC9255B33392288f43Fd3B63d7aed7Cb';
        const tokenSymbol = 'MFT';
        const tokenDecimals = 18;
        const tokenImage = 'https://mftcoin.com/path/to/mft_logo.png';
  
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
  
        if (wasAdded) {
          console.log('MFT Token successfully added to wallet');
        } else {
          console.log('MFT Token was not added to wallet');
        }
      } catch (error) {
        console.error('Error adding token to wallet:', error);
        alert('Failed to add MFT token to your wallet. You can add it manually.');
      }
    }
  };

  const handleBuyTokens = async () => {
    try {
      setIsLoading(true);
      setPurchaseStatus('Initiating transaction...');
      const web3 = new Web3(window.ethereum);
  
      const weiAmount = web3.utils.toWei(totalPaymentAmount.toString(), 'ether');
      const mftAmount = web3.utils.toWei(amount.replace(/,/g, ''), 'ether');
  
      setPurchaseStatus('Transferring BNB payment...');
      let paymentResult;
      try {
        paymentResult = await web3.eth.sendTransaction({
          from: account,
          to: recipientAddress,
          value: weiAmount
        });
        console.log("Payment result:", paymentResult);
        setTransactionHash(paymentResult.transactionHash);

        await saveTransferToParseDB(account, amount, totalPaymentAmount);

      } catch (txError) {
        console.error("Transaction error:", txError);
        throw new Error(`BNB transfer failed: ${txError.message}. Please check your wallet and try again.`);
      }
  
      setPurchaseStatus('Processing payment and transferring MFT tokens...');
      let apiResponse;
      try {
        apiResponse = await fetch('https://www.myfacecoin.net/api/sendmfttokens', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            account,
            amount: mftAmount,
            paymentTxHash: paymentResult.transactionHash
          })
        });
      } catch (fetchError) {
        console.error("Fetch error:", fetchError);
        throw new Error(`Failed to communicate with the server: ${fetchError.message}. Please try again later.`);
      }
  
      if (!apiResponse.ok) {
        const errorText = await apiResponse.text();
        console.error("API response error text:", errorText);
        throw new Error(`API response error: ${errorText}`);
      }
  
      const apiResult = await apiResponse.json();
  
      if (!apiResult.success) {
        console.error("API error response:", apiResult);
        throw new Error(apiResult.error || 'Unknown server error');
      }
  
      if (apiResult.success) {
        setPurchaseStatus('Transaction complete!');
        await addTokenToWallet();
        setIsLoading(false);
        setTransactionData({
          transactionHash: paymentResult.transactionHash,
          tokenAmount: amount,
          tokenBonus: bonusAmount,
          paymentAmount: totalPaymentAmount,
          paymentCurrency: selectedToken.toUpperCase(),
          joinNetwork: joinNetwork
        });
        nextStep({
          transactionHash: paymentResult.transactionHash,
          tokenAmount: amount,
          tokenBonus: bonusAmount,
          paymentAmount: totalPaymentAmount,
          paymentCurrency: selectedToken.toUpperCase(),
          joinNetwork: joinNetwork
        });
      } else {
        throw new Error(apiResult.message || "Token transfer failed for an unknown reason");
      }
    } catch (error) {
      console.error("Token purchase failed:", error);
      setPurchaseStatus('Transaction failed. Please try again.');
      setIsLoading(false);
      
      // Error mesajını bir div içinde göstermek yerine alert kullanıyorum
      alert(`Error: ${error.message}`);
    }
  };  
  
  // Yeni eklenen test fonksiyonu - ödeme simülasyonu yapacak
  const simulateBuyTokens = async () => {
    try {
      setIsLoading(true);
      setPurchaseStatus('İşlem başlatılıyor...');
      
      // Debug log
      console.log("[SIMULATE] Başlangıç bilgileri:", {
        joinNetwork,
        selectedPackage,
        amount
      });
      
      // 2 saniye bekle
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setPurchaseStatus('BNB ödemesi aktarılıyor...');
      
      // 3 saniye bekle
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      // Rastgele bir transaction hash oluştur
      const mockTransactionHash = '0x' + Array(64).fill(0).map(() => 
        Math.floor(Math.random() * 16).toString(16)).join('');
      setTransactionHash(mockTransactionHash);
      
      // Sayısal değerleri hesapla
      const numericAmount = parseFloat(amount.replace(/,/g, ''));
      const numericBonus = selectedPackage?.bonus 
        ? Math.floor(numericAmount * (selectedPackage.bonus / 100)) 
        : 0;
      
      // Paket tipini belirle
      let packageType = 0;
      if (numericAmount >= 1000000) {
        packageType = 1; // Premium paket
      } else if (numericAmount >= 500000) {
        packageType = 2; // Standart paket
      } else {
        packageType = 3; // Özel miktar
      }
      
      console.log("[SIMULATE] Hesaplanan sayısal değerler:", {
        numericAmount,
        numericBonus,
        packageType,
        joinNetwork
      });
      
      // Sahte veritabanı kaydı logla
      console.log('[SIMULATE] Transfer verileri:', {
        walletId: account,
        mftAmount: numericAmount,
        bnbAmount: totalPaymentAmount,
        bonusAmount: numericBonus,
        packageType: packageType,
        date: new Date(),
        joinNetwork: joinNetwork
      });
      
      setPurchaseStatus('Ödeme işleniyor ve MFT tokenleri transfer ediliyor...');
      
      // 3 saniye daha bekle
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      setPurchaseStatus('İşlem tamamlandı!');
      
      // Token'ı simüle et
      console.log('[SIMULATE] Token wallet\'a ekleniyor (simülasyon)');
      
      // Biraz daha bekle ve sonraki adıma geç
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setIsLoading(false);
      
      // Transaction Details - Sayısal değerler
      const transactionDetails = {
        transactionHash: mockTransactionHash,
        tokenAmount: numericAmount,  // Doğrudan sayısal değer
        tokenBonus: numericBonus,    // Doğrudan sayısal değer
        paymentAmount: totalPaymentAmount,
        paymentCurrency: selectedToken.toUpperCase(),
        joinNetwork: joinNetwork,
        packageType: packageType     // Paket tipini ekle
      };
      
      console.log("[SIMULATE] Sonraki adıma aktarılacak veriler:", transactionDetails);
      
      // Global olarak transactionData'yı kaydet (diğer bileşenler için)
      window.currentTransactionData = transactionDetails;

      // Diğer bileşenlerin transactionData'ya erişebilmesi için global state'i düzgün şekilde güncelle
      setTransactionData(transactionDetails);
      
      // Bir sonraki adıma geç
      nextStep(transactionDetails);
      
    } catch (error) {
      console.error("[SIMULATE] Hata:", error);
      setPurchaseStatus('İşlem başarısız. Lütfen tekrar deneyin.');
      setIsLoading(false);
      
      // Error mesajını göster
      alert(`Hata: ${error.message}`);
    }
  };
  
  return (
    <div className="token-wizard-body">
      <h2 className="token-wizard-step-title">{t.buyMFT}</h2>
      
      {isLoading ? (
        <div className="token-wizard-loading">
          <div className="loading-spinner"></div>
          <p className="loading-text">{purchaseStatus}</p>
        </div>
      ) : (
        <>
          {selectedPackage && (
            <div className="selected-package-info">
              <div className="selected-package-title">{selectedPackage.name}</div>
              <div className="selected-package-details">
                <div className="package-amount-display">
                  <span>{amount}</span>
                  <span className="package-currency">MFT</span>
                </div>
                
                {selectedPackage.bonus > 0 && (
                  <div className="token-bonus-info">
                    <FaRegLightbulb className="token-bonus-icon" />
                    {t.bonusAmount}: {formatNumber(bonusAmount)} MFT
                  </div>
                )}
              </div>
            </div>
          )}
          
          {!selectedPackage && (
            <div className="token-input-container">
              <label className="token-input-label">{t.howManyMFT}</label>
              <div className="token-input-wrapper">
                <input
                  type="text"
                  className="token-input"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder="0"
                />
                <span className="token-symbol">MFT</span>
              </div>
              
              {!isAmountValid && amount && (
                <motion.div 
                  className="token-wizard-error"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <FaExclamationTriangle className="token-wizard-error-icon" />
                  {t.minimum1000}
                </motion.div>
              )}
            </div>
          )}
          
          <div className="payment-methods">
            <motion.div
              className={`payment-method ${selectedToken === 'bnb' ? 'selected' : ''} ${isAmountValid ? '' : 'disabled'}`}
              onClick={() => isAmountValid && handleTokenSelection('bnb')}
              whileHover={isAmountValid ? { scale: 1.02 } : {}}
              whileTap={isAmountValid ? { scale: 0.98 } : {}}
            >
              <FaBitcoin /> BNB
            </motion.div>
            <motion.div
              className={`payment-method ${selectedToken === 'eth' ? 'selected' : ''} ${isAmountValid ? '' : 'disabled'}`}
              onClick={() => isAmountValid && handleTokenSelection('eth')}
              whileHover={isAmountValid ? { scale: 1.02 } : {}}
              whileTap={isAmountValid ? { scale: 0.98 } : {}}
            >
              <FaEthereum /> ETH
            </motion.div>
          </div>
          
          {isAmountValid && selectedToken && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="payment-summary"
            >
              <div className="summary-row">
                <span className="summary-label">{t.tokenAmount}:</span>
                <span className="summary-value">{amount} MFT</span>
              </div>
              
              {bonusAmount > 0 && (
                <div className="summary-row">
                  <span className="summary-label">{t.bonusAmount}:</span>
                  <span className="summary-value">{formatNumber(bonusAmount)} MFT</span>
                </div>
              )}
              
              <div className="summary-row">
                <span className="summary-label">{t.exchangeRate}:</span>
                <span className="summary-value">1 MFT = ${mftToUsdRate}</span>
              </div>
              
              <div className="summary-row">
                <span className="summary-label">{t.usdEquivalent}:</span>
                <span className="summary-value">${(parseFloat(amount.replace(/,/g, '')) * mftToUsdRate).toFixed(2)}</span>
              </div>
              
              <div className="summary-row">
                <span className="summary-label">{t.amountToPay}:</span>
                <span className="summary-value">{tokenEquivalent.toFixed(8)} {selectedToken.toUpperCase()}</span>
              </div>
              
              <div className="summary-row">
                <span className="summary-label">{t.gasFee}:</span>
                <span className="summary-value">{gasEstimate.toFixed(8)} {selectedToken.toUpperCase()}</span>
              </div>
              
              <div className="summary-divider"></div>
              
              <div className="summary-total-row">
                <span className="summary-total-label">{t.totalAmount}:</span>
                <span className="summary-total-value">{totalPaymentAmount.toFixed(8)} {selectedToken.toUpperCase()}</span>
              </div>
            </motion.div>
          )}
        </>
      )}
      
      <div className="token-wizard-footer">
        <motion.button 
          className="token-wizard-button secondary"
          onClick={previousStep}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          disabled={isLoading}
        >
          <FaArrowLeft /> {t.back}
        </motion.button>
        
        {isAmountValid && selectedToken && !isLoading && (
          <motion.button 
            className="token-wizard-button"
            onClick={simulateBuyTokens}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {t.processPayment} <FaArrowRight />
          </motion.button>
        )}
      </div>
    </div>
  );
};

const NetworkJoinStep = ({ previousStep, nextStep, transactionData, account }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  
  // Komponent yüklendiğinde transaction verilerini kontrol et
  useEffect(() => {
    console.log("[NETWORK_JOIN] TransactionData:", JSON.stringify(transactionData));
    console.log("[NETWORK_JOIN] Account:", account);
    
    // TransactionData kontrol
    if (transactionData) {
      if (transactionData.tokenAmount !== undefined) {
        console.log("[NETWORK_JOIN] Token Amount Type:", typeof transactionData.tokenAmount);
        console.log("[NETWORK_JOIN] Token Amount Value:", transactionData.tokenAmount);
      }
      
      if (transactionData.tokenBonus !== undefined) {
        console.log("[NETWORK_JOIN] Bonus Amount Type:", typeof transactionData.tokenBonus);
        console.log("[NETWORK_JOIN] Bonus Amount Value:", transactionData.tokenBonus);
      }
      
      if (transactionData.packageType !== undefined) {
        console.log("[NETWORK_JOIN] Package Type:", transactionData.packageType);
      }
    }
  }, [transactionData, account]);
  
  // Form alanlarını doğrula
  useEffect(() => {
    const emailIsValid = validateEmail(email);
    const passwordIsValid = password.length >= 6;
    const passwordsDoMatch = password === confirmPassword;
    
    setPasswordsMatch(passwordsDoMatch);
    setIsValid(emailIsValid && passwordIsValid && passwordsDoMatch);
    
    if (password && !passwordIsValid) {
      setError('Şifre en az 6 karakter olmalıdır.');
    } else if (confirmPassword && !passwordsDoMatch) {
      setError('Şifreler eşleşmiyor.');
    } else {
      setError('');
    }
  }, [email, password, confirmPassword]);
  
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  
  const generateReferralCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let referralCode = '';
    for (let i = 0; i < 8; i++) {
      referralCode += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return referralCode;
  };
  
  const saveToBinaryNetworkMembers = async () => {
    try {
      console.log("[BINARY_SAVE] Kayıt işlemi başlatıldı");
      
      // Önce transactionData'yı kontrol et
      let activeTransactionData = transactionData;
      
      if (!activeTransactionData || Object.keys(activeTransactionData).length === 0) {
        console.warn("[BINARY_SAVE] UYARI: TransactionData boş! Global değişkene bakılıyor...");
        
        // Global değişkeni kontrol et
        if (window.currentTransactionData && Object.keys(window.currentTransactionData).length > 0) {
          activeTransactionData = window.currentTransactionData;
          console.log("[BINARY_SAVE] Global TransactionData bulundu:", activeTransactionData);
        } else {
          console.error("[BINARY_SAVE] HATA: TransactionData bulunamadı!");
          throw new Error("Token satın alma bilgisi bulunamadı. Lütfen işlemi tekrar yapın.");
        }
      }
      
      console.log("[BINARY_SAVE] Kullanılacak TransactionData:", activeTransactionData);
      
      // BinaryNetworkMembers sınıfını tanımla
      const BinaryNetworkMembers = Parse.Object.extend("BinaryNetworkMembers");
      const networkMember = new BinaryNetworkMembers();
      
      // Paket tipi ve değer hesaplamaları
      let packageType = 0;
      let purchaseAmount = 0;
      let bonusAmount = 0;
      
      // Doğrudan sayısal değerler kullan
      if (activeTransactionData.packageType !== undefined) {
        packageType = Number(activeTransactionData.packageType);
      } else if (activeTransactionData.tokenAmount) {
        // Token miktarına göre paket tipini belirle
        const tokenAmount = Number(activeTransactionData.tokenAmount);
        if (tokenAmount >= 1000000) {
          packageType = 1; // Premium paket
        } else if (tokenAmount >= 500000) {
          packageType = 2; // Standart paket
        } else {
          packageType = 3; // Özel miktar
        }
      }
      
      // Token miktarını sayı olarak belirle
      if (activeTransactionData.tokenAmount !== undefined) {
        if (typeof activeTransactionData.tokenAmount === 'number') {
          purchaseAmount = activeTransactionData.tokenAmount;
        } else {
          // String olması durumunda temizle ve dönüştür
          const tokenAmountStr = String(activeTransactionData.tokenAmount).replace(/,/g, '');
          purchaseAmount = Number(tokenAmountStr);
        }
        
        // NaN kontrolü
        if (isNaN(purchaseAmount)) {
          purchaseAmount = 0;
          console.warn("[BINARY_SAVE] Token miktarı sayısal değere dönüştürülemedi!");
        }
      }
      
      // Bonus miktarını sayı olarak belirle
      if (activeTransactionData.tokenBonus !== undefined) {
        if (typeof activeTransactionData.tokenBonus === 'number') {
          bonusAmount = activeTransactionData.tokenBonus;
        } else {
          // String olması durumunda temizle ve dönüştür
          const bonusStr = String(activeTransactionData.tokenBonus).replace(/,/g, '');
          bonusAmount = Number(bonusStr);
        }
        
        // NaN kontrolü
        if (isNaN(bonusAmount)) {
          bonusAmount = 0;
          console.warn("[BINARY_SAVE] Bonus miktarı sayısal değere dönüştürülemedi!");
        }
      }
      
      const referralCode = generateReferralCode();
      
      // Kaydedilecek verileri logla - 0 değil, gerçek değerler olmalı
      console.log("[BINARY_SAVE] Kaydedilecek veriler:", {
        email,
        walletAddress: account || '',
        packageType,
        purchaseAmount,
        bonusAmount,
        referralCode,
        passwordSaved: password ? "Evet (şifrelenmiş)" : "Hayır"
      });
      
      // Parse için alanları açıkça sayı olarak ayarla
      networkMember.set("email", email);
      networkMember.set("password", password); // Şifre ekledik
      networkMember.set("walletAddress", account || "");
      networkMember.set("packageType", Number(packageType));
      networkMember.set("purchaseAmount", Number(purchaseAmount));
      networkMember.set("bonusAmount", Number(bonusAmount));
      networkMember.set("purchaseDate", new Date());
      networkMember.set("parent", null);
      networkMember.set("position", "");
      networkMember.set("level", 0);
      networkMember.set("leftVolume", 0);
      networkMember.set("rightVolume", 0);
      networkMember.set("totalEarnings", 0);
      networkMember.set("cycleCommission", 0);
      networkMember.set("directCommission", 0);
      networkMember.set("teamCommission", 0);
      networkMember.set("matchingBonus", 0);
      networkMember.set("referralCode", referralCode);
      
      // Kaydet ve sonuçları logla
      const result = await networkMember.save();
      console.log('[BINARY_SAVE] Kayıt başarılı:', result);
      
      // Kaydedilen verileri doğrula - sayı olarak kaydedilmiş olmalı, 0 değil!
      console.log("[BINARY_SAVE] Kayıt kontrol - Token miktarı:", result.get("purchaseAmount"));
      console.log("[BINARY_SAVE] Kayıt kontrol - Bonus miktarı:", result.get("bonusAmount"));
      console.log("[BINARY_SAVE] Kayıt kontrol - Paket tipi:", result.get("packageType"));
      console.log("[BINARY_SAVE] Kayıt kontrol - Şifre kaydedildi:", result.get("password") ? "Evet" : "Hayır");
      
      return true;
    } catch (error) {
      console.error('[BINARY_SAVE] Kayıt hatası:', error);
      console.error('[BINARY_SAVE] Hata ayrıntıları:', error.stack);
      setError('Network üyeliği kaydedilemedi: ' + error.message);
      return false;
    }
  };
  
  const handleSubmit = async () => {
    if (!isValid) {
      if (!validateEmail(email)) {
        setError('Lütfen geçerli bir email adresi girin.');
      } else if (password.length < 6) {
        setError('Şifre en az 6 karakter olmalıdır.');
      } else if (password !== confirmPassword) {
        setError('Şifreler eşleşmiyor.');
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      const success = await saveToBinaryNetworkMembers();
      
      if (success) {
        setIsSuccess(true);
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        setError('Network üyeliği kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (err) {
      console.error("Kayıt hatası:", err);
      setError('Beklenmeyen bir hata oluştu: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="token-wizard-body">
      <h2 className="token-wizard-step-title">{t.joinNetwork}</h2>
      
      {isSuccess ? (
        <motion.div
          className="confirmation-icon"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FaCheck />
        </motion.div>
      ) : (
        <p className="token-wizard-step-description">{t.joinNetworkDescription}</p>
      )}
      
      {isSuccess ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="network-join-success"
        >
          <h3>{t.networkJoinSuccess}</h3>
          <p>{t.emailAdded}</p>
        </motion.div>
      ) : (
        <div className="network-join-form">
          <div className="email-input-wrapper">
            <input
              type="email"
              className="email-input"
              value={email}
              onChange={handleEmailChange}
              placeholder={t.enterEmail}
              disabled={isLoading}
            />
          </div>
          
          <div className="email-input-wrapper" style={{marginTop: '16px'}}>
            <input
              type="password"
              className="email-input"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Şifre (en az 6 karakter)"
              disabled={isLoading}
            />
          </div>
          
          <div className="email-input-wrapper" style={{marginTop: '16px'}}>
            <input
              type="password"
              className="email-input"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Şifreyi Doğrula"
              disabled={isLoading}
            />
          </div>
          
          {error && (
            <motion.div 
              className="token-wizard-error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{marginTop: '16px'}}
            >
              <FaExclamationTriangle className="token-wizard-error-icon" />
              {error}
            </motion.div>
          )}
          
          {isLoading && (
            <div className="token-wizard-loading">
              <div className="loading-spinner"></div>
              <p className="loading-text">Kaydediliyor...</p>
            </div>
          )}
        </div>
      )}
      
      <div className="token-wizard-footer">
        {!isSuccess && (
          <>
            <motion.button 
              className="token-wizard-button secondary"
              onClick={previousStep}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={isLoading}
            >
              <FaArrowLeft /> {t.back}
            </motion.button>
            
            <motion.button 
              className="token-wizard-button"
              onClick={handleSubmit}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={!isValid || isLoading}
            >
              {t.submitEmail}
            </motion.button>
          </>
        )}
        
        {isSuccess && (
          <motion.button 
            className="token-wizard-button"
            onClick={nextStep}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {t.continue} <FaArrowRight />
          </motion.button>
        )}
      </div>
    </div>
  );
};

const ConfirmationStep = ({ previousStep, onClose, transactionData = {}, joinNetwork }) => {
  const [redirectToNetwork, setRedirectToNetwork] = useState(false);
  
  useEffect(() => {
    if (redirectToNetwork) {
      // Network sayfasına yönlendirme işlemi
      window.location.href = '/binary-network-login';
    }
  }, [redirectToNetwork]);
  
  const openExplorer = (txHash) => {
    // BNB Chain Explorer için URL
    const explorerUrl = `https://bscscan.com/tx/${txHash}`;
    window.open(explorerUrl, '_blank');
  };

  const handleNetworkLogin = () => {
    setRedirectToNetwork(true);
  };

  return (
    <div className="token-wizard-body">
      <div className="confirmation-icon">
        <FaCheck />
      </div>
      
      <h2 className="token-wizard-step-title">{t.transactionComplete}</h2>
      <p className="token-wizard-step-description">{t.purchaseSuccessful}</p>
      
      {transactionData.transactionHash && (
        <div className="transaction-details">
          <div className="transaction-detail-row">
            <span className="transaction-detail-label">{t.tokenAmount}:</span>
            <span className="transaction-detail-value">{transactionData.tokenAmount} MFT</span>
          </div>
          
          {transactionData.tokenBonus > 0 && (
            <div className="transaction-detail-row">
              <span className="transaction-detail-label">{t.bonusAmount}:</span>
              <span className="transaction-detail-value">{transactionData.tokenBonus} MFT</span>
            </div>
          )}
          
          <div className="transaction-detail-row">
            <span className="transaction-detail-label">{t.bnbSent}:</span>
            <span className="transaction-detail-value">
              {transactionData.paymentAmount?.toFixed(8)} {transactionData.paymentCurrency}
            </span>
          </div>
          
          <div className="transaction-detail-row">
            <span className="transaction-detail-label">{t.transactionId}:</span>
            <span 
              className="transaction-detail-value transaction-hash"
              onClick={() => openExplorer(transactionData.transactionHash)}
            >
              {transactionData.transactionHash.slice(0, 14)}...{transactionData.transactionHash.slice(-6)}
              <FaExternalLinkAlt style={{ marginLeft: "8px", fontSize: "12px" }} />
            </span>
          </div>
        </div>
      )}
      
      <div className="token-wizard-footer">
        {joinNetwork && (
          <motion.button 
            onClick={handleNetworkLogin} 
            className="token-wizard-button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {t.loginToNetwork}
          </motion.button>
        )}
        
        <motion.button 
          onClick={onClose} 
          className={`token-wizard-button ${joinNetwork ? 'secondary' : ''}`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {t.close}
        </motion.button>
      </div>
    </div>
  );
};

const PackageSelectionStep = ({ nextStep, setAmount, setSelectedPackage, setJoinNetwork }) => {
  const [packageChoice, setPackageChoice] = useState(null);
  const [customAmount, setCustomAmount] = useState('');
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [error, setError] = useState('');
  const mftToUsdRate = 0.005; // 1 MFT = $0.005
  
  // Paketler
  const packages = [
    {
      id: 'premium',
      name: t.premiumPackage,
      price: 5000,
      tokenAmount: 5000 / mftToUsdRate,
      bonus: 20,
      joinNetwork: true,
      benefits: [
        { text: `${t.bonus}: 20%`, icon: <FaGem /> },
        { text: t.includesNetworkAccess, icon: <FaUsers /> },
        { text: t.networkBenefits, icon: <FaChartLine /> }
      ]
    },
    {
      id: 'standard',
      name: t.standardPackage,
      price: 2500,
      tokenAmount: 2500 / mftToUsdRate,
      bonus: 10,
      joinNetwork: true,
      benefits: [
        { text: `${t.bonus}: 10%`, icon: <FaStar /> },
        { text: t.includesNetworkAccess, icon: <FaUsers /> },
        { text: t.networkBenefits, icon: <FaChartLine /> }
      ]
    }
  ];
  
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  const handlePackageSelect = (pkg) => {
    setPackageChoice(pkg);
    setCustomAmount('');
    setError('');
    setIsAmountValid(true);
  };
  
  const handleCustomAmountSelect = () => {
    setPackageChoice(null);
    setError('');
    setIsAmountValid(customAmount !== '' && parseFloat(customAmount) >= 5);
  };
  
  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (/^\d*\.?\d*$/.test(value)) {
      setCustomAmount(value);
      const numValue = parseFloat(value);
      // Minimum 5 dolar kabul edilecek
      setIsAmountValid(!isNaN(numValue) && numValue >= 5);
      
      if (value !== '' && (isNaN(numValue) || numValue < 5)) {
        setError('Minimum $5 değerinde MFT satın almalısınız.');
      } else {
        setError('');
      }
    }
  };
  
  const handleContinue = () => {
    if (packageChoice) {
      setAmount(formatNumber(Math.floor(packageChoice.tokenAmount)));
      setSelectedPackage(packageChoice);
      setJoinNetwork(packageChoice.joinNetwork);
      nextStep();
    } else if (isAmountValid) {
      const tokenAmount = Math.floor(parseFloat(customAmount) / mftToUsdRate);
      setAmount(formatNumber(tokenAmount));
      setSelectedPackage({
        id: 'custom',
        name: t.customPackage,
        price: parseFloat(customAmount),
        tokenAmount: tokenAmount,
        bonus: 0,
        joinNetwork: false
      });
      setJoinNetwork(false);
      nextStep();
    } else {
      setError('Lütfen geçerli bir paket veya miktar seçin.');
    }
  };
  
  return (
    <div className="token-wizard-body">
      <h2 className="token-wizard-step-title">{t.packageSelection}</h2>
      <p className="token-wizard-step-description">{t.selectPackage}</p>
      
      <div className="package-grid">
        {packages.map((pkg) => (
          <motion.div
            key={pkg.id}
            className={`package-card ${packageChoice?.id === pkg.id ? 'selected' : ''}`}
            onClick={() => handlePackageSelect(pkg)}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: pkg.id === 'premium' ? 0.1 : 0.2 }}
            whileHover={{ y: -4 }}
            whileTap={{ y: 0 }}
          >
            <div className="package-badge">+{pkg.bonus}%</div>
            <div className="package-name">{pkg.name}</div>
            <div className="package-price">${formatNumber(pkg.price)}</div>
            <div className="package-token-amount">{formatNumber(Math.floor(pkg.tokenAmount))} MFT</div>
            
            <div className="package-benefits">
              {pkg.benefits.map((benefit, idx) => (
                <div key={idx} className="package-benefit">
                  <span className="package-benefit-icon">{benefit.icon}</span>
                  <span>{benefit.text}</span>
                </div>
              ))}
            </div>
          </motion.div>
        ))}
        
        <motion.div
          className={`custom-amount-card ${!packageChoice ? 'selected' : ''}`}
          onClick={handleCustomAmountSelect}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          whileHover={{ y: -4 }}
          whileTap={{ y: 0 }}
        >
          <div className="custom-amount-title">{t.customAmount}</div>
          <div className="token-input-wrapper">
            <span>$</span>
            <input
              type="text"
              value={customAmount}
              onChange={handleCustomAmountChange}
              onClick={(e) => e.stopPropagation()}
              className="token-input"
              placeholder="0.00"
            />
          </div>
          <div className="custom-amount-description">
            {customAmount && !isNaN(parseFloat(customAmount)) ? (
              <>≈ {formatNumber(Math.floor(parseFloat(customAmount) / mftToUsdRate))} MFT</>
            ) : (
              <>{t.orSelectCustomAmount}</>
            )}
          </div>
        </motion.div>
      </div>
      
      {error && (
        <motion.div 
          className="token-wizard-error"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <FaExclamationTriangle className="token-wizard-error-icon" />
          {error}
        </motion.div>
      )}
      
      <div className="token-wizard-footer">
        <motion.button 
          className="token-wizard-button"
          onClick={handleContinue}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          disabled={!packageChoice && !isAmountValid}
        >
          {t.continue} <FaArrowRight />
        </motion.button>
      </div>
    </div>
  );
};

// StepWizard için özel renderer
const StepWizardNav = ({ currentStep, totalSteps, goToStep }) => {
  return (
    <div className="token-wizard-header">
      <div className="token-wizard-progress">
        {Array.from({ length: totalSteps }).map((_, index) => (
          <div 
            key={index}
            className={`progress-step ${currentStep >= index + 1 ? 'active' : ''} ${currentStep > index + 1 ? 'completed' : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
};

const BuyTokenWizard = ({ onClose }) => {
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState('');
  const [amount, setAmount] = useState('');
  const [tokenEquivalent, setTokenEquivalent] = useState(0);
  const [selectedToken, setSelectedToken] = useState('bnb'); // Varsayılan olarak BNB seçili
  const [prices, setPrices] = useState(null);
  const [transactionData, setTransactionData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [joinNetwork, setJoinNetwork] = useState(false);
  const mftToUsdRate = 0.0050; // 1 MFT = $0.0050

  // Kripto fiyatlarını çek
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,ethereum&vs_currencies=usd');
        const data = await response.json();
        setPrices(data);
      } catch (error) {
        console.error('Fiyat çekme hatası:', error);
        // Fallback fiyatlar
        setPrices({
          binancecoin: { usd: 300 },
          ethereum: { usd: 2000 }
        });
      }
    };
    fetchPrices();
  }, []);

  // transactionData değiştiğinde yapılacak işlemler
  useEffect(() => {
    if (transactionData && Object.keys(transactionData).length > 0) {
      console.log("[WIZARD_ROOT] TransactionData güncellendi:", transactionData);
      // Global değişkeni de güncelle (yedek olarak)
      window.currentTransactionData = transactionData;
    }
  }, [transactionData]);

  const handleContainerClick = (e) => {
    if (e.target.className === 'token-wizard-overlay') {
      onClose();
    }
  };
  
  const handleStepChange = (data) => {
    console.log("[WIZARD] Step change data:", data);
    
    if (data) {
      // Verileri kontrol et ve sayısal değerleri düzelt
      const updatedData = {...data};
      
      // tokenAmount sayısal değere dönüştür
      if (updatedData.tokenAmount !== undefined) {
        const amount = typeof updatedData.tokenAmount === 'string' 
          ? parseFloat(updatedData.tokenAmount.replace(/,/g, '')) 
          : Number(updatedData.tokenAmount);
        
        updatedData.tokenAmount = isNaN(amount) ? 0 : amount;
        console.log("[WIZARD] Parsed tokenAmount:", updatedData.tokenAmount);
      }
      
      // tokenBonus sayısal değere dönüştür
      if (updatedData.tokenBonus !== undefined) {
        const bonus = typeof updatedData.tokenBonus === 'string'
          ? parseFloat(updatedData.tokenBonus.replace(/,/g, ''))
          : Number(updatedData.tokenBonus);
        
        updatedData.tokenBonus = isNaN(bonus) ? 0 : bonus;
        console.log("[WIZARD] Parsed tokenBonus:", updatedData.tokenBonus);
      }
      
      // packageType ekle (yoksa)
      if (updatedData.packageType === undefined && updatedData.tokenAmount) {
        if (updatedData.tokenAmount >= 1000000) {
          updatedData.packageType = 1;
        } else if (updatedData.tokenAmount >= 500000) {
          updatedData.packageType = 2;
        } else {
          updatedData.packageType = 3;
        }
        console.log("[WIZARD] Added packageType:", updatedData.packageType);
      }
      
      console.log("[WIZARD] Final data:", updatedData);
      setTransactionData(updatedData);
    }
  };

  return (
    <AnimatePresence>
      <motion.div 
        className="token-wizard-overlay" 
        onClick={handleContainerClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div 
          className="token-wizard-card"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="token-wizard-title-container">
            <h1 className="token-wizard-title">MFT Satın Al</h1>
            <button className="token-wizard-close" onClick={onClose}>
              <FaTimes />
            </button>
          </div>
          
          <StepWizard
            isHashEnabled={false}
            transitions={{}}
            onStepChange={(stats) => setCurrentStep(stats.activeStep)}
            nav={<StepWizardNav totalSteps={joinNetwork ? 4 : 3} currentStep={currentStep} />}
            instance={(wizardInstance) => {
              // StepWizard instance'ını güvenli şekilde bir değişkene ata
              window.stepWizardInstance = wizardInstance;
            }}
          >
            <PackageSelectionStep
              setAmount={setAmount}
              setSelectedPackage={setSelectedPackage}
              setJoinNetwork={setJoinNetwork}
              nextStep={handleStepChange}
            />
            <WalletStep 
              setConnected={setConnected} 
              setAccount={setAccount}
              previousStep={null}
              nextStep={handleStepChange}
            />
            <PurchaseStep
              amount={amount}
              setAmount={setAmount}
              selectedToken={selectedToken}
              setSelectedToken={setSelectedToken}
              tokenEquivalent={tokenEquivalent}
              setTokenEquivalent={setTokenEquivalent}
              mftToUsdRate={mftToUsdRate}
              prices={prices}
              setPrices={setPrices}
              account={account}
              selectedPackage={selectedPackage}
              joinNetwork={joinNetwork}
              setTransactionData={setTransactionData}
              previousStep={handleStepChange}
              nextStep={(data) => {
                console.log("[DIRECT_PASS] Step değişikliği:", data);
                // Veriyi doğrudan state'e kaydet
                if (data) {
                  setTransactionData(data);
                }
                // sonraki adıma manuel geç
                const step = joinNetwork ? 4 : 4;
                if (window.stepWizardInstance) {
                  window.stepWizardInstance.goToStep(step);
                }
              }}
            />
            {joinNetwork ? (
              <NetworkJoinStep
                transactionData={transactionData}
                account={account}
                previousStep={() => {
                  if (window.stepWizardInstance) {
                    window.stepWizardInstance.goToStep(3);
                  }
                }}
                nextStep={() => {
                  if (window.stepWizardInstance) {
                    window.stepWizardInstance.goToStep(5);
                  }
                }}
              />
            ) : null}
            <ConfirmationStep
              onClose={onClose}
              transactionData={transactionData}
              joinNetwork={joinNetwork}
              previousStep={() => {
                if (window.stepWizardInstance) {
                  window.stepWizardInstance.goToStep(joinNetwork ? 4 : 3);
                }
              }}
            />
          </StepWizard>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BuyTokenWizard;

