import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { 
  FaCheckCircle, 
  FaInfoCircle, 
  FaLightbulb, 
  FaChartBar, 
  FaNetworkWired,
  FaUsers,
  FaMoneyBillWave,
  FaArrowRight,
  FaGem,
  FaStar
} from "react-icons/fa";

const RulesContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #0c1015 0%, #1a1c35 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
`;

const RulesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 100%;
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 149, 0, 0.5);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 149, 0, 0.7);
  }
`;

const RuleCategory = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const CategoryTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #FF9500;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RuleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RuleItem = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.05);
  
  svg {
    flex: 0 0 18px;
    font-size: 18px;
    color: #FF9500;
    margin-top: 2px;
  }
`;

const RuleContent = styled.div`
  flex: 1;
`;

const RuleTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: white;
`;

const RuleDescription = styled.p`
  font-size: 13px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
`;

const LevelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

const LevelItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 12px;
  border-radius: 8px;
  
  .level-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    
    svg {
      font-size: 14px;
      margin-top: 0;
    }
  }
  
  .level-rate {
    font-weight: 600;
    color: #FF9500;
    font-size: 13px;
  }
`;

const PresentationNetworkRules = () => {
  return (
    <RulesContainer>
      <ContentWrapper>
        <Header>
          <Title>MyFutureToken Network Sistem Kuralları</Title>
          <Subtitle>
            Adil ve şeffaf bir kazanç sistemi için Network sistemimizde uygulanan
            temel kurallar ve çalışma prensipleri
          </Subtitle>
        </Header>
        
        <RulesContent>
          <RuleCategory
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <CategoryTitle>
              <FaNetworkWired /> Ağaç Yapısı ve Yerleştirme Kuralları
            </CategoryTitle>
            <RuleList>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>İkili Ağaç Yapısı</RuleTitle>
                  <RuleDescription>
                    Her üye, organizasyonunda yalnızca iki direkt alt hat (sol ve sağ kol) oluşturabilir.
                    Diğer tüm yeni üyeler, mevcut yapının altına yerleştirilir.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Spillover Etkisi</RuleTitle>
                  <RuleDescription>
                    İlk iki üyeden sonra getirdiğiniz yeni üyeler, ağaç yapınızda uygun boş pozisyonlara
                    otomatik olarak yerleştirilir, bu da alt hatlarınızın genişlemesini sağlar.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Dengeli Ağaç Avantajı</RuleTitle>
                  <RuleDescription>
                    Kazançlarınızı maksimize etmek için sol ve sağ kollarınızı dengeli tutmanız önerilir.
                    Dengesiz bir ağaç, kazanç potansiyelinizi sınırlandırabilir.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
            </RuleList>
          </RuleCategory>
          
          <RuleCategory
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <CategoryTitle>
              <FaMoneyBillWave /> Kazanç Hesaplama ve Ödeme Kuralları
            </CategoryTitle>
            <RuleList>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Zayıf Kol Hesaplaması</RuleTitle>
                  <RuleDescription>
                    Kazancınız, zayıf kolunuzun (daha az hacme sahip kol) toplam hacmi üzerinden hesaplanır.
                    Bu nedenle dengeli bir ağaç yapısı oluşturmak önemlidir.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Haftalık Ödeme Döngüsü</RuleTitle>
                  <RuleDescription>
                    Tüm kazançlar haftalık olarak hesaplanır ve her pazartesi MFT cüzdanınıza otomatik olarak yatırılır.
                    Minimum ödeme eşiği 50 MFT'dir.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Seviye Bazlı Komisyon Oranları</RuleTitle>
                  <RuleDescription>
                    Komisyon oranları, üyelik seviyenize göre belirlenir. Daha yüksek paketler, daha yüksek
                    komisyon oranlarına sahiptir.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <LevelList>
                <LevelItem>
                  <div className="level-name"><FaGem /> Premium Seviye (Level 1)</div>
                  <div className="level-rate">%20</div>
                </LevelItem>
                <LevelItem>
                  <div className="level-name"><FaStar /> Profesyonel Seviye (Level 2)</div>
                  <div className="level-rate">%10</div>
                </LevelItem>
                <LevelItem>
                  <div className="level-name"><FaUsers /> Standart Seviye (Level 3)</div>
                  <div className="level-rate">%5</div>
                </LevelItem>
              </LevelList>
            </RuleList>
          </RuleCategory>
          
          <RuleCategory
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <CategoryTitle>
              <FaLightbulb /> Tavsiye ve Optimizasyon Stratejileri
            </CategoryTitle>
            <RuleList>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Dengeli Büyüme Stratejisi</RuleTitle>
                  <RuleDescription>
                    Maksimum kazanç için, her iki kolunuzu da dengeli bir şekilde büyütmeye çalışın.
                    Yeni üyelerinizi kolların ihtiyacına göre yerleştirin.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Aktif ve Pasif Gelir Dengeleme</RuleTitle>
                  <RuleDescription>
                    Hem direkt referanslardan kazanç (aktif) hem de ağaç derinliğinden kazanç (pasif)
                    stratejilerini dengeli bir şekilde kullanın.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Üst Seviye Paketlere Geçiş</RuleTitle>
                  <RuleDescription>
                    Kazancınızı artırmak için, ağınız büyüdükçe üst seviye paketlere geçmeyi değerlendirin.
                    Bu, daha yüksek komisyon oranları sağlar.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
            </RuleList>
          </RuleCategory>
          
          <RuleCategory
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <CategoryTitle>
              <FaInfoCircle /> Genel Kurallar ve Politikalar
            </CategoryTitle>
            <RuleList>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Tek Hesap Politikası</RuleTitle>
                  <RuleDescription>
                    Her kullanıcı yalnızca bir hesaba sahip olabilir. Birden fazla hesap oluşturmak,
                    hesabın kapatılmasına ve kazançların askıya alınmasına neden olabilir.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Etik Pazarlama Kuralları</RuleTitle>
                  <RuleDescription>
                    Üyeler, yanıltıcı veya gerçek dışı pazarlama taktikleri kullanamaz. MyFutureToken'ın
                    itibarını zedeleyecek davranışlar kabul edilmez.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
              <RuleItem>
                <FaArrowRight />
                <RuleContent>
                  <RuleTitle>Yükseltme ve Değişiklik Hakkı</RuleTitle>
                  <RuleDescription>
                    MyFutureToken, gerekli gördüğü durumlarda sistem kurallarında değişiklik yapma hakkını
                    saklı tutar. Tüm değişiklikler önceden duyurulur.
                  </RuleDescription>
                </RuleContent>
              </RuleItem>
            </RuleList>
          </RuleCategory>
        </RulesContent>
      </ContentWrapper>
    </RulesContainer>
  );
};

export default PresentationNetworkRules; 