import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import * as d3 from "d3";
import { FaUsers, FaNetworkWired, FaArrowRight, FaStar, FaChartLine, FaDollarSign } from "react-icons/fa";

const NetworkSystemContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #0c1015 0%, #1a1c35 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 12px;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 10px rgba(255, 102, 0, 0.3);
`;

const Subtitle = styled.p`
  font-size: 15px;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
`;

const NetworkContent = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  flex: 1;
  overflow: hidden;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const TreeContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoPanel = styled.div`
  flex: 0 0 360px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  
  /* Scrollbar stilini özelleştirme */
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  @media (max-width: 1024px) {
    flex: 0 0 auto;
  }
`;

const InfoTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #FF9500;
  display: flex;
  align-items: center;
  gap: 10px;
  text-shadow: 0px 2px 5px rgba(255, 149, 0, 0.3);
`;

const InfoText = styled.p`
  font-size: 15px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 18px;
`;

const FeatureList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const FeatureItem = styled(motion.li)`
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 15px;
  border-radius: 8px;
  border-left: 3px solid #FF9500;
  
  svg {
    flex: 0 0 20px;
    margin-right: 12px;
    margin-top: 2px;
    color: #FF9500;
  }
  
  span {
    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const PresentationNetworkSystem = () => {
  const svgRef = useRef(null);
  
  useEffect(() => {
    if (!svgRef.current) return;
    
    // SVG boyutu
    const width = 600;
    const height = 400;
    
    // SVG oluştur
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");
    
    // Glow efekti için filter
    const defs = svg.append("defs");
    const filter = defs.append("filter")
      .attr("id", "glow")
      .attr("x", "-50%")
      .attr("y", "-50%")
      .attr("width", "200%")
      .attr("height", "200%");
    
    filter.append("feGaussianBlur")
      .attr("stdDeviation", "5")
      .attr("result", "coloredBlur");
    
    const femerge = filter.append("feMerge");
    femerge.append("feMergeNode").attr("in", "coloredBlur");
    femerge.append("feMergeNode").attr("in", "SourceGraphic");
    
    // Gradiyentler
    const rootGradient = defs.append("radialGradient")
      .attr("id", "root-gradient")
      .attr("cx", "50%")
      .attr("cy", "50%")
      .attr("r", "50%");
    
    rootGradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "rgba(255, 102, 0, 1)");
    
    rootGradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "rgba(255, 80, 0, 0.8)");
    
    const level1Gradient = defs.append("radialGradient")
      .attr("id", "level1-gradient")
      .attr("cx", "50%")
      .attr("cy", "50%")
      .attr("r", "50%");
    
    level1Gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "rgba(255, 149, 0, 1)");
    
    level1Gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "rgba(255, 120, 0, 0.8)");
    
    const level2Gradient = defs.append("radialGradient")
      .attr("id", "level2-gradient")
      .attr("cx", "50%")
      .attr("cy", "50%")
      .attr("r", "50%");
    
    level2Gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "rgba(255, 204, 0, 1)");
    
    level2Gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "rgba(255, 170, 0, 0.8)");
    
    // Statik ağaç yapısını çiz
    const g = svg.append("g")
      .attr("transform", `translate(${width/2}, 60)`);
    
    // Düğüm pozisyonları (manuel olarak ayarla)
    const nodes = [
      { id: "root", name: "Siz", x: 0, y: 0, level: 0 },
      { id: "left", name: "Sol Kol", x: -120, y: 100, level: 1 },
      { id: "right", name: "Sağ Kol", x: 120, y: 100, level: 1 },
      { id: "left-left", name: "Üye A", x: -180, y: 200, level: 2 },
      { id: "left-right", name: "Üye B", x: -60, y: 200, level: 2 },
      { id: "right-left", name: "Üye C", x: 60, y: 200, level: 2 },
      { id: "right-right", name: "Üye D", x: 180, y: 200, level: 2 }
    ];
    
    // Bağlantıları tanımla
    const links = [
      { source: "root", target: "left" },
      { source: "root", target: "right" },
      { source: "left", target: "left-left" },
      { source: "left", target: "left-right" },
      { source: "right", target: "right-left" },
      { source: "right", target: "right-right" }
    ];
    
    // Bağlantıları çiz
    links.forEach((link, i) => {
      const source = nodes.find(n => n.id === link.source);
      const target = nodes.find(n => n.id === link.target);
      
      const path = g.append("path")
        .attr("d", `M${source.x},${source.y} 
                    C${source.x},${(source.y + target.y) / 2} 
                     ${target.x},${(source.y + target.y) / 2} 
                     ${target.x},${target.y}`)
        .attr("stroke", "rgba(255, 149, 0, 0.8)")
        .attr("stroke-width", 3)
        .attr("fill", "none")
        .style("opacity", 0)
        .style("filter", "url(#glow)");
      
      // Animasyon
      setTimeout(() => {
        path.transition()
          .duration(800)
          .style("opacity", 1);
      }, 500 + (source.level * 300));
    });
    
    // Düğümleri çiz
    nodes.forEach((node, i) => {
      const nodeGroup = g.append("g")
        .attr("transform", `translate(${node.x}, ${node.y})`)
        .style("opacity", 0);
      
      // Arka plan hale
      nodeGroup.append("circle")
        .attr("r", node.level === 0 ? 40 : 30)
        .attr("fill", "rgba(255, 149, 0, 0.15)")
        .style("filter", "url(#glow)");
      
      // Ana daire
      nodeGroup.append("circle")
        .attr("r", node.level === 0 ? 28 : 22)
        .attr("fill", node.level === 0 ? "url(#root-gradient)" : 
                     (node.level === 1 ? "url(#level1-gradient)" : "url(#level2-gradient)"))
        .attr("stroke", "rgba(255, 255, 255, 0.9)")
        .attr("stroke-width", 2)
        .style("filter", "drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.4))");
      
      // İsim etiketi
      nodeGroup.append("text")
        .attr("y", node.level === 0 ? 42 : 35)
        .attr("text-anchor", "middle")
        .attr("fill", "white")
        .style("font-size", node.level === 0 ? "14px" : "12px")
        .style("font-weight", "600")
        .text(node.name);
      
      // İç metin (sadece root için)
      if (node.level === 0) {
        nodeGroup.append("text")
          .attr("text-anchor", "middle")
          .attr("fill", "white")
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .text("Siz");
      }
      
      // Animasyon
      setTimeout(() => {
        nodeGroup.transition()
          .duration(600)
          .style("opacity", 1);
      }, 400 + (node.level * 300));
    });
    
  }, []);

  return (
    <NetworkSystemContainer>
      <ContentWrapper>
        <Header>
          <Title>Binary Network Sistemi</Title>
          <Subtitle>
            İki kol yapısına dayalı, hem ekip genişliğinden hem de derinliğinden kazanç sağlayan
            güçlü ve adil bir network pazarlama sistemi
          </Subtitle>
        </Header>
        
        <NetworkContent>
          <TreeContainer>
            <svg ref={svgRef}></svg>
          </TreeContainer>
          
          <InfoPanel>
            <InfoTitle>
              <FaNetworkWired /> Binary Sistem Nasıl Çalışır?
            </InfoTitle>
            <InfoText>
              Binary Network Sistemi, her üyenin iki farklı kol (sol ve sağ) oluşturabildiği, 
              derinlik ve genişlik kazancı sağlayan bir yapıdır. Sistem dengeli bir şekilde 
              büyüdükçe kazancınız da artar.
            </InfoText>
            
            <FeatureList>
              <FeatureItem 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <FaArrowRight />
                <span>Her üye sadece iki kişiyi (sol ve sağ kol) doğrudan kendisine bağlayabilir.</span>
              </FeatureItem>
              <FeatureItem 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <FaUsers />
                <span>Diğer üyeler, ağacınızın altındaki mevcut kişilerin altına yerleştirilir (spillover etkisi).</span>
              </FeatureItem>
              <FeatureItem 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <FaChartLine />
                <span>Kazançlar, zayıf kolunuzun hacmine göre hesaplanır; bu da dengeli bir ağaç oluşturmayı teşvik eder.</span>
              </FeatureItem>
              <FeatureItem 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <FaDollarSign />
                <span>Paketinizin seviyesine bağlı olarak %5 ile %20 arasında komisyon kazanırsınız.</span>
              </FeatureItem>
              <FeatureItem 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <FaStar />
                <span>Sisteme katılan her yeni üye, sizin ve üst hatlarınızın gelirlerine katkıda bulunur.</span>
              </FeatureItem>
            </FeatureList>
          </InfoPanel>
        </NetworkContent>
      </ContentWrapper>
    </NetworkSystemContainer>
  );
};

export default PresentationNetworkSystem; 