import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import tokenomicsImg from "../../assets/img/banner/tokenomics.png";
import connectBuyEarnImg from "../../assets/img/banner/MFC-Connect-Buy-Earn-GER.png";
import mfcFirstImg from "../../assets/img/banner/MFC-First.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import PresentationTokenomics from './PresentationTokenomics';
import PresentationNetworkSystem from './PresentationNetworkSystem';
import PresentationEarningsAnimation from './PresentationEarningsAnimation';
import PresentationNetworkRules from './PresentationNetworkRules';

const PresentationContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #0a0f18 0%, #1c1e40 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const SlideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SlideContent = styled(motion.div)`
  width: 90%;
  max-width: 1400px;
  height: 85%;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const SlideHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const SlideTitle = styled.h2`
  font-size: 36px;
  font-weight: 800;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;

const SlideNumber = styled.div`
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
`;

const SlideBody = styled.div`
  flex: 1;
  display: flex;
  gap: 40px;
  overflow: hidden;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const SlideImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 12px;
  
  @media (max-width: 992px) {
    max-height: 40%;
  }
`;

const SlideImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const SlideTextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  padding-right: 10px;
  
  /* Scrollbar stilini özelleştirme */
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const SlideDescription = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 30px;
`;

const SlideHighlights = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
`;

const HighlightItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  
  &:before {
    content: "•";
    color: #FF6600;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-right: 10px;
    font-size: 24px;
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
`;

const Progress = styled.div`
  height: 100%;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease;
`;

const presentationSlides = [
  {
    title: "MyFutureToken (MFT)",
    image: mfcFirstImg,
    description: "Yapay Zeka teknolojilerinin geleceğine yatırım yapın",
    highlights: [
      "Ethereum blockchain üzerinde ERC-20 standardında geliştirilmiş token",
      "Yapay zeka projelerine yatırım yaparak inovasyonu hızlandırma",
      "20 yıllık yazılım geliştirme ve 5 yıllık AI geliştirme tecrübesi",
      "Etik, insan odaklı ve sürdürülebilir AI teknolojileri vizyonu"
    ]
  },
  {
    title: "Tokenomik Yapı",
    image: tokenomicsImg,
    description: "Toplam 10 milyar MFT token, güçlü bir ekonomik planlama ile dağıtılmıştır:",
    highlights: [
      "%20 (2 milyar MFT) - Presale",
      "%25 (2.5 milyar MFT) - Staking Ödülleri",
      "%5 (500 milyon MFT) - Bonus Dağıtımı",
      "%15 (1.5 milyar MFT) - Pazarlama",
      "%20 (2 milyar MFT) - Proje Fonu",
      "%15 (1.5 milyar MFT) - Likidite Rezervleri"
    ]
  },
  {
    title: "Bağlan, Satın Al, Kazan",
    image: connectBuyEarnImg,
    description: "Basit üç adımda MyFutureToken ekosistemine katılın ve kazanmaya başlayın:",
    highlights: [
      "Bağlan: Hesabınızı oluşturun ve network marketing sistemine dahil olun",
      "Satın Al: İstediğiniz paket seviyesinde MFT token'larınızı satın alın",
      "Kazan: Ağınızı genişleterek komisyonlar kazanın ve token değerindeki artıştan faydalanın",
      "Şubat 2025'ten Şubat 2026'ya projeksiyonumuz: %18.900 değer artışı"
    ]
  },
  {
    title: "Gelişim Yol Haritası",
    image: null,
    description: "MyFutureToken, sağlam bir planlama ile ilerliyor:",
    highlights: [
      "Q3 2023: Konsept geliştirme ve stratejik planlama",
      "Q4 2023: Teknik altyapı ve UX tasarımı geliştirme",
      "Q1 2024: Güvenlik protokolleri ve altyapı optimizasyonu",
      "Q2 2024: Network Marketing Sistemi geliştirme",
      "Q3-Q4 2024: Halka açık beta ve sistem testleri",
      "Q1 2025: ICO hazırlıkları ve ön satış",
      "Q2 2025: Resmi ICO başlangıcı ve tam lansman",
      "Q3 2025: Ekosistem genişletme çalışmaları"
    ]
  }
];

const Presentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const nextSlide = () => {
    if (currentSlide < presentationSlides.length - 1) {
      setCurrentSlide(prevSlide => prevSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(prevSlide => prevSlide - 1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      nextSlide();
    } else if (e.key === "ArrowLeft") {
      prevSlide();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSlide]);
  
  return (
    <PresentationContainer>
      <SlideContainer>
        <AnimatePresence mode="wait">
          <SlideContent
            key={currentSlide}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.5 }}
          >
            <SlideHeader>
              <SlideTitle>{presentationSlides[currentSlide].title}</SlideTitle>
              <SlideNumber>
                {currentSlide + 1} / {presentationSlides.length}
              </SlideNumber>
            </SlideHeader>
            
            <SlideBody>
              {presentationSlides[currentSlide].image && (
                <SlideImageContainer>
                  <SlideImage src={presentationSlides[currentSlide].image} alt={presentationSlides[currentSlide].title} />
                </SlideImageContainer>
              )}
              
              <SlideTextContent>
                <SlideDescription>
                  {presentationSlides[currentSlide].description}
                </SlideDescription>
                
                <SlideHighlights>
                  {presentationSlides[currentSlide].highlights.map((highlight, index) => (
                    <HighlightItem key={index}>{highlight}</HighlightItem>
                  ))}
                </SlideHighlights>
              </SlideTextContent>
            </SlideBody>
          </SlideContent>
        </AnimatePresence>
        
        <ProgressBar>
          <Progress progress={(currentSlide / (presentationSlides.length - 1)) * 100} />
        </ProgressBar>
      </SlideContainer>
    </PresentationContainer>
  );
};

export default Presentation; 