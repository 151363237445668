import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { FaUser, FaCoins, FaArrowUp, FaDollarSign, FaChartLine, FaMoneyBillWave, FaPlus } from "react-icons/fa";

const EarningsContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #0c1015 0%, #1a1c35 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
`;

const NetworkContent = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  flex: 1;
  overflow: hidden;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const AnimationArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
`;

const InfoPanel = styled.div`
  flex: 0 0 350px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
  
  @media (max-width: 1024px) {
    flex: 0 0 auto;
  }
`;

const InfoTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #FF9500;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InfoText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const shine = keyframes`
  0% { background-position: -100px; }
  100% { background-position: 200px; }
`;

const UserCircle = styled(motion.div)`
  width: ${props => props.size || 50}px;
  height: ${props => props.size || 50}px;
  border-radius: 50%;
  background: ${props => props.isMain 
    ? "linear-gradient(135deg, #FF6600, #FF9500)" 
    : props.highlight 
      ? "linear-gradient(135deg, #FF9500, #FFCC00)" 
      : "rgba(255, 255, 255, 0.1)"};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  box-shadow: ${props => props.isMain 
    ? "0 0 20px rgba(255, 102, 0, 0.6)" 
    : props.highlight 
      ? "0 0 15px rgba(255, 149, 0, 0.5)" 
      : "0 0 10px rgba(255, 255, 255, 0.1)"};
  border: 2px solid ${props => props.isMain 
    ? "rgba(255, 255, 255, 0.8)" 
    : props.highlight 
      ? "rgba(255, 255, 255, 0.5)" 
      : "rgba(255, 255, 255, 0.1)"};
  z-index: ${props => props.isMain ? 10 : (10 - props.level)};
  cursor: ${props => props.clickable ? "pointer" : "default"};
  animation: ${props => props.pulse ? pulse : "none"} 1.5s ease infinite;
  overflow: visible;
  
  svg {
    font-size: ${props => props.size ? props.size / 2.5 : 20}px;
    color: ${props => props.isMain ? "white" : "rgba(255, 255, 255, 0.9)"};
  }
`;

const EmptySlot = styled(motion.div)`
  width: ${props => props.size || 50}px;
  height: ${props => props.size || 50}px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  border: 2px dashed rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  z-index: ${props => 10 - props.level};
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: scale(1.05);
  }
  
  svg {
    font-size: ${props => props.size ? props.size / 3 : 16}px;
  }
`;

const NetworkLevel = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: ${props => props.marginTop || 30}px;
  z-index: ${props => 10 - props.level};
`;

const NetworkLine = styled(motion.div)`
  position: absolute;
  width: 2px;
  height: ${props => props.height || 30}px;
  background: rgba(255, 149, 0, 0.6);
  top: ${props => props.top || -30}px;
  left: ${props => props.left || '50%'};
  transform: translateX(-50%);
  z-index: 1;
`;

const HorizontalLine = styled(motion.div)`
  position: absolute;
  height: 2px;
  background: rgba(255, 149, 0, 0.6);
  top: ${props => props.top || 0}px;
  left: ${props => props.left || '0'};
  width: ${props => props.width || '100%'};
  z-index: 1;
`;

const AmountBubble = styled(motion.div)`
  position: absolute;
  background: linear-gradient(90deg, rgba(40, 160, 240, 0.7), rgba(100, 200, 255, 0.7));
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  white-space: nowrap;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  top: -10px;
  right: -90px;
  z-index: 15;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    background-size: 200px 100%;
    animation: ${shine} 2s infinite linear;
    border-radius: 15px;
  }
`;

const BonusBubble = styled(motion.div)`
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 102, 0, 0.7), rgba(255, 204, 0, 0.7));
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  white-space: nowrap;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    background-size: 200px 100%;
    animation: ${shine} 2s infinite linear;
    border-radius: 15px;
  }
`;

const CoinsAnimation = styled(motion.div)`
  position: absolute;
  font-size: 20px;
  color: #FFD700;
  animation: ${bounce} 0.8s ease infinite;
  z-index: 20;
  filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.6));
`;

const UserLabel = styled.div`
  position: absolute;
  bottom: -25px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-weight: 600;
`;

const StatsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const StatItem = styled.div`
  flex: 1;
  min-width: 100px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  svg {
    color: #FF9500;
    font-size: 18px;
    margin-bottom: 5px;
  }
`;

const StatLabel = styled.div`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 3px;
`;

const StatValue = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.color || "white"};
`;

const KazancCard = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  border-left: 3px solid #FF9500;
  
  svg {
    color: #FF9500;
    font-size: 18px;
    margin-right: 12px;
  }
`;

const KazancInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const KazancTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #FF9500;
  margin-bottom: 3px;
`;

const KazancDesc = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
`;

const PresentationEarningsAnimation = () => {
  const [step, setStep] = useState(0);
  const [showCoins, setShowCoins] = useState(false);
  const [coinPositions, setCoinPositions] = useState([]);
  const [leftMember, setLeftMember] = useState(null);
  const [rightMember, setRightMember] = useState(null);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [leftVolume, setLeftVolume] = useState(0);
  const [rightVolume, setRightVolume] = useState(0);
  const [nextSlotToPulse, setNextSlotToPulse] = useState('left');
  
  const animationAreaRef = useRef(null);
  
  // Satın alma miktarı ve bonus miktarları
  const purchaseAmount = 100000;
  const referralBonus = purchaseAmount * 0.10; // %10 referral bonus
  
  useEffect(() => {
    // Sunuma başladığında ilk adımı 1 saniye sonra başlat
    const startTimeout = setTimeout(() => {
      setNextSlotToPulse('left');
    }, 1000);
    
    return () => clearTimeout(startTimeout);
  }, []);
  
  useEffect(() => {
    // Adım 1: Sol kola üye ekle
    if (step === 1) {
      // Sol kola üye eklendi
      setLeftMember({
        name: "Yeni Üye",
        purchaseAmount: purchaseAmount,
        bonus: referralBonus
      });
      
      // Sol hacmini güncelle
      setLeftVolume(purchaseAmount);
      
      // Toplam kazancı güncelle
      setTotalEarnings(referralBonus);
      
      // Coin animasyonunu başlat
      startCoinAnimation('left');
      
      // 5 saniye sonra adım 2'ye geç
      setTimeout(() => {
        setStep(2);
        setNextSlotToPulse('right');
      }, 5000);
    } 
    // Adım 2: Sağ kola üye ekle
    else if (step === 2) {
      // Sağ kola üye eklendi
      setRightMember({
        name: "Yeni Üye",
        purchaseAmount: purchaseAmount,
        bonus: referralBonus
      });
      
      // Sağ hacmini güncelle
      setRightVolume(purchaseAmount);
      
      // Toplam kazancı güncelle
      setTotalEarnings(prev => prev + referralBonus);
      
      // Coin animasyonunu başlat
      startCoinAnimation('right');
      
      // 5 saniye sonra sunumu sıfırla
      setTimeout(() => {
        resetPresentation();
      }, 5000);
    }
  }, [step]);
  
  // Coin animasyonunu başlat
  const startCoinAnimation = (position) => {
    if (animationAreaRef.current) {
      const areaRect = animationAreaRef.current.getBoundingClientRect();
      const newPositions = [];
      
      // Hangi düğümden coin animasyonu gösterilecek
      let sourceY, sourceX;
      
      if (position === 'left') {
        // Sol koldan ana düğüme akış
        sourceY = 170;
        sourceX = areaRect.width * 0.3;
      } else if (position === 'right') {
        // Sağ koldan ana düğüme akış
        sourceY = 170;
        sourceX = areaRect.width * 0.7;
      }
      
      // Ana düğüme coin akışını oluştur
      const destX = areaRect.width * 0.5;
      const destY = 50;
      
      for (let i = 0; i < 5; i++) {
        newPositions.push({
          id: i,
          startX: sourceX + (Math.random() * 20 - 10),
          startY: sourceY + (Math.random() * 20 - 10),
          endX: destX + (Math.random() * 20 - 10),
          endY: destY + (Math.random() * 20 - 10),
          delay: i * 0.1,
        });
      }
      
      setShowCoins(true);
      setCoinPositions(newPositions);
      
      setTimeout(() => {
        setShowCoins(false);
      }, 2000);
    }
  };
  
  // Yeni üye ekleme işlemi
  const addNewMember = () => {
    // Eğer sol kol boşsa oraya ekle
    if (step === 0) {
      setStep(1);
    }
  };
  
  // Sunumu sıfırla
  const resetPresentation = () => {
    setStep(0);
    setLeftMember(null);
    setRightMember(null);
    setTotalEarnings(0);
    setLeftVolume(0);
    setRightVolume(0);
    setNextSlotToPulse('left');
    
    // 2 saniye sonra tekrar başlat
    setTimeout(() => {
      setNextSlotToPulse('left');
    }, 2000);
  };

  return (
    <EarningsContainer>
      <ContentWrapper>
        <Header>
          <Title>Binary Network Kazanç Sistemi</Title>
          <Subtitle>
            MyFutureToken sisteminde, ağınızı genişlettikçe hem derinlik hem de genişlik kazançları elde edersiniz
          </Subtitle>
        </Header>
        
        <NetworkContent>
          <AnimationArea ref={animationAreaRef}>
            {/* Ana Düğüm (Siz) */}
            <NetworkLevel level={1}>
              <UserCircle
                size={65}
                isMain
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <FaUser />
                <UserLabel>Siz</UserLabel>
                
                {(step > 0) && (
                  <BonusBubble
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <FaCoins /> Bonus: {totalEarnings.toLocaleString()} MFT
                  </BonusBubble>
                )}
              </UserCircle>
            </NetworkLevel>
            
            {/* Orta Düğümler (Sol ve Sağ Kol) */}
            <NetworkLevel level={2} marginTop={80}>
              <div style={{ position: 'relative', display: 'flex', width: '300px', justifyContent: 'space-between' }}>
                {/* Orta Bağlantı Çizgileri */}
                <NetworkLine 
                  height={50} 
                  initial={{ height: 0 }}
                  animate={{ height: 50 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                />
                <HorizontalLine 
                  width="300px" 
                  left="calc(50% - 150px)" 
                  initial={{ width: 0, left: "50%" }}
                  animate={{ width: "300px", left: "calc(50% - 150px)" }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                />
                
                {/* Sol Kol */}
                {leftMember ? (
                  <UserCircle
                    size={50}
                    highlight={true}
                    level={2}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <FaUser />
                    <UserLabel>{leftMember.name}</UserLabel>
                    
                    <AmountBubble
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3, delay: 0.2 }}
                    >
                      <FaCoins /> {leftMember.purchaseAmount.toLocaleString()} MFT
                    </AmountBubble>
                    
                    <BonusBubble
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.4 }}
                    >
                      <FaCoins /> Bonus: {leftMember.bonus.toLocaleString()} MFT
                    </BonusBubble>
                  </UserCircle>
                ) : (
                  <EmptySlot 
                    size={50} 
                    level={2}
                    pulse={nextSlotToPulse === 'left'}
                    onClick={addNewMember}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                  >
                    <FaPlus />
                    <UserLabel>Sol Kol</UserLabel>
                  </EmptySlot>
                )}
                
                {/* Sağ Kol */}
                {rightMember ? (
                  <UserCircle
                    size={50}
                    highlight={true}
                    level={2}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <FaUser />
                    <UserLabel>{rightMember.name}</UserLabel>
                    
                    <AmountBubble
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3, delay: 0.2 }}
                    >
                      <FaCoins /> {rightMember.purchaseAmount.toLocaleString()} MFT
                    </AmountBubble>
                    
                    <BonusBubble
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.4 }}
                    >
                      <FaCoins /> Bonus: {rightMember.bonus.toLocaleString()} MFT
                    </BonusBubble>
                  </UserCircle>
                ) : (
                  <EmptySlot 
                    size={50} 
                    level={2}
                    pulse={nextSlotToPulse === 'right'}
                    onClick={addNewMember}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                  >
                    <FaPlus />
                    <UserLabel>Sağ Kol</UserLabel>
                  </EmptySlot>
                )}
              </div>
            </NetworkLevel>
            
            {/* Coin Animasyonu */}
            {showCoins && 
              coinPositions.map(position => (
                <CoinsAnimation
                  key={position.id}
                  initial={{ x: position.startX, y: position.startY, opacity: 0, scale: 0.5 }}
                  animate={{ x: position.endX, y: position.endY, opacity: 1, scale: 1 }}
                  transition={{ 
                    duration: 1, 
                    delay: position.delay,
                    ease: "easeOut"
                  }}
                >
                  <FaCoins />
                </CoinsAnimation>
              ))
            }
            
            {/* İlk adımda yönerge göster */}
            {step === 0 && nextSlotToPulse === 'left' && (
              <InfoText style={{ position: 'absolute', bottom: '10px', textAlign: 'center' }}>
                Bu demo, binary sistemde üye eklendiğinde oluşan kazanç akışını gösterir. 
                Yanıp sönen + simgesine tıklayarak başlayın.
              </InfoText>
            )}
          </AnimationArea>
          
          <InfoPanel>
            <InfoTitle>
              <FaMoneyBillWave /> Kazanç Sistemi Özeti
            </InfoTitle>
            
            <StatsBox>
              <StatItem>
                <FaChartLine />
                <StatLabel>Sol Hacim</StatLabel>
                <StatValue>{leftVolume.toLocaleString()}</StatValue>
              </StatItem>
              <StatItem>
                <FaChartLine />
                <StatLabel>Sağ Hacim</StatLabel>
                <StatValue>{rightVolume.toLocaleString()}</StatValue>
              </StatItem>
              <StatItem>
                <FaDollarSign />
                <StatLabel>Toplam Bonus</StatLabel>
                <StatValue color="#FF9500">{totalEarnings.toLocaleString()}</StatValue>
              </StatItem>
            </StatsBox>
            
            <InfoTitle>
              <FaCoins /> Kazanç Çeşitleri
            </InfoTitle>
            
            <KazancCard>
              <FaCoins />
              <KazancInfo>
                <KazancTitle>Referans Komisyonu</KazancTitle>
                <KazancDesc>Doğrudan referansınızdan %10 komisyon alırsınız</KazancDesc>
              </KazancInfo>
            </KazancCard>
            
            <KazancCard>
              <FaChartLine />
              <KazancInfo>
                <KazancTitle>Binary Kazanç</KazancTitle>
                <KazancDesc>Zayıf kol üzerinden haftalık hesaplanan ödeme</KazancDesc>
              </KazancInfo>
            </KazancCard>
            
            <KazancCard>
              <FaArrowUp />
              <KazancInfo>
                <KazancTitle>Eşleştirme Bonusu</KazancTitle>
                <KazancDesc>Alt hatların kazançlarından 5 seviye derinliğe bonus</KazancDesc>
              </KazancInfo>
            </KazancCard>
            
            <InfoText>
              Dengeli bir ağ oluşturmak, kazançlarınızı maksimize etmenizin anahtarıdır.
              Ağınıza dahil olan her üye network genişledikçe gelirlerinize katkı sağlar.
            </InfoText>
          </InfoPanel>
        </NetworkContent>
      </ContentWrapper>
    </EarningsContainer>
  );
};

export default PresentationEarningsAnimation; 