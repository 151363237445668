import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import * as d3 from "d3";

const TokenomicsContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #0c1015 0%, #1a1c35 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 85%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.4;
`;

const TokenomicsContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;
  gap: 40px;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const ChartContainer = styled.div`
  flex: 0 0 auto;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
`;

const TokenSymbol = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 800;
  color: white;
  text-align: center;
  
  div {
    font-size: 18px;
    margin-top: 3px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const AllocationList = styled.div`
  flex: 0 0 auto;
  width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 16px;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  
  /* Scrollbar stilini özelleştirme */
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const AllocationCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const AllocationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const AllocationTitle = styled.h3`
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  color: white;
`;

const AllocationPercentage = styled.span`
  font-size: 14px;
  font-weight: 700;
  background: ${props => props.color || "linear-gradient(90deg, #FF6600, #FFCC00)"};
  padding: 3px 10px;
  border-radius: 20px;
  color: #000;
`;

const AllocationDescription = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  line-height: 1.35;
  margin: 0;
  margin-bottom: 8px;
`;

const TokenAmount = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 600;
`;

const allocations = [
  {
    title: "Presale",
    percentage: 20,
    color: "#FF6600",
    description: "ICO öncesi satışlar için ayrılmış token miktarı. Bu tokenler, erken aşama yatırımcılara özel fiyatlarla sunulacaktır.",
    amount: "2.000.000.000 MFT"
  },
  {
    title: "Staking Ödülleri",
    percentage: 25,
    color: "#FF9500",
    description: "Token sahiplerinin MFT'lerini stake ederek kazanacakları ödüller için ayrılmıştır. Yıllık %8-15 getiri sunulmaktadır.",
    amount: "2.500.000.000 MFT"
  },
  {
    title: "Bonus Dağıtımı",
    percentage: 5,
    color: "#FFC400",
    description: "Topluluk etkinlikleri, teşvik programları ve referans bonusları için ayrılmış tokenler.",
    amount: "500.000.000 MFT"
  },
  {
    title: "Pazarlama",
    percentage: 15,
    color: "#00C3FF",
    description: "Pazarlama kampanyaları, ortaklıklar ve ekosistem büyütme çalışmaları için ayrılmış fon.",
    amount: "1.500.000.000 MFT"
  },
  {
    title: "Proje Fonu",
    percentage: 20,
    color: "#8A2BE2",
    description: "Yapay zeka projelerinin geliştirilmesi ve teknoloji yatırımları için ayrılmış stratejik fon.",
    amount: "2.000.000.000 MFT"
  },
  {
    title: "Likidite Rezervleri",
    percentage: 15,
    color: "#44D7B6",
    description: "Kripto borsalarında likidite sağlamak ve fiyat istikrarını korumak için ayrılmış rezerv.",
    amount: "1.500.000.000 MFT"
  }
];

const PresentationTokenomics = () => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    if (!chartRef.current) return;
    
    // SVG boyutları ve dizayn değişkenleri
    const width = 450;
    const height = 450;
    const radius = Math.min(width, height) / 2 - 20; // Kenar payı için 20px azalttım
    
    // Eski chart'ı temizle
    d3.select(chartRef.current).selectAll("*").remove();
    
    const svg = d3.select(chartRef.current);
    
    const pie = d3.pie()
      .value(d => d.percentage)
      .sort(null);
    
    const arc = d3.arc()
      .innerRadius(radius * 0.5)
      .outerRadius(radius * 0.75);
    
    const outerArc = d3.arc()
      .innerRadius(radius * 0.85)
      .outerRadius(radius * 0.85);
    
    const g = svg.append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);
    
    // Ark çizimi
    const arcs = g.selectAll(".arc")
      .data(pie(allocations))
      .enter()
      .append("g")
      .attr("class", "arc");
    
    arcs.append("path")
      .attr("d", arc)
      .attr("fill", d => d.data.color)
      .attr("stroke", "rgba(0, 0, 0, 0.3)")
      .attr("stroke-width", 1)
      .style("transition", "all 0.3s ease")
      .on("mouseover", function() {
        d3.select(this)
          .attr("transform", "scale(1.05)")
          .attr("filter", "drop-shadow(0 0 10px rgba(255, 255, 255, 0.3))");
      })
      .on("mouseout", function() {
        d3.select(this)
          .attr("transform", "scale(1)")
          .attr("filter", "none");
      });
    
    // Yüzde etiketleri
    arcs.append("text")
      .attr("transform", d => {
        const pos = outerArc.centroid(d);
        const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.85 * (midAngle < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .attr("dy", ".35em")
      .style("text-anchor", d => {
        const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midAngle < Math.PI ? "start" : "end";
      })
      .attr("fill", "white")
      .style("font-size", "11px")
      .style("font-weight", "bold")
      .text(d => `${d.data.percentage}%`);
    
    // Çizgiler
    arcs.append("polyline")
      .attr("points", d => {
        const pos = outerArc.centroid(d);
        const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.85 * (midAngle < Math.PI ? 1 : -1);
        return [arc.centroid(d), outerArc.centroid(d), pos];
      })
      .attr("fill", "none")
      .attr("stroke", "rgba(255, 255, 255, 0.5)")
      .attr("stroke-width", 1);
    
  }, []);
  
  return (
    <TokenomicsContainer>
      <ContentWrapper>
        <Header>
          <Title>Tokenomik Yapı</Title>
          <Subtitle>
            10 milyar MFT token, MyFutureToken projesinin sürdürülebilir büyümesini ve gelişimini desteklemek için
            stratejik olarak dağıtılmıştır
          </Subtitle>
        </Header>
        
        <TokenomicsContent>
          <ChartContainer>
            <StyledSVG ref={chartRef} viewBox="0 0 450 450" preserveAspectRatio="xMidYMid meet" />
            <TokenSymbol>
              MFT
              <div>10 Milyar</div>
            </TokenSymbol>
          </ChartContainer>
          
          <AllocationList>
            {allocations.map((allocation, index) => (
              <AllocationCard 
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <AllocationHeader>
                  <AllocationTitle>{allocation.title}</AllocationTitle>
                  <AllocationPercentage color={allocation.color}>{allocation.percentage}%</AllocationPercentage>
                </AllocationHeader>
                <AllocationDescription>{allocation.description}</AllocationDescription>
                <TokenAmount>{allocation.amount}</TokenAmount>
              </AllocationCard>
            ))}
          </AllocationList>
        </TokenomicsContent>
      </ContentWrapper>
    </TokenomicsContainer>
  );
};

export default PresentationTokenomics;