import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FaBitcoin, FaLock, FaEnvelope, FaUser, FaArrowRight } from 'react-icons/fa';

// Animasyonlar
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-50px); }
`;

const slideIn = keyframes`
  from { opacity: 0; transform: translateY(50px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

// Stil Bileşenleri
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #121212 0%, #1e2132 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
`;

const BackgroundEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(247, 148, 29, 0.1) 0%, rgba(0, 0, 0, 0) 70%);
  z-index: -1;
  
  &::before, &::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: rgba(247, 148, 29, 0.05);
    filter: blur(50px);
  }
  
  &::before {
    top: 20%;
    left: 20%;
    animation: ${pulse} 15s infinite alternate;
  }
  
  &::after {
    bottom: 20%;
    right: 20%;
    animation: ${pulse} 20s infinite alternate;
  }
`;

const ParticleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
`;

const Particle = styled.div`
  position: absolute;
  width: ${props => props.size || '2px'};
  height: ${props => props.size || '2px'};
  background-color: rgba(247, 148, 29, ${props => props.opacity || '0.3'});
  border-radius: 50%;
  top: ${props => props.top};
  left: ${props => props.left};
  animation: ${pulse} ${props => props.duration || '3s'} infinite alternate;
`;

const LoginCard = styled.div`
  background: rgba(30, 33, 50, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 40px;
  width: 400px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: ${fadeIn} 0.8s ease-out;
  transition: all 0.5s ease;
  transform: ${props => props.isSuccess ? 'scale(0.9) translateY(-20px)' : 'scale(1)'};
  opacity: ${props => props.isSuccess ? 0 : 1};
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  
  svg {
    font-size: 4rem;
    color: #F7941D;
    animation: ${rotate} 10s linear infinite;
  }
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: 700;
  
  span {
    color: #F7941D;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #F7941D, transparent);
      background-size: 200% 100%;
      animation: ${shimmer} 2s infinite;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputGroup = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 45px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #F7941D;
    box-shadow: 0 0 10px rgba(247, 148, 29, 0.3);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const InputIcon = styled.div`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
  
  ${Input}:focus + & {
    color: #F7941D;
  }
`;

const LoginButton = styled.button`
  background: linear-gradient(135deg, #F7941D 0%, #e88710 100%);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(247, 148, 29, 0.4);
  }
  
  &:disabled {
    background: #666;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  svg {
    transition: transform 0.3s ease;
  }
  
  &:hover svg {
    transform: translateX(5px);
  }
`;

const ErrorMessage = styled.div`
  color: #ff5555;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 15px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const WelcomeCard = styled.div`
  background: rgba(30, 33, 50, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 40px;
  width: 500px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5), 0 0 50px rgba(247, 148, 29, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: absolute;
  display: ${props => props.show ? 'block' : 'none'};
  animation: ${slideIn} 0.8s ease-out, ${pulse} 2s infinite alternate;
  text-align: center;
`;

const WelcomeTitle = styled.h1`
  color: #F7941D;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const WelcomeText = styled.p`
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const UserInfo = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  
  h3 {
    color: #F7941D;
    margin-bottom: 10px;
  }
  
  p {
    color: rgba(255, 255, 255, 0.8);
    margin: 5px 0;
  }
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #F7941D;
  animation: ${rotate} 1s linear infinite;
  margin: 20px auto;
`;

// Kullanıcı verileri
const users = [
  {
    email: 'mazlum@gmail.com',
    password: '99558843!.!',
    name: 'Mazlum',
    role: 'Country Sales Manager'
  },
  {
    email: 'ismetdemirer@gmail.com',
    password: 'iiSm129900',
    name: 'Ismet',
    role: 'CTO of Company'
  }
];

// Rastgele parçacıklar oluşturan fonksiyon
const generateParticles = (count) => {
  const particles = [];
  for (let i = 0; i < count; i++) {
    particles.push({
      id: i,
      size: `${Math.random() * 3 + 1}px`,
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      opacity: Math.random() * 0.5,
      duration: `${Math.random() * 5 + 3}s`
    });
  }
  return particles;
};

const BinaryLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [particles] = useState(() => generateParticles(50));
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    // Kullanıcı doğrulama
    setTimeout(() => {
      const user = users.find(u => u.email === email && u.password === password);
      
      if (user) {
        setCurrentUser(user);
        setLoginSuccess(true);
        
        setTimeout(() => {
          setShowWelcome(true);
          
          // BinaryNetworkSystem'e yönlendirme
          setTimeout(() => {
            navigate('/binary-network-simulator');
          }, 3000);
        }, 800);
      } else {
        setError('Ungültige E-Mail oder Passwort. Bitte versuchen Sie es erneut.');
        setIsLoading(false);
      }
    }, 1500);
  };
  
  return (
    <Container>
      <BackgroundEffect />
      <ParticleContainer>
        {particles.map(particle => (
          <Particle 
            key={particle.id}
            size={particle.size}
            top={particle.top}
            left={particle.left}
            opacity={particle.opacity}
            duration={particle.duration}
          />
        ))}
      </ParticleContainer>
      
      <LoginCard isSuccess={loginSuccess}>
        <Logo>
          <FaBitcoin />
        </Logo>
        <Title>
          Binary Network <span>Marketing System</span>
        </Title>
        
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Input 
              type="email" 
              placeholder="E-Mail Adresse" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <InputIcon>
              <FaEnvelope />
            </InputIcon>
          </InputGroup>
          
          <InputGroup>
            <Input 
              type="password" 
              placeholder="Passwort" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <InputIcon>
              <FaLock />
            </InputIcon>
          </InputGroup>
          
          <LoginButton type="submit" disabled={isLoading}>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                Anmelden <FaArrowRight />
              </>
            )}
          </LoginButton>
        </Form>
        
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </LoginCard>
      
      {showWelcome && currentUser && (
        <WelcomeCard show={showWelcome}>
          <WelcomeTitle>Willkommen zurück!</WelcomeTitle>
          <UserInfo>
            <h3>{currentUser.name}</h3>
            <p>{currentUser.role}</p>
            <p>{currentUser.email}</p>
          </UserInfo>
          <WelcomeText>
            Das Binary Network Marketing System wird geladen...
          </WelcomeText>
          <LoadingSpinner />
        </WelcomeCard>
      )}
    </Container>
  );
};

export default BinaryLogin; 