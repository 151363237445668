import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TimelineContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #12151c 0%, #1f2341 100%);
  color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimelineWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TimelineHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const TimelineTitle = styled.h2`
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #FF6600, #FFCC00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const TimelineSubtitle = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TimelineContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow-y: auto;
  padding: 0 10px 0 0;
  
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 102, 0, 0.7), rgba(255, 204, 0, 0.7));
    border-radius: 5px;
    z-index: 1;
  }
  
  /* Scrollbar stilini özelleştirme */
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  @media (max-width: 768px) {
    &:before {
      left: 30px;
    }
  }
`;

const TimelineItem = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  position: relative;
  margin-bottom: 20px;
  width: 50%;
  z-index: 2;
  
  &:nth-child(even) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 0;
    margin-left: auto;
  }
  
  &:before {
    content: "";
    position: absolute;
    top: 20px;
    right: -14px;
    width: 26px;
    height: 26px;
    background: ${props => props.color || "#FF6600"};
    border-radius: 50%;
    box-shadow: 0 0 15px ${props => props.color || "rgba(255, 102, 0, 0.7)"};
    z-index: 3;
  }
  
  &:nth-child(even):before {
    right: auto;
    left: -14px;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 50px;
    padding-right: 0;
    margin-bottom: 20px;
    
    &:nth-child(even) {
      padding-left: 50px;
      padding-right: 0;
    }
    
    &:before {
      left: 15px;
      right: auto;
      width: 20px;
      height: 20px;
    }
    
    &:nth-child(even):before {
      left: 15px;
    }
  }
`;

const TimelineContent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 18px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  width: 100%;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 102, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const QuarterLabel = styled.span`
  font-size: 13px;
  font-weight: 700;
  color: ${props => props.color || "#FF6600"};
  background: rgba(255, 102, 0, 0.1);
  padding: 4px 10px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 8px;
`;

const ItemTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 8px 0;
  color: white;
`;

const ItemDescription = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
`;

const timelineData = [
  {
    quarter: "Q3 2023",
    title: "Konsept Geliştirme",
    description: "MyFutureToken'in temel konsepti, tokenomik modeli ve iş planı oluşturuldu.",
    color: "#FF6600"
  },
  {
    quarter: "Q4 2023",
    title: "Altyapı ve UX Tasarımı",
    description: "Teknik altyapının ilk adımları, UI/UX tasarımları ve web platformu çalışmaları başladı.",
    color: "#FF9500"
  },
  {
    quarter: "Q1 2024",
    title: "Güvenlik ve Optimizasyon",
    description: "Akıllı kontrat güvenlik protokolleri, sistem mimarisi ve performans optimizasyonu çalışmaları tamamlandı.",
    color: "#FFCC00"
  },
  {
    quarter: "Q2 2024",
    title: "Network Marketing Sistemi",
    description: "Binary network marketing sistemi geliştirildi, komisyon ve ödeme yapısı oluşturuldu.",
    color: "#00C3FF"
  },
  {
    quarter: "Q3-Q4 2024",
    title: "Beta ve Testler",
    description: "Halka açık beta testleri, stres testleri ve güvenlik denetimleri yapılacak, topluluk geri bildirimleri değerlendirilecek.",
    color: "#8A2BE2"
  },
  {
    quarter: "Q1 2025",
    title: "ICO Öncesi Hazırlıklar",
    description: "Token ön satış dönemi başlayacak, pazarlama kampanyaları ve stratejik ortaklıklar devreye girecek.",
    color: "#44D7B6"
  },
  {
    quarter: "Q2 2025",
    title: "ICO ve Tam Lansman",
    description: "Resmi ICO başlangıcı, büyük borsalarda listeleme ve platform tam lansman aşaması gerçekleştirilecek.",
    color: "#FF6347"
  },
  {
    quarter: "Q3-Q4 2025",
    title: "Ekosistem Genişletme",
    description: "Yapay zeka projelerine yatırım, yeni fonksiyonlar ve global ekosistem genişletme çalışmaları başlayacak.",
    color: "#4682B4"
  }
];

const PresentationAdvancedTimeline = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false
  });
  
  return (
    <TimelineContainer>
      <TimelineWrapper>
        <TimelineHeader>
          <TimelineTitle>MyFutureToken Gelişim Yol Haritası</TimelineTitle>
          <TimelineSubtitle>
            MyFutureToken'in kuruluşundan tam lansmana kadar geçecek süreçteki kilometre taşları 
            ve projemizin stratejik gelişim planı
          </TimelineSubtitle>
        </TimelineHeader>
        
        <TimelineContentWrapper ref={ref}>
          {timelineData.map((item, index) => (
            <TimelineItem
              key={index}
              color={item.color}
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <TimelineContent>
                <QuarterLabel color={item.color}>{item.quarter}</QuarterLabel>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemDescription>{item.description}</ItemDescription>
              </TimelineContent>
            </TimelineItem>
          ))}
        </TimelineContentWrapper>
      </TimelineWrapper>
    </TimelineContainer>
  );
};

export default PresentationAdvancedTimeline; 