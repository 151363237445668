import React, { useState, useEffect } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import Parse from "parse";
import BuyTokenWizard from "../About/BuyTokenWizard";
import { useInvitation } from '../../context/InvitationContext';
import "../../assets/css/HeaderTwo.css";

const HeaderTwo = () => {
  const [menuItem1, setmenuItem1] = useState("");
  const [menuItem2, setmenuItem2] = useState("");
  const [menuItem3, setmenuItem3] = useState("");
  const [menuItem4, setmenuItem4] = useState("");
  const [menuItem5, setmenuItem5] = useState("");
  const [menuItem6, setmenuItem6] = useState("");
  const { isInvited, inviterName } = useInvitation();

  const [show, setShow] = useState(false);
  const [showBuyToken, setShowBuyToken] = useState(false);
  const [showBuyTokenV2, setShowBuyTokenV2] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleBuyTokenShow = () => setShowBuyToken(true);
  const handleBuyTokenClose = () => setShowBuyToken(false);
  const handleBuyTokenShowV2 = () => setShowBuyTokenV2(true);
  const handleBuyTokenCloseV2 = () => setShowBuyTokenV2(false);

  const menuItems = [
    {
      title: "Home",
      path: "#header",
      hasDropdown: false,
    },
    {
      title: "Über uns",
      path: "#about",
      hasDropdown: false,
    },
    {
      title: "Roadmap",
      path: "#roadmap",
      hasDropdown: false,
    },
    {
      title: "FAQ",
      path: "#faq",
      hasDropdown: false,
    },
    {
      title: "Kontakt",
      path: "#contact",
      hasDropdown: false,
    }
  ];

  useEffect(() => {
    const MyFaceCoin = Parse.Object.extend("Menus");
    const query = new Parse.Query(MyFaceCoin);
    query.first().then((object) => {
      if (object) {
        setmenuItem1(object.get("menuItem1"));
        setmenuItem2(object.get("menuItem2"));
        setmenuItem3(object.get("menuItem3"));
        setmenuItem4(object.get("menuItem4"));
        setmenuItem5(object.get("menuItem5"));
        setmenuItem6(object.get("menuItem6"));
      } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });
  }, []);

  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    handleClose(); // Mobil menüyü kapat
  };

  const { hash } = useLocation();
  const isActiveLink = (id) => {
    return id === hash ? "active" : "";
  };

  return (
    <header id="header">
      {isInvited && inviterName && (
        <div style={{
          backgroundColor: '#F7941D',
          color: 'white',
          textAlign: 'center',
          padding: '10px',
          fontWeight: 'bold',
          fontFamily: 'Poppins, sans-serif'
        }}>
          Sie wurden von {inviterName} eingeladen. Wenn Sie mindestens 1000 MFT kaufen, erhalten Sie und {inviterName} jeweils 10% MFT als Bonus!
        </div>
      )}

      <div id="sticky-header" className={cn("menu-area transparent-header", stickyClass.header)}>
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler d-lg-none" onClick={handleShow}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img src={logoImage} alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      {menuItems.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.path}
                            className={cn("section-link", isActiveLink(item.path))}
                            onClick={() => handleClickScroll(item.path.substring(1))}
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-lang">
                        <span className="selected-lang">GER</span>
                        <ul className="lang-list">
                          <li><Link to="#">GER</Link></li>
                          <li><Link to="#">ENG</Link></li>
                        </ul>
                      </li>
                      <li className="header-btn">
                        <button className="btn" onClick={handleBuyTokenShow}>
                          Token kaufen
                        </button>
                        <button className="btn" onClick={handleBuyTokenShowV2}>
                          Buy & JoinNetwork  
                        </button>

                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                  <div className="logo">
                    <Link to="/">
                      <img src={logoImage} alt="Logo" />
                    </Link>
                  </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <ul className="nav-mobile">
                    {menuItems.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.path}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClickScroll(item.path.substring(1));
                          }}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="nav-mobile-btn">
                    <button onClick={() => {
                      handleClose();
                      handleBuyTokenShow();
                    }}>
                      Token kaufen
                    </button>
                    <button className="btn" onClick={handleBuyTokenShowV2}>
                      Buy & JoinNetwork
                    </button>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </div>
      </div>

        

      <Modal
        show={showBuyToken}
        onHide={handleBuyTokenClose}
        centered
        size="lg"
        className="buy-token-modal"
        backdropClassName="buy-token-overlay"
      >
        <Modal.Body className="buy-token-modal-body">
          <BuyTokenWizard onClose={handleBuyTokenClose} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showBuyTokenV2}
        onHide={handleBuyTokenCloseV2}
        centered
        size="lg"
        className="buy-token-modal"
        backdropClassName="buy-token-overlay"
      >
        <Modal.Body className="buy-token-modal-body">
          <BuyTokenWizard isOpen={showBuyTokenV2} onClose={handleBuyTokenCloseV2} />
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default HeaderTwo;
