import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FaChevronLeft, FaChevronRight, FaExpand, FaPause, FaPlay, FaTimes } from "react-icons/fa";
import { 
  Presentation, 
  PresentationAdvancedTimeline, 
  PresentationTokenomics, 
  PresentationNetworkSystem,
  PresentationEarningsAnimation,
  PresentationNetworkRules
} from "../../components/Presentation";

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #080a12;
  color: white;
  overflow: hidden;
  z-index: 9999;
`;

const ControlsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  z-index: 10000;
`;

const ControlButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
`;

const ExitButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10000;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }
`;

const SlidesContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const PresentationPage = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  const sections = [
    { component: <Presentation />, key: "main-slides" },
    { component: <PresentationTokenomics />, key: "tokenomics" },
    { component: <PresentationNetworkSystem />, key: "network-system" },
    { component: <PresentationEarningsAnimation />, key: "earnings-animation" },
    { component: <PresentationNetworkRules />, key: "network-rules" },
    { component: <PresentationAdvancedTimeline />, key: "timeline" }
  ];
  
  const nextSection = () => {
    if (currentSection < sections.length - 1) {
      setCurrentSection(prevSection => prevSection + 1);
    }
  };
  
  const prevSection = () => {
    if (currentSection > 0) {
      setCurrentSection(prevSection => prevSection - 1);
    }
  };
  
  const togglePause = () => {
    setIsPaused(!isPaused);
  };
  
  const toggleFullscreen = () => {
    const element = document.documentElement;
    
    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    
    setIsFullscreen(!isFullscreen);
  };
  
  const exitPresentation = () => {
    window.location.href = "/";
  };
  
  const handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      nextSection();
    } else if (e.key === "ArrowLeft") {
      prevSection();
    } else if (e.key === "Escape") {
      if (isFullscreen) {
        toggleFullscreen();
      } else {
        exitPresentation();
      }
    } else if (e.key === " ") {
      togglePause();
    } else if (e.key === "f") {
      toggleFullscreen();
    }
  };
  
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSection, isFullscreen, isPaused]);
  
  return (
    <PageContainer>
      <SlidesContainer>
        <AnimatePresence mode="wait">
          <motion.div
            key={sections[currentSection].key}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{ width: "100%", height: "100%" }}
          >
            {sections[currentSection].component}
          </motion.div>
        </AnimatePresence>
      </SlidesContainer>
      
      <ExitButton onClick={exitPresentation}>
        <FaTimes />
      </ExitButton>
      
      <ControlsContainer>
        <ControlButton 
          onClick={prevSection} 
          disabled={currentSection === 0}
        >
          <FaChevronLeft />
        </ControlButton>
        
        <ControlButton onClick={togglePause}>
          {isPaused ? <FaPlay /> : <FaPause />}
        </ControlButton>
        
        <PageIndicator>
          {currentSection + 1} / {sections.length}
        </PageIndicator>
        
        <ControlButton onClick={toggleFullscreen}>
          <FaExpand />
        </ControlButton>
        
        <ControlButton 
          onClick={nextSection} 
          disabled={currentSection === sections.length - 1}
        >
          <FaChevronRight />
        </ControlButton>
      </ControlsContainer>
    </PageContainer>
  );
};

export default PresentationPage; 