import styled from 'styled-components';

// TypeScript için prop tipleri tanımlamaları
interface LanguageButtonProps {
  active?: boolean;
}

interface InfoTextProps {
  isMinimized?: boolean;
}

interface BinaryTreeContainerProps {
  zoomLevel?: number;
}

interface TreeLevelProps {
  isRoot?: boolean;
}

interface NodeWrapperProps {
  level?: number;
}

interface NodeCardProps {
  side?: 'left' | 'right' | 'root';
  isActive?: boolean;
}

interface NodeInfoProps {
  side?: 'left' | 'right' | 'root';
}

interface BadgeProps {
  color?: string;
}

// Styled Components
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, #1e2132 0%, #2d3348 100%);
  color: #fff;
  padding: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: none;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  color: #F7941D;
  margin: 0;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 12px;
  }
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const LanguageSwitch = styled.div`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const LanguageButton = styled.button<LanguageButtonProps>`
  background: ${props => props.active ? 'rgba(247, 148, 29, 0.8)' : 'transparent'};
  color: ${props => props.active ? 'white' : 'rgba(255, 255, 255, 0.6)'};
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    background: ${props => props.active ? 'rgba(247, 148, 29, 0.8)' : 'rgba(255, 255, 255, 0.1)'};
  }
  
  svg {
    font-size: 1rem;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    color: #F7941D;
    transform: scale(1.1);
  }
`;

const BinarySystemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  
  /* Tarayıcı geri düğmesine yanlışlıkla basılmasını engellemek için */
  overscroll-behavior: contain;
`;

const MinimizeButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: #F7941D;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  transition: all 0.2s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  
  &:hover {
    background: rgba(0, 0, 0, 0.9);
    transform: scale(1.1);
  }
  
  svg {
    font-size: 18px;
  }
`;

const StatsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  background: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const StatsSection = styled.div`
  flex: 1;
  min-width: 300px;
  padding: 0 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  
  &:last-child {
    border-right: none;
  }
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 10px 0;
    color: #F7941D;
    display: flex;
    align-items: center;
    
    svg {
      margin-right: 8px;
    }
  }
`;

const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 15px;
  margin: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  min-width: 200px;
  flex: 1;
  
  .label-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .label {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
  }
  
  .info-icon {
    margin-left: 5px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    cursor: pointer;
    transition: color 0.2s ease;
    
    &:hover {
      color: #F7941D;
    }
  }
  
  .value {
    font-weight: 600;
    color: #fff;
    font-size: 0.9rem;
  }
  
  .highlight {
    color: #F7941D;
  }
  
  .usd-value {
    font-size: 0.75rem;
    color: #4dffa7;
    margin-left: 4px;
  }
`;

const FloatingCard = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 800px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
`;

const FloatingCardHeader = styled.div`
  background: rgba(247, 148, 29, 0.2);
  padding: 12px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h3 {
    margin: 0;
    font-size: 1rem;
    color: #F7941D;
    display: flex;
    align-items: center;
    
    svg {
      margin-right: 8px;
    }
  }
`;

const FloatingCardContent = styled.div`
  padding: 15px;
  overflow-y: auto;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &:hover {
    color: #F7941D;
  }
`;

const Sidebar = styled.div`
  width: 350px;
  background: rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const AddPersonForm = styled.form`
  width: 100%;
  margin: 0;
`;

const FormTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 15px 0;
  color: #F7941D;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 8px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  
  label {
    display: block;
    margin-bottom: 6px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    
    .info-icon {
      margin-left: 5px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.8rem;
      cursor: pointer;
      transition: color 0.2s ease;
      
      &:hover {
        color: #F7941D;
      }
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: #F7941D;
    background: rgba(255, 255, 255, 0.08);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: #F7941D;
  }
  
  option {
    background: #2d3348;
  }
`;

const AddButton = styled.button`
  background: #F7941D;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 100%;
  
  svg {
    margin-right: 8px;
  }

  &:hover {
    background: #e88710;
    transform: translateY(-2px);
  }
`;

const SummarySection = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const BonusSection = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  margin-top: auto;
`;

const SummaryTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 15px 0;
  color: #F7941D;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 8px;
  }
`;

const InfoSection = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 15px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  border-left: 3px solid #F7941D;
  max-width: 600px;
  z-index: 1000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const InfoTitle = styled.h4`
  font-size: 0.9rem;
  margin: 0 0 8px 0;
  color: #F7941D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .title-text {
    display: flex;
    align-items: center;
    
    svg {
      margin-right: 8px;
    }
  }
`;

const InfoText = styled.p<InfoTextProps>`
  font-size: 0.8rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  overflow: hidden;
  max-height: ${props => props.isMinimized ? '0' : '200px'};
  opacity: ${props => props.isMinimized ? '0' : '1'};
  transition: all 0.3s ease;
`;

const InfoToggleButton = styled.button`
  background: none;
  border: none;
    color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  padding: 0;
  
  &:hover {
    color: #F7941D;
  }
  
  svg {
    font-size: 14px;
  }
`;

const Badge = styled.div<BadgeProps>`
  display: inline-flex;
  align-items: center;
  background: ${props => props.color || '#F7941D'};
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  margin-left: 8px;
  
  svg {
    margin-right: 3px;
  }
`;

const ActiveNodeIndicator = styled.div`
  margin-bottom: 15px;
  padding: 10px 15px;
  background: rgba(247, 148, 29, 0.1);
  border-radius: 5px;
  border-left: 3px solid #F7941D;
  font-size: 0.9rem;
  
  .node-name {
    font-weight: 600;
    color: #F7941D;
  }
`;

// Binlik ayırma için sayı girişine özel bir bileşen ekliyorum
const NumberInput = styled.input`
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: #F7941D;
    background: rgba(255, 255, 255, 0.08);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const BinaryTreeContainer = styled.div<BinaryTreeContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: ${props => Math.max(1200, 2400 / (props.zoomLevel || 1))}px;
  min-height: 600px;
  overflow: visible;
  padding: 40px 20px 100px;
  transform-origin: top center;
  transition: transform 0.3s ease;
  transform: scale(${props => props.zoomLevel || 1});
`;

const ZoomControls = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
`;

const ZoomButton = styled.button`
  background: rgba(247, 148, 29, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease;
  font-size: 16px;
  
  &:hover {
    background: rgba(247, 148, 29, 1);
  }
  
  &:disabled {
    background: rgba(100, 100, 100, 0.5);
    cursor: not-allowed;
  }
`;

const TreeLevel = styled.div<TreeLevelProps>`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: ${props => props.isRoot ? '0' : '60px'};
  padding: 0 20px;
  flex-wrap: nowrap;
  width: 100%;
`;

const NodeWrapper = styled.div<NodeWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 220px;
  width: ${props => props.level && props.level > 0 ? '50%' : 'auto'};
`;

const ConnectorVertical = styled.div`
  width: 2px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
`;

const ConnectorHorizontal = styled.div`
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: -20px;
  width: 100%;
    left: 0;
`;

const ConnectorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 20px;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    transform: translateX(-50%);
  }
`;

const NodeCard = styled.div<NodeCardProps>`
  background: ${props => props.side === 'left' ? 'linear-gradient(135deg, #1a598e 0%, #2a6da3 100%)' : 'linear-gradient(135deg, #8e1a4c 0%, #a32a62 100%)'};
  border-radius: 10px;
  padding: 15px;
  width: 220px;
  z-index: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: ${props => props.side === 'left' ? '#4a9cde' : '#de4a7e'};
    border-radius: 3px 3px 0 0;
  }
  
  ${props => props.isActive && `
    border: 2px solid #F7941D;
    box-shadow: 0 0 15px rgba(247, 148, 29, 0.5);
  `}
`;

const NodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const NodeTitle = styled.div`
  font-weight: 600;
  color: #fff;
`;

const NodeSide = styled.div`
  font-size: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 3px 8px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
`;

const NodeInfo = styled.div<NodeInfoProps>`
  margin-top: 5px;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  .info-row {
    display: flex;
    justify-content: space-between;
  }
  
  .info-label {
    color: rgba(255, 255, 255, 0.6);
  }
  
  .info-value {
    font-weight: 600;
  }
  
  .usd-value {
    font-size: 0.75rem;
    color: #4dffa7;
    margin-left: 3px;
  }
  
  .highlight {
    color: ${props => props.side === 'left' ? '#4a9cde' : '#de4a7e'};
  }
`;

const EmptyNode = styled.div`
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 15px;
  width: 180px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease;
  
  &:hover {
    border-color: #F7941D;
    color: #F7941D;
    background: rgba(247, 148, 29, 0.05);
  }
`;

const LeaderBadge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background: #F7941D;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 2;
  transition: all 0.2s ease;
  
  &:hover {
    transform: scale(1.2);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  }
`;

// Kutlama modalı için stil bileşenleri
const CelebrationModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const CelebrationContent = styled.div`
  background: linear-gradient(135deg, #1e2132 0%, #2d3348 100%);
  border: 3px solid #F7941D;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5), 0 0 50px rgba(247, 148, 29, 0.5);
  position: relative;
  animation: celebrationPulse 2s infinite;
  
  @keyframes celebrationPulse {
    0% { transform: scale(1); box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5), 0 0 30px rgba(247, 148, 29, 0.3); }
    50% { transform: scale(1.05); box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5), 0 0 50px rgba(247, 148, 29, 0.6); }
    100% { transform: scale(1); box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5), 0 0 30px rgba(247, 148, 29, 0.3); }
  }
`;

const CelebrationTitle = styled.h2`
  font-size: 2rem;
  color: #F7941D;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    margin: 0 10px;
    animation: rotateStar 2s infinite linear;
  }
  
  @keyframes rotateStar {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const CelebrationText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  margin: 10px 0;
  
  .highlight {
    color: #F7941D;
    font-weight: bold;
  }
`;

const CelebrationButton = styled.button`
  background: #F7941D;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: #e88710;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(247, 148, 29, 0.4);
  }
`;

const CelebrationIcon = styled.div`
  font-size: 5rem;
  color: #F7941D;
  margin: 20px 0;
  animation: bounce 2s infinite;
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background: rgba(20, 20, 30, 0.98);
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 0.8rem;
  max-width: 300px;
  z-index: 10000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5), 0 0 0 1px rgba(255, 255, 255, 0.1);
  pointer-events: none;
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  border-left: 3px solid #F7941D;
  line-height: 1.5;
  font-weight: 400;
  
  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;

export {
  Container, ModalContent, ModalHeader, Title, HeaderControls, LanguageSwitch, LanguageButton,
  CloseButton, BinarySystemContainer, MainContent, MinimizeButton, StatsBar, StatsSection,
  StatsRow, StatItem, FloatingCard, FloatingCardHeader, FloatingCardContent, ToggleButton,
  Sidebar, AddPersonForm, FormTitle, FormGroup, Input, Select, AddButton, SummarySection,
  BonusSection, SummaryTitle, InfoSection, InfoTitle, InfoText, InfoToggleButton, Badge,
  ActiveNodeIndicator, NumberInput, BinaryTreeContainer, ZoomControls, ZoomButton, TreeLevel,
  NodeWrapper, ConnectorVertical, ConnectorHorizontal, ConnectorContainer, NodeCard, NodeHeader,
  NodeTitle, NodeSide, NodeInfo, EmptyNode, LeaderBadge, CelebrationModal, CelebrationContent,
  CelebrationTitle, CelebrationText, CelebrationButton, CelebrationIcon, Tooltip
};