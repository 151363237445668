import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { FaWallet, FaEthereum, FaSpinner } from 'react-icons/fa';
import { SiBinance } from 'react-icons/si';

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 12px;
    border: none;
    background: white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border-bottom: none;
    padding: 20px 30px;
    background: #f8f9fa;
    border-radius: 12px 12px 0 0;
  }

  .modal-body {
    padding: 40px;
  }

  .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
  }
`;

const ModalTitle = styled.h3`
  color: #1a1a1a;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
`;

const PackageInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const PackageGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 30px 0;
  width: 100%;
`;

const PackageCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #eee;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 240px;

  ${props => props.featured && `
    border-color: #F7941D;
    box-shadow: 0 4px 15px rgba(247, 148, 29, 0.1);
    
    &:before {
      content: 'Empfohlen';
      position: absolute;
      top: 12px;
      right: -35px;
      background: #F7941D;
      color: white;
      padding: 4px 40px;
      font-size: 0.7rem;
      transform: rotate(45deg);
    }
  `}

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  }

  ${props => props.selected && `
    border: 2px solid #F7941D;
    box-shadow: 0 4px 15px rgba(247, 148, 29, 0.1);
  `}
`;

const PackageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`;

const PackageTitle = styled.h4`
  color: #1a1a1a;
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
`;

const PackagePrice = styled.div`
  .mft {
    font-size: 1.1rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 3px;
  }

  .usd {
    font-size: 0.9rem;
    color: #666;
  }
`;

const PackageFeatures = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

const PackageFeature = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;

  .label {
    color: #666;
  }

  .value {
    color: #1a1a1a;
    font-weight: 500;
  }
`;

const InfoCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #eee;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  .label {
    color: #666;
    font-size: 0.9rem;
  }

  .value {
    color: #1a1a1a;
    font-weight: 600;
    font-size: 1rem;
  }
`;

const CryptoCard = styled.div`
  background: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;

  svg {
    font-size: 1.5rem;
    color: ${props => props.iconColor || '#F7941D'};
  }

  .info {
    flex: 1;
  }

  .amount {
    font-weight: 600;
    font-size: 1.1rem;
    color: #1a1a1a;
  }

  .label {
    font-size: 0.85rem;
    color: #666;
    margin-top: 2px;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: 30px;
`;

const ActionButton = styled.button`
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &.connect {
    background: #f8f9fa;
    color: #1a1a1a;
    border: 1px solid #ddd;

    &:hover:not(:disabled) {
      background: #eee;
    }
  }

  &.buy {
    background: #F7941D;
    color: white;

    &:hover:not(:disabled) {
      background: #e88710;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SelectButton = styled(ActionButton)`
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  background: ${props => props.selected ? '#e88710' : '#F7941D'};
  color: white;
  font-size: 0.9rem;

  &:hover {
    background: #e88710;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const levelData = {
  1: {
    title: "Premium Level 1 Paket",
    mft: "1.000.000",
    usd: "5.000",
    commission: "20%",
    bonus: "20%",
    maxParticipants: "100",
    featured: true
  },
  2: {
    title: "Professional Level 2 Paket",
    mft: "600.000",
    usd: "3.000",
    commission: "10%",
    bonus: "10%",
    maxParticipants: "200"
  },
  3: {
    title: "Standard Level 3 Paket",
    mft: "200.000",
    usd: "1.000",
    commission: "5%",
    bonus: "5%",
    maxParticipants: "300"
  },
  4: {
    title: "Starter Level 4 Paket",
    mft: "200.000",
    usd: "1.000",
    commission: "5%",
    bonus: "5%",
    maxParticipants: "300"
  }
};

const NetworkMarketingModal = ({ show, onHide, selectedLevel: initialLevel }) => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState({
    binancecoin: { usd: 300 },
    ethereum: { usd: 2000 }
  });
  const packageData = initialLevel ? levelData[initialLevel] : null;

  useEffect(() => {
    const fetchPrices = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,ethereum&vs_currencies=usd', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        });
        
        if (!response.ok) {
          throw new Error('API yanıt vermedi');
        }
        
        const data = await response.json();
        console.log("API Response:", data); // Debug için

        // API'den gelen veriyi kontrol et
        if (data && data.binancecoin && data.ethereum) {
          console.log("Setting prices:", data); // Debug için
          setPrices({
            binancecoin: { usd: data.binancecoin.usd },
            ethereum: { usd: data.ethereum.usd }
          });
        } else {
          throw new Error('Invalid API response format');
        }
      } catch (error) {
        console.error('Fiyat çekme hatası:', error);
        // Fallback değerleri kullan
        setPrices({
          binancecoin: { usd: 300 },
          ethereum: { usd: 2000 }
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      fetchPrices();
    }
  }, [show]);

  const calculateCryptoAmount = (usdAmount) => {
    console.log("Calculating for USD:", usdAmount); // Debug için
    console.log("Current prices:", prices); // Debug için

    // USD değerini temizle (virgülü noktaya çevir)
    const cleanUsdAmount = usdAmount.replace(/\./g, '').replace(',', '.');
    const usdValue = parseFloat(cleanUsdAmount);

    console.log("Cleaned USD value:", usdValue); // Debug için

    if (isNaN(usdValue)) {
      console.log("Invalid USD value"); // Debug için
      return { bnb: '0', eth: '0' };
    }

    const bnbPrice = prices.binancecoin?.usd || 300;
    const ethPrice = prices.ethereum?.usd || 2000;

    console.log("BNB Price:", bnbPrice); // Debug için
    console.log("ETH Price:", ethPrice); // Debug için

    const bnbAmount = (usdValue / bnbPrice).toFixed(2);
    const ethAmount = (usdValue / ethPrice).toFixed(2);

    console.log("Calculated amounts:", { bnb: bnbAmount, eth: ethAmount }); // Debug için

    return {
      bnb: bnbAmount,
      eth: ethAmount
    };
  };

  const handleClose = () => {
    setIsWalletConnected(false);
    onHide();
  };

  const handleConnectWallet = () => {
    setIsWalletConnected(true);
  };

  const handleBuyPackage = () => {
    // Satın alma işlemleri
  };

  const renderPackageSelection = () => (
    <>
      <ModalTitle>Wählen Sie Ihr Paket</ModalTitle>
      <PackageGrid>
        {Object.entries(levelData).map(([level, data]) => (
          <PackageCard 
            key={level}
            featured={data.featured}
          >
            <PackageHeader>
              <PackageTitle>{data.title}</PackageTitle>
              <PackagePrice>
                <div className="mft">{data.mft} MFT</div>
                <div className="usd">{data.usd} USD</div>
              </PackagePrice>
            </PackageHeader>
            <PackageFeatures>
              <PackageFeature>
                <span className="label">Provision</span>
                <span className="value">{data.commission}</span>
              </PackageFeature>
              <PackageFeature>
                <span className="label">MFT Bonus</span>
                <span className="value">{data.bonus}</span>
              </PackageFeature>
              <PackageFeature>
                <span className="label">Maximale Teilnehmer</span>
                <span className="value">{data.maxParticipants}</span>
              </PackageFeature>
            </PackageFeatures>
            <SelectButton onClick={() => onHide()}>
              Paket Wählen
            </SelectButton>
          </PackageCard>
        ))}
      </PackageGrid>
    </>
  );

  const renderPackageDetails = () => {
    if (!packageData) return null;
    
    const cryptoAmounts = calculateCryptoAmount(packageData.usd);
    
    return (
      <>
        <ModalTitle>{packageData.title}</ModalTitle>
        <PackageInfo>
          <InfoCard>
            <InfoRow>
              <span className="label">MFT Menge</span>
              <span className="value">{packageData.mft} MFT</span>
            </InfoRow>
            <InfoRow>
              <span className="label">Provision</span>
              <span className="value">{packageData.commission}</span>
            </InfoRow>
            <InfoRow>
              <span className="label">MFT Bonus</span>
              <span className="value">{packageData.bonus}</span>
            </InfoRow>
            <InfoRow>
              <span className="label">Maximale Teilnehmer</span>
              <span className="value">{packageData.maxParticipants}</span>
            </InfoRow>
          </InfoCard>

          <InfoCard>
            <CryptoCard>
              <FaWallet />
              <div className="info">
                <div className="amount">{packageData.usd} USD</div>
                <div className="label">Gesamtwert</div>
              </div>
            </CryptoCard>

            <CryptoCard iconColor="#F3BA2F">
              <SiBinance />
              <div className="info">
                <div className="amount">
                  {isLoading ? <LoadingSpinner /> : `${cryptoAmounts.bnb} BNB`}
                </div>
                <div className="label">BNB Gegenwert</div>
              </div>
            </CryptoCard>

            <CryptoCard iconColor="#627EEA">
              <FaEthereum />
              <div className="info">
                <div className="amount">
                  {isLoading ? <LoadingSpinner /> : `${cryptoAmounts.eth} ETH`}
                </div>
                <div className="label">ETH Gegenwert</div>
              </div>
            </CryptoCard>
          </InfoCard>
        </PackageInfo>

        <ButtonGroup>
          <ActionButton 
            className="connect"
            onClick={handleConnectWallet}
            disabled={isWalletConnected}
          >
            {isWalletConnected ? 'Wallet Verbunden' : 'Wallet Verbinden'}
          </ActionButton>
          <ActionButton 
            className="buy"
            onClick={handleBuyPackage}
            disabled={!isWalletConnected}
          >
            Jetzt Kaufen
          </ActionButton>
        </ButtonGroup>
      </>
    );
  };

  return (
    <StyledModal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton />
      <Modal.Body>
        {initialLevel ? renderPackageDetails() : renderPackageSelection()}
      </Modal.Body>
    </StyledModal>
  );
};

export default NetworkMarketingModal;