import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {  
  FaInfoCircle, FaQuestionCircle,  FaGlobe, FaChartLine, 
  FaClipboard, FaSignOutAlt, FaUserCircle, 
  FaCheckCircle, FaPlus, FaSearchPlus, FaSearchMinus
} from 'react-icons/fa';
import logoImage from '../assets/img/logo/logo.png';

import {
  Container, ModalContent, ModalHeader, Title, HeaderControls, LanguageSwitch, LanguageButton,
   MainContent, InfoSection, InfoTitle, InfoText, 
  BinaryTreeContainer, ZoomControls, ZoomButton, TreeLevel,
  NodeWrapper, ConnectorVertical, ConnectorHorizontal, ConnectorContainer, NodeCard, NodeHeader,
  NodeTitle, NodeSide, NodeInfo, EmptyNode
} from './BinaryNetworkSystemV2Style.tsx';

// Button bileşenini kendi oluşturalım
const Button = styled.button`
  background: var(--primary-gradient, linear-gradient(135deg, #6739ff, #9a6bff));
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-family: var(--font-family-heading, 'Poppins', sans-serif);
  font-weight: 600;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  
  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(103, 57, 255, 0.3);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

// Eksik stil bileşenleri

// Cüzdan adresini kısaltan yardımcı fonksiyon
const shortenAddress = (address) => {
  if (!address) return 'Bulunamadı';
  if (address.length < 15) return address;
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
};

// Logo bileşeni
const LogoImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
`;


const InfoItem = styled.div`
  margin-bottom: 8px;
  
  .info-label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 3px;
    display: block;
  }
  
  .info-value {
    font-size: 16px;
    font-weight: 600;
    color: #F7941D;
    
    .usd-value {
      font-size: 12px;
      opacity: 0.8;
      color: rgba(255, 255, 255, 0.7);
      font-weight: normal;
      margin-left: 5px;
    }
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.last ? '0' : '8px'};
`;


// Popup stil bileşenleri
const SidePopup = styled.div`
  position: fixed;
  top: 120px;
  width: 320px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 100;
  transition: all 0.3s ease;
  
  ${props => props.side === 'left' ? `
    left: 20px;
  ` : `
    right: 20px;
  `}
  
  @media (max-width: 1200px) {
    width: 280px;
    ${props => props.side === 'left' ? `
      left: 10px;
    ` : `
      right: 10px;
    `}
  }
  
  @media (max-width: 992px) {
    position: static;
    width: calc(100% - 20px);
    margin: 10px;
  }
`;

const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  h3 {
    margin: 0;
    font-size: 16px;
    color: #F7941D;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReferralBox = styled.div`
  margin-top: 10px;
  background: rgba(247, 148, 29, 0.1);
  border-radius: 6px;
  padding: 10px;
  border: 1px solid rgba(247, 148, 29, 0.2);
`;

const BinaryNetworkSystemV2 = () => {
  const [language, setLanguage] = useState('de');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [referralCopied, setReferralCopied] = useState(false);
  const navigate = useNavigate();

  // Ağaç yapısı için state'ler
  const [rootNode, setRootNode] = useState(null);
  const [nodeMap, setNodeMap] = useState({});
  const [activeNode, setActiveNode] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1.0);

  // MFT Token fiyatı
  const [tokenPrice, setTokenPrice] = useState(0.005); // $0.005 USD
  
  // Kullanıcı bilgilerini yükle
  useEffect(() => {
    const checkUserLogin = () => {
      try {
        const storedUser = localStorage.getItem('binaryNetworkUser');
        if (!storedUser) {
          console.log("Kullanıcı girişi bulunamadı");
          navigate('/binary-login');
          return;
        }
        
        const userInfo = JSON.parse(storedUser);
        console.log("Kullanıcı bilgileri yüklendi:", userInfo);
        setUserData(userInfo);
        
        // Kullanıcıyı root node olarak ağaca ekle
        const userNode = {
          id: userInfo.id,
          name: userInfo.email.split('@')[0], // Kullanıcı adı olarak email'in ilk kısmını kullan
          email: userInfo.email,
          volume: Number(userInfo.purchaseAmount) || 0,
          bonusAmount: Number(userInfo.bonusAmount) || 0,
          packageType: userInfo.packageType,
          referralCode: userInfo.referralCode,
          purchaseDate: userInfo.purchaseDate,
          walletAddress: userInfo.walletAddress,
          leftChild: null,
          rightChild: null,
          parent: null
        };
        
        // Node haritasını oluştur
        const newNodeMap = { [userInfo.id]: userNode };
        setNodeMap(newNodeMap);
        setRootNode(userNode);
        setActiveNode(userNode);
        
      } catch (error) {
        console.error("Kullanıcı bilgileri yüklenirken hata:", error);
        navigate('/binary-login');
      } finally {
        setLoading(false);
      }
    };
    
    checkUserLogin();
  }, [navigate]);
  
  
  // Referans kodunu kopyala
  const copyReferralCode = () => {
    if (userData?.referralCode) {
      navigator.clipboard.writeText(userData.referralCode);
      setReferralCopied(true);
      
      // 3 saniye sonra durumu sıfırla
      setTimeout(() => {
        setReferralCopied(false);
      }, 3000);
    }
  };
  
  // Çıkış yap
  const handleLogout = () => {
    localStorage.removeItem('binaryNetworkUser');
    navigate('/binary-network-login');
  };
  
  // Sayı formatlamak için geliştirmiş fonksiyon
  const formatNumber = (value, decimals = 0) => {
    if (value === undefined || value === null) return '0';
    
    // Ondalık ayarları
    const options = {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    };
    
    return Number(value).toLocaleString('tr-TR', options);
  };
  
  // Paket tipini metne dönüştür
  const getPackageTypeName = (packageType) => {
    if (!packageType && packageType !== 0) return 'Bilinmiyor';
    
    switch (Number(packageType)) {
      case 1: return 'Premium Paket';
      case 2: return 'Standart Paket';
      case 3: return 'Özel Miktar';
      default: return 'Bilinmiyor';
    }
  };
  
  // Metinleri dil seçimine göre getiren fonksiyon
  const getText = (key) => {
    const texts = {
      // Header ve genel metinler
      title: {
        de: 'Binary Network System',
        tr: 'Binary Network Sistemi'
      },
      german: {
        de: 'Deutsch',
        tr: 'Almanca'
      },
      turkish: {
        de: 'Türkisch',
        tr: 'Türkçe'
      },
      
      // Kullanıcı profil bilgileri
      profileTitle: {
        de: 'Profilinformationen',
        tr: 'Profil Bilgileri'
      },
      email: {
        de: 'E-Mail:',
        tr: 'Email:'
      },
      walletAddress: {
        de: 'Wallet-Adresse:',
        tr: 'Cüzdan Adresi:'
      },
      packageType: {
        de: 'Pakettyp:',
        tr: 'Paket Türü:'
      },
      purchaseAmount: {
        de: 'Gekaufter Betrag:',
        tr: 'Satın Alınan Miktar:'
      },
      bonusAmount: {
        de: 'Bonusbetrag:',
        tr: 'Bonus Miktarı:'
      },
      purchaseDate: {
        de: 'Kaufdatum:',
        tr: 'Satın Alma Tarihi:'
      },
      referralCode: {
        de: 'Empfehlungscode:',
        tr: 'Davet Kodu:'
      },
      copy: {
        de: 'Kopieren',
        tr: 'Kopyala'
      },
      copied: {
        de: 'Kopiert!',
        tr: 'Kopyalandı!'
      },
      
      // İstatistik başlıkları
      networkOverview: {
        de: 'Netzwerk Übersicht',
        tr: 'Network Genel Bakış'
      },
      earningsOverview: {
        de: 'Ertragsübersicht',
        tr: 'Kazanç Genel Bakış'
      },
      
      // İstatistik öğeleri
      purchasedTokens: {
        de: 'Gekaufte Tokens:',
        tr: 'Satın Alınan Tokenler:'
      },
      bonusTokens: {
        de: 'Bonus-Tokens:',
        tr: 'Bonus Tokenler:'
      },
      totalTokens: {
        de: 'Gesamttokens:',
        tr: 'Toplam Tokenler:'
      },
      usdValue: {
        de: 'USD-Wert:',
        tr: 'USD Değeri:'
      },
      
      // Bilgi bölümü
      howItWorks: {
        de: 'Wie funktioniert das Binary Network System?',
        tr: 'Binary Network Sistemi Nasıl Çalışır?'
      },
      howItWorksInfo: {
        de: 'Das Binary Network ist ein Netzwerk-Marketing-System, das auf einer Zwei-Bein-Struktur basiert. Jedes Mitglied kann zwei Personen im linken und rechten Bein sponsern. Die Einnahmen werden auf Basis des schwächeren Beins berechnet. Je mehr Mitglieder Sie haben, desto höhere Boni und Provisionen erhalten Sie.',
        tr: 'Binary Network, iki kol yapısına dayalı bir ağ pazarlama sistemidir. Her üye, sol ve sağ kolda iki kişi sponsorluğunda bulunabilir. Kazançlar, zayıf kol hacmine göre hesaplanır. Üye sayınız arttıkça, daha yüksek bonuslar ve komisyonlar elde edersiniz.'
      },
      
      // Çıkış
      logout: {
        de: 'Abmelden',
        tr: 'Çıkış Yap'
      }
    };
    
    if (!texts[key]) {
      console.warn(`Missing translation key: ${key}`);
      return key;
    }
    
    return texts[key][language] || key;
  };
  
  // Zoom fonksiyonları
  const zoomIn = () => {
    if (zoomLevel < 2.0) {
      setZoomLevel(prev => Math.min(prev + 0.2, 2.0));
    }
  };
  
  const zoomOut = () => {
    if (zoomLevel > 0.6) {
      setZoomLevel(prev => Math.max(prev - 0.2, 0.6));
    }
  };
  
  const resetZoom = () => {
    setZoomLevel(1.0);
  };
  
  // Toplam hacim hesaplama (alt düğümler dahil)
  const calculateTotalVolume = (node) => {
    if (!node) return 0;
    
    // Eğer node bir ID ise, nodeMap'ten al
    if (typeof node === 'string') {
      node = nodeMap[node];
      if (!node) return 0;
    }
    
    let leftVolume = 0;
    let rightVolume = 0;
    
    if (node.leftChild) {
      const leftNode = nodeMap[node.leftChild];
      if (leftNode) {
        leftVolume = calculateTotalVolume(leftNode);
      }
    }
    
    if (node.rightChild) {
      const rightNode = nodeMap[node.rightChild];
      if (rightNode) {
        rightVolume = calculateTotalVolume(rightNode);
      }
    }
    
    return node.volume + leftVolume + rightVolume;
  };
  
  // ID'ye göre toplam hacim hesaplama
  const getTotalVolumeForId = (nodeId) => {
    if (!nodeId) return 0;
    const node = nodeMap[nodeId];
    if (!node) return 0;
    
    return calculateTotalVolume(node);
  };
  
  // Ağaç görünümünü oluştur
  const renderBinaryTree = (nodeId, level = 0, side = 'root') => {
    if (!nodeId || !nodeMap[nodeId]) return null;
    
    const node = nodeMap[nodeId];
    const leftChildId = node.leftChild;
    const rightChildId = node.rightChild;
    
    const hasLeftChild = leftChildId && nodeMap[leftChildId];
    const hasRightChild = rightChildId && nodeMap[rightChildId];
    
    // Kişinin hacim bilgilerini hesapla
    const leftVolume = getTotalVolumeForId(leftChildId);
    const rightVolume = getTotalVolumeForId(rightChildId);
    const totalVolume = node.volume + leftVolume + rightVolume;
    
    // Düğüm kartı
    const nodeCard = (
      <NodeCard 
        side={side === 'root' ? 'left' : side} 
        isActive={activeNode && node.id === activeNode.id}
        onClick={() => setActiveNode(node)}
      >
        <NodeHeader>
          <NodeTitle>{node.name}</NodeTitle>
          <NodeSide>{side === 'root' ? 'Kök' : side === 'left' ? 'Sol Kol' : 'Sağ Kol'}</NodeSide>
        </NodeHeader>
        
        <NodeInfo side={side === 'root' ? 'left' : side}>
          <div className="info-row">
            <span className="info-label">Hacim:</span>
            <span className="info-value">
              {formatNumber(node.volume || 0)} MFT
              <span className="usd-value">
                (${formatNumber((node.volume || 0) * tokenPrice, 2)})
              </span>
            </span>
          </div>
          <div className="info-row">
            <span className="info-label">Sol Kol:</span>
            <span className="info-value">
              {formatNumber(leftVolume)} MFT
            </span>
          </div>
          <div className="info-row">
            <span className="info-label">Sağ Kol:</span>
            <span className="info-value">
              {formatNumber(rightVolume)} MFT
            </span>
          </div>
          
          <div className="info-row" style={{ borderTop: '1px solid rgba(255,255,255,0.2)', paddingTop: '5px', marginTop: '5px' }}>
            <span className="info-label">Toplam:</span>
            <span className="info-value highlight">
              {formatNumber(totalVolume)} MFT
              <span className="usd-value">
                (${formatNumber(totalVolume * tokenPrice, 2)})
              </span>
            </span>
          </div>
        </NodeInfo>
      </NodeCard>
    );
    
    // Boş düğüm oluşturma
    const createEmptyNode = (side) => (
      <EmptyNode onClick={() => {
        setActiveNode(node);
      }}>
        <FaPlus /> {node.name}'ın {side === 'left' ? 'Sol' : 'Sağ'} Kolu Ekle
      </EmptyNode>
    );
    
    // Alt düğümleri oluştur
    const renderChildren = () => {
      // Her seviye için genişlik hesaplama
      const childSpacing = Math.max(10, 120 / Math.pow(2, level));
      const childStyle = {
        width: '50%',
        padding: `0 ${childSpacing}px`
      };

      if (!hasLeftChild && !hasRightChild) {
        return (
          <TreeLevel level={level+1}>
            <NodeWrapper level={level+1} style={childStyle}>
              <ConnectorHorizontal />
              {createEmptyNode('left')}
            </NodeWrapper>
            <NodeWrapper level={level+1} style={childStyle}>
              <ConnectorHorizontal />
              {createEmptyNode('right')}
            </NodeWrapper>
          </TreeLevel>
        );
      }
      
      return (
        <TreeLevel level={level+1}>
          <NodeWrapper level={level+1} style={childStyle}>
            <ConnectorHorizontal />
            {hasLeftChild ? 
              renderBinaryTree(leftChildId, level + 1, 'left') : 
              createEmptyNode('left')
            }
          </NodeWrapper>
          <NodeWrapper level={level+1} style={childStyle}>
            <ConnectorHorizontal />
            {hasRightChild ? 
              renderBinaryTree(rightChildId, level + 1, 'right') : 
              createEmptyNode('right')
            }
          </NodeWrapper>
        </TreeLevel>
      );
    };
    
    // Her seviye için stil hesaplama
    const getNodeStyle = () => {
      if (level === 0) return {};
      
      // Her seviye için genişlik hesaplama
      const spacing = Math.max(20, 240 / Math.pow(2, level - 1));
      return {
        margin: `0 ${spacing}px`
      };
    };
    
    return (
      <div key={node.id} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TreeLevel isRoot={level === 0} level={level}>
          <NodeWrapper level={level} style={getNodeStyle()}>
            {level > 0 && <ConnectorVertical />}
            {nodeCard}
          </NodeWrapper>
        </TreeLevel>
        
        {(hasLeftChild || hasRightChild || level < 2) && (
          <>
            {level < 2 && <ConnectorContainer />}
            {renderChildren()}
          </>
        )}
      </div>
    );
  };
  
  // Kullanıcı yükleniyor ise
  if (loading) {
    return (
      <Container>
        <ModalContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ 
              width: '40px', 
              height: '40px', 
              border: '4px solid rgba(255, 255, 255, 0.1)', 
              borderRadius: '50%', 
              borderTopColor: '#6739ff', 
              animation: 'spin 1s infinite linear',
              margin: '0 auto 20px'
            }}></div>
            <p>Yükleniyor...</p>
          </div>
        </ModalContent>
      </Container>
    );
  }
  
  // Kullanıcı verisi yoksa
  if (!userData) {
    return (
      <Container>
        <ModalContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <div style={{ textAlign: 'center' }}>
            <FaInfoCircle style={{ fontSize: '48px', color: '#ff4f4f', marginBottom: '20px' }} />
            <p>Oturum bilgileriniz bulunamadı. Lütfen tekrar giriş yapın.</p>
            <Button 
              onClick={() => navigate('/binary-login')}
              style={{ marginTop: '20px', padding: '10px 20px' }}
            >
              Giriş Sayfasına Dön
            </Button>
          </div>
        </ModalContent>
      </Container>
    );
  }
  
  // Toplam token miktarı
  const totalTokens = (Number(userData.purchaseAmount) || 0) + (Number(userData.bonusAmount) || 0);
  // Token USD değeri
  const tokensUsdValue = totalTokens * tokenPrice;
  
  // Tarih formatını düzenle
  const formatDate = (dateString) => {
    if (!dateString) return 'Bilinmiyor';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('tr-TR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      return 'Geçersiz Tarih';
    }
  };

  return (
    <Container>
      <ModalContent>
        <ModalHeader style={{ padding: '10px 15px' }}>
          <Title>
            <LogoImage src={logoImage} alt="MFT Logo" />
            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 1.2 }}>
              {getText('title')}
              <small style={{ fontSize: '14px', opacity: 0.8 }}>{userData?.email}</small>
            </div>
          </Title>
          <HeaderControls>
            <LanguageSwitch>
              <LanguageButton 
                active={language === 'de'} 
                onClick={() => setLanguage('de')}
              >
                <FaGlobe /> Deutsch
              </LanguageButton>
              <LanguageButton 
                active={language === 'tr'} 
                onClick={() => setLanguage('tr')}
              >
                <FaGlobe /> Türkçe
              </LanguageButton>
            </LanguageSwitch>
            <Button 
              onClick={handleLogout}
              style={{ 
                marginLeft: '10px',
                background: 'rgba(255, 79, 79, 0.2)',
                borderColor: 'rgba(255, 79, 79, 0.3)',
                color: '#ff4f4f'
              }}
            >
              <FaSignOutAlt /> {getText('logout')}
            </Button>
          </HeaderControls>
        </ModalHeader>
        
        {/* Ana içerik alanı */}
        <MainContent style={{ position: 'relative', flex: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Ağaç Görünümü */}
          <BinaryTreeContainer 
            style={{ 
              flex: 1, 
              minHeight: '70vh', 
              overflowY: 'auto', 
              overflowX: 'auto', 
              padding: '30px 10px',
              background: 'linear-gradient(135deg, rgba(15, 17, 48, 0.8) 0%, rgba(30, 33, 50, 0.8) 100%)',
              borderRadius: '10px',
              border: '1px solid rgba(255, 255, 255, 0.05)',
              boxShadow: 'inset 0 0 30px rgba(0, 0, 0, 0.2)',
              margin: '0'
            }}
          >
            <div style={{ 
              transform: `scale(${zoomLevel})`, 
              transformOrigin: 'center top',
              transition: 'transform 0.3s ease',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              paddingBottom: '50px'
            }}>
              {rootNode && renderBinaryTree(rootNode.id)}
            </div>
          </BinaryTreeContainer>
          
          {/* Zoom Kontrolleri */}
          <ZoomControls>
            <ZoomButton onClick={zoomIn} disabled={zoomLevel >= 2.0} title="Büyüt">
              <FaSearchPlus />
            </ZoomButton>
            <ZoomButton onClick={resetZoom} title="Normal boyut">
              {Math.round(zoomLevel * 100)}%
            </ZoomButton>
            <ZoomButton onClick={zoomOut} disabled={zoomLevel <= 0.6} title="Küçült">
              <FaSearchMinus />
            </ZoomButton>
          </ZoomControls>
          
          {/* Nasıl Çalışır Bilgisi */}
          <InfoSection style={{ bottom: '20px', left: '20px', transform: 'none', maxWidth: '400px' }}>
            <InfoTitle>
              <span className="title-text">
                <FaQuestionCircle /> {getText('howItWorks')}
              </span>
            </InfoTitle>
            <InfoText>
              {getText('howItWorksInfo')}
            </InfoText>
          </InfoSection>
          
          {/* Token Bilgileri Pop-up - Sol Taraf */}
          <SidePopup side="left">
            <PopupHeader>
              <h3><FaChartLine /> Token Bilgileri</h3>
            </PopupHeader>
            <PopupContent>
              <InfoItem>
                <div className="info-label">Satın Alınan Tokenler:</div>
                <div className="info-value">
                  {formatNumber(userData?.purchaseAmount || 0)} MFT
                  <span className="usd-value">(${formatNumber((userData?.purchaseAmount || 0) * tokenPrice, 2)})</span>
                </div>
              </InfoItem>
              
              <InfoItem>
                <div className="info-label">Bonus Tokenler:</div>
                <div className="info-value">
                  {formatNumber(userData?.bonusAmount || 0)} MFT
                  <span className="usd-value">(${formatNumber((userData?.bonusAmount || 0) * tokenPrice, 2)})</span>
                </div>
              </InfoItem>
              
              <InfoItem>
                <div className="info-label">Toplam Tokenler:</div>
                <div className="info-value">
                  {formatNumber(totalTokens)} MFT
                </div>
              </InfoItem>
              
              <InfoItem>
                <div className="info-label">USD Değeri:</div>
                <div className="info-value">
                  ${formatNumber(tokensUsdValue, 2)}
                </div>
              </InfoItem>
            </PopupContent>
          </SidePopup>
          
          {/* Profil Bilgileri Pop-up - Sağ Taraf */}
          <SidePopup side="right">
            <PopupHeader>
              <h3><FaUserCircle /> {getText('profileTitle')}</h3>
            </PopupHeader>
            <PopupContent>
              <InfoItem>
                <div className="info-label">{getText('walletAddress')}</div>
                <div className="info-value" style={{ fontSize: '14px', fontFamily: 'monospace' }}>
                  {shortenAddress(userData?.walletAddress)}
                </div>
              </InfoItem>
              
              <InfoItem>
                <div className="info-label">{getText('packageType')}</div>
                <div className="info-value" style={{ fontSize: '14px' }}>
                  {getPackageTypeName(userData?.packageType)}
                </div>
              </InfoItem>
              
              <InfoItem>
                <div className="info-label">{getText('purchaseDate')}</div>
                <div className="info-value" style={{ fontSize: '14px' }}>
                  {formatDate(userData?.purchaseDate)}
                </div>
              </InfoItem>
              
              <ReferralBox>
                <InfoRow>
                  <div>
                    <div className="info-label" style={{ marginBottom: '5px' }}>{getText('referralCode')}</div>
                    <div style={{ 
                      fontSize: '18px', 
                      fontWeight: 'bold',
                      color: '#F7941D',
                      letterSpacing: '1px'
                    }}>
                      {userData?.referralCode || 'Bulunamadı'}
                    </div>
                  </div>
                  <Button 
                    onClick={copyReferralCode}
                    style={{
                      padding: '6px 12px',
                      background: referralCopied ? 'rgba(40, 167, 69, 0.2)' : 'rgba(247, 148, 29, 0.2)',
                      borderColor: referralCopied ? 'rgba(40, 167, 69, 0.3)' : 'rgba(247, 148, 29, 0.3)',
                      color: referralCopied ? '#28a745' : '#F7941D',
                      alignSelf: 'center'
                    }}
                  >
                    {referralCopied ? <FaCheckCircle /> : <FaClipboard />} {getText(referralCopied ? 'copied' : 'copy')}
                  </Button>
                </InfoRow>
              </ReferralBox>
            </PopupContent>
          </SidePopup>
        </MainContent>
      </ModalContent>
    </Container>
  );
};

export default BinaryNetworkSystemV2;