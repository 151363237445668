import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { InvitationProvider } from './context/InvitationContext';
import Parse from 'parse';
import HomeThree from "./pages/Home/HomeThree";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import TradingPlatform from "./pages/Trading/MobileTradingPlatform";
import BinaryLogin from "./components/BinaryLogin";
import BinaryNetworkSystemV2 from "./components/BinaryNetworkSystemV2";
import BinaryNetworkLogin from "./components/BinaryNetworkLogin";
import BinaryNetworkSystemV2Simulator from "./components/BinaryNetworkSystemV2Simulator";
import PresentationPage from "./pages/Presentation";

Parse.serverURL = "https://parseapi.back4app.com/"
Parse.initialize('4KRQWmAja0cl9H0lKWJEsNqBVUtMqQKz61jPlY65', 'w0mdP7fUy36pSnrGBx3CXHGsLHvFi8NfksOw6Klm');

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  return (
    <InvitationProvider>
      <Routes>
        <Route path="home-two" element={<Home />} />
        <Route path="home-three" element={<HomeThree />} />
        <Route path="/" element={<HomeTwo />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetailsPage />} />
        <Route path="ntwrk/:inviteCode" element={<HomeTwo />} />
        <Route path="trading" element={<TradingPlatform />} />
        <Route path="binary-login" element={<BinaryLogin />} />
        <Route path="binary-network" element={<BinaryNetworkSystemV2 />} />
        <Route path="binary-network-login" element={<BinaryNetworkLogin />} />
        <Route path="binary-network-simulator" element={<BinaryNetworkSystemV2Simulator />} />
        <Route path="presentation" element={<PresentationPage />} />
      </Routes>
    </InvitationProvider>
  );
  // return <ComingSoon />;
}

export default App;
