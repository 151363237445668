import React, { useState, useEffect } from 'react';
import { FaPlus, FaUserPlus, FaChartLine, FaCoins, FaCalculator, FaBitcoin, FaGift, FaTrophy, FaDollarSign, FaSearchPlus, FaSearchMinus, FaInfoCircle, FaCompressAlt, FaExpandAlt, FaQuestionCircle, FaCrown, FaStar, FaGlobe, FaLanguage, FaTimes, FaSave } from 'react-icons/fa';
import Confetti from 'react-confetti';
import {
  Container, ModalContent, ModalHeader, Title, HeaderControls, LanguageSwitch, LanguageButton,
  CloseButton, BinarySystemContainer, MainContent, MinimizeButton, StatsBar, StatsSection,
  StatsRow, StatItem, FloatingCard, FloatingCardHeader, FloatingCardContent, ToggleButton,
  Sidebar, AddPersonForm, FormTitle, FormGroup, Input, Select, AddButton, SummarySection,
  BonusSection, SummaryTitle, InfoSection, InfoTitle, InfoText, InfoToggleButton, Badge,
  ActiveNodeIndicator, NumberInput, BinaryTreeContainer, ZoomControls, ZoomButton, TreeLevel,
  NodeWrapper, ConnectorVertical, ConnectorHorizontal, ConnectorContainer, NodeCard, NodeHeader,
  NodeTitle, NodeSide, NodeInfo, EmptyNode, LeaderBadge, CelebrationModal, CelebrationContent,
  CelebrationTitle, CelebrationText, CelebrationButton, CelebrationIcon, Tooltip,
  CardHeader, CardTitle, CardContent, TabContainer, TabList, Tab, TabContent,
  InputGroup, Label, Button, CardFooter
} from './BinaryNetworkSystemV2Style.tsx';

// Lider olup olmadığını kontrol eden fonksiyon
const isLeader = (nodeId, nodeMap) => {
  if (!nodeId) return false;
  const node = nodeMap[nodeId];
  if (!node) return false;
  
  // Bir kişinin en az 5 alt üyesi varsa lider olarak kabul ediyoruz
  const countMembers = (id) => {
    if (!id) return 0;
    const currentNode = nodeMap[id];
    if (!currentNode) return 0;
    
    return 1 + 
      (currentNode.leftChild ? countMembers(currentNode.leftChild) : 0) + 
      (currentNode.rightChild ? countMembers(currentNode.rightChild) : 0);
  };
  
  // Kendisi hariç alt üye sayısı
  const memberCount = 
    (node.leftChild ? countMembers(node.leftChild) : 0) + 
    (node.rightChild ? countMembers(node.rightChild) : 0);
  
  return memberCount >= 5;
};

// Ana Bileşen
const BinaryNetworkSystemV2Simulator = () => {
  const [activeNode, setActiveNode] = useState(null);
  const [rootNode, setRootNode] = useState({
    id: 'root',
    name: 'Hauptkonto',
    volume: 0,
    leftChild: null,
    rightChild: null,
    parent: null
  });
  const [formData, setFormData] = useState({
    name: '',
    volume: '',
    side: 'left',
    parentId: ''
  });
  
  const [nodeMap, setNodeMap] = useState({ 
    root: {
      id: 'root',
      name: 'Hauptkonto',
      volume: 0,
      leftChild: null,
      rightChild: null,
      parent: null
    } 
  });
  
  // Komisyon oranları için state
  const [commissionRates, setCommissionRates] = useState({
    // Firma komisyon dağıtım oranı
    firmDistributionRate: 49, // %49
    
    // Cycle Komisyonu
    cycleCommissionRate: 25, // %25
    cycleThreshold: 1000, // $1000 
    cycleAmount: 500, // $500 ödeme
    
    // Direkt Komisyon oranları (4 seviye)
    directCommissionRates: {
      level1: 7, // %7
      level2: 4, // %4
      level3: 3, // %3
      level4: 1  // %1
    },
    
    // Takım Komisyonu
    teamCommissionRate: 6, // %6
    teamCommissionThreshold: 20, // 20 kişi eşiği
    
    // Eşleşme Bonusu
    matchingBonusRate: 3 // %3
  });
  
  // Eski bonus oranları (geriye uyumluluk için tutuyoruz)
  const [bonusRates, setBonusRates] = useState({
    earningsRate: 10, // %10 kazanç oranı
    balanceBonusRate: 2, // %2 denge bonusu
    volumeBonusRate: 1, // %1 hacim bonusu
    leadershipBonusAmount: 100, // Her üye için 100 MFT
    volumeBonusThreshold: 10000, // 10.000 MFT eşiği
    balanceBonusRatio: 80, // %80 denge oranı eşiği
    leadershipBonusThreshold: 5 // 5 üye eşiği
  });
  
  // MFT Token fiyatı
  const [tokenPrice, setTokenPrice] = useState(0.005); // $0.005 USD
  
  // Aktif sekme (üye ekleme veya komisyon ayarları)
  const [activeTab, setActiveTab] = useState('addMember'); // 'addMember' veya 'commissionSettings'
  
  // Zoom seviyesi state'i ekleniyor
  const [zoomLevel, setZoomLevel] = useState(1.0);
  
  // Floating card açık/kapalı durumu
  const [isFloatingCardOpen, setIsFloatingCardOpen] = useState(true);
  
  // Ağaç görünümünün minimize edilip edilmediği
  const [isTreeMinimized, setIsTreeMinimized] = useState(false);
  
  // Bilgi bölümünün minimize edilip edilmediği
  const [isInfoMinimized, setIsInfoMinimized] = useState(false);
  
  // Kutlama modalı için state
  const [showCelebration, setShowCelebration] = useState(false);
  const [celebrationNode, setCelebrationNode] = useState(null);
  const [runConfetti, setRunConfetti] = useState(false);
  
  // Tooltip için state
  const [tooltip, setTooltip] = useState({
    show: false,
    text: '',
    x: 0,
    y: 0
  });
  
  // Dil seçimi için state
  const [language, setLanguage] = useState('de'); // 'de' for German, 'tr' for Turkish
  
  // Geçici komisyon oranları için state
  const [tempCommissionRates, setTempCommissionRates] = useState(commissionRates);
  const [tempTokenPrice, setTempTokenPrice] = useState(tokenPrice);
  
  // Komisyon ayarlarını kaydetme fonksiyonu
  const saveCommissionSettings = () => {
    setCommissionRates(tempCommissionRates);
    setTokenPrice(tempTokenPrice);
    
    // Kullanıcıya bildirim göster
    alert(getText('settingsSaved'));
  };
  
  // İlk kök düğümü oluştur
  useEffect(() => {
    // Başlangıç değerlerini zaten state'in ilk değeri olarak verdik
    setActiveNode(rootNode);
  }, []);
  
  // Toplam hacim hesaplama (alt düğümler dahil)
  const calculateTotalVolume = (node) => {
    if (!node) return 0;
    
    // Eğer node bir ID ise, nodeMap'ten al
    if (typeof node === 'string') {
      node = nodeMap[node];
      if (!node) return 0;
    }
    
    let leftVolume = 0;
    let rightVolume = 0;
    
    if (node.leftChild) {
      const leftNode = nodeMap[node.leftChild];
      if (leftNode) {
        leftVolume = calculateTotalVolume(leftNode);
      }
    }
    
    if (node.rightChild) {
      const rightNode = nodeMap[node.rightChild];
      if (rightNode) {
        rightVolume = calculateTotalVolume(rightNode);
      }
    }
    
    return node.volume + leftVolume + rightVolume;
  };
  

  
  // Yeni kişi ekleme
  const handleAddPerson = (e) => {
    e.preventDefault();
    
    if (!formData.name || !formData.volume) {
      alert('Bitte füllen Sie alle Felder aus');
      return;
    }
    
    const parentNode = activeNode || rootNode;
    const side = formData.side;
    
    // Seçilen tarafta zaten bir alt düğüm varsa uyarı ver
    if ((side === 'left' && parentNode.leftChild) || (side === 'right' && parentNode.rightChild)) {
      alert(`Die ausgewählte Person hat bereits ein Mitglied auf der ${side === 'left' ? 'linken' : 'rechten'} Seite!`);
      return;
    }
    
    // Sayıyı virgüllerden ve noktalardan temizle
    const cleanedVolume = formData.volume.toString().replace(/\./g, '').replace(/,/g, '');
    
    // Yeni düğüm oluştur
    const newNode = {
      id: Date.now().toString(),
      name: formData.name,
      volume: parseFloat(cleanedVolume) || 0,
      leftChild: null,
      rightChild: null,
      parent: parentNode.id,
      sponsor: parentNode.id // Sponsor olarak parent'ı ekle
    };
    
    // Ebeveyn düğümü güncelle
    const updatedParent = { ...parentNode };
    if (side === 'left') {
      updatedParent.leftChild = newNode.id;
    } else {
      updatedParent.rightChild = newNode.id;
    }
    
    // Node haritasını güncelle
    const updatedNodeMap = { ...nodeMap };
    updatedNodeMap[newNode.id] = newNode;
    updatedNodeMap[parentNode.id] = updatedParent;
    
    // Eğer eklenen kişi lider olacaksa kutlama göster
    // Önce parent'ın lider olup olmadığını kontrol et
    const wasLeaderBefore = isLeader(parentNode.id, nodeMap);
    
    // Ekleme sonrası lider oldu mu kontrol et
    const isLeaderNow = isLeader(parentNode.id, updatedNodeMap);
    
    console.log("Teamleiter-Prüfung:", {
      parentId: parentNode.id,
      parentName: parentNode.name,
      wasLeaderBefore,
      isLeaderNow,
      memberCountBefore: countTeamMembers(parentNode.id) - 1,
      memberCountAfter: countTeamMembers(parentNode.id, updatedNodeMap) - 1
    });
    
    // State'i güncelle
    setNodeMap(updatedNodeMap);
    
    // Kök düğümü updatedNodeMap'ten al
    const updatedRoot = { ...updatedNodeMap.root };
    setRootNode(updatedRoot);
    
    // Formu temizle
    setFormData({
      name: '',
      volume: '',
      side: 'left',
      parentId: ''
    });
    
    // Aktif düğümü güncelle
    setActiveNode(updatedParent);
    
    // Eğer önceden lider değildi ve şimdi lider olduysa kutlama göster
    if (!wasLeaderBefore && isLeaderNow) {
      // Önce state'i güncelle, sonra kutlama göster
      setTimeout(() => {
        setCelebrationNode(updatedParent);
        setShowCelebration(true);
        setRunConfetti(true);
        
        // Confetti'yi 5 saniye sonra durdur
        setTimeout(() => {
          setRunConfetti(false);
        }, 5000);
      }, 100);
    }
  };
  
  // Zoom fonksiyonları
  const zoomIn = () => {
    if (zoomLevel < 2.0) {
      setZoomLevel(prev => Math.min(prev + 0.2, 2.0));
    }
  };
  
  const zoomOut = () => {
    if (zoomLevel > 0.6) {
      setZoomLevel(prev => Math.max(prev - 0.2, 0.6));
    }
  };
  
  const resetZoom = () => {
    setZoomLevel(1.0);
  };
  
  // ID'ye göre toplam hacim hesaplama
  const getTotalVolumeForId = (nodeId) => {
    if (!nodeId) return 0;
    const node = nodeMap[nodeId];
    if (!node) return 0;
    
    return calculateTotalVolume(node);
  };
  
  // ID'ye göre kazanç hesaplama
  const calculateEarningsForId = (nodeId) => {
    if (!nodeId) return 0;
    const node = nodeMap[nodeId];
    if (!node) return 0;
    
    // Zayıf kol bazlı kazanç hesaplama
    const leftVolume = node.leftChild ? getTotalVolumeForId(node.leftChild) : 0;
    const rightVolume = node.rightChild ? getTotalVolumeForId(node.rightChild) : 0;
    const weakerLeg = Math.min(leftVolume, rightVolume);
    
    // Kazancı hesapla (zayıf kol * kazanç oranı)
    return weakerLeg * (bonusRates.earningsRate / 100);
  };
  
  // Kişiye özel bonus hesaplama fonksiyonu
  const calculateBonusForId = (nodeId) => {
    if (!nodeId) return { balanceBonus: 0, volumeBonus: 0, leadershipBonus: 0, totalBonus: 0 };
    const node = nodeMap[nodeId];
    if (!node) return { balanceBonus: 0, volumeBonus: 0, leadershipBonus: 0, totalBonus: 0 };
    
    const bonuses = {
      balanceBonus: 0,
      volumeBonus: 0,
      leadershipBonus: 0,
      totalBonus: 0
    };
    
    // Kişinin sol ve sağ kol hacimlerini hesapla
    const leftVolume = node.leftChild ? getTotalVolumeForId(node.leftChild) : 0;
    const rightVolume = node.rightChild ? getTotalVolumeForId(node.rightChild) : 0;
    const weakerLeg = Math.min(leftVolume, rightVolume);
    const strongerLeg = Math.max(leftVolume, rightVolume);
    const totalVolume = leftVolume + rightVolume;
    
    // Denge bonusu: Sol ve sağ kol dengeli ise ekstra bonus
    const ratio = weakerLeg > 0 && strongerLeg > 0 
      ? weakerLeg / strongerLeg 
      : 0;
    
    if (ratio >= bonusRates.balanceBonusRatio / 100) {
      bonuses.balanceBonus = weakerLeg * (bonusRates.balanceBonusRate / 100); // Denge bonusu
    }
    
    // Hacim bonusu: Toplam hacim belirli bir eşiği geçerse
    if (totalVolume > bonusRates.volumeBonusThreshold) {
      bonuses.volumeBonus = totalVolume * (bonusRates.volumeBonusRate / 100); // Hacim bonusu
    }
    
    // Liderlik bonusu: Üye sayısı belirli bir eşiği geçerse
    const memberCount = countTeamMembers(nodeId) - 1; // Kendisi hariç
    if (memberCount >= bonusRates.leadershipBonusThreshold) {
      bonuses.leadershipBonus = bonusRates.leadershipBonusAmount * memberCount; // Her üye için bonus
    }
    
    bonuses.totalBonus = bonuses.balanceBonus + bonuses.volumeBonus + bonuses.leadershipBonus;
    
    return bonuses;
  };
  
  // Yeni Komisyon Hesaplamaları
  
  // 1. Cycle Komisyonu Hesaplama
  const calculateCycleCommission = (nodeId) => {
    if (!nodeId) return 0;
    const node = nodeMap[nodeId];
    if (!node) return 0;
    
    // Sol ve sağ bacak hacimlerini USD cinsinden hesapla
    const leftVolume = node.leftChild ? getTotalVolumeForId(node.leftChild) * tokenPrice : 0;
    const rightVolume = node.rightChild ? getTotalVolumeForId(node.rightChild) * tokenPrice : 0;
    
    // Cycle hesaplama: Her iki bacak da eşiği geçmişse cycle sayısını hesapla
    if (leftVolume >= commissionRates.cycleThreshold && rightVolume >= commissionRates.cycleThreshold) {
      // Zayıf kol baz alınarak cycle sayısı hesaplanır
      const weakerLeg = Math.min(leftVolume, rightVolume);
      const cycleCount = Math.floor(weakerLeg / commissionRates.cycleThreshold);
      return cycleCount * commissionRates.cycleAmount;
    }
    
    return 0;
  };
  
  // Direkt referansları (sponsorluğu) bulma fonksiyonu
  const getDirectReferrals = (nodeId, maxDepth = 4) => {
    if (!nodeId) return [];
    
    const result = [];
    
    // Bağlı tüm düğümlerin sponsorunu kontrol et
    const checkSponsorship = (id, depth = 1) => {
      if (depth > maxDepth) return; // Maksimum derinliğe ulaşıldı
      
      // Tüm düğümleri dolaş
      Object.values(nodeMap).forEach(node => {
        // Eğer düğümün sponsoru verilen ID ise, onu listeye ekle
        if (node.sponsor === id) {
          result.push({
            id: node.id,
            name: node.name,
            volume: node.volume,
            level: depth
          });
          
          // Daha sonra bu düğümün sponsorluklarını kontrol et (bir sonraki seviye)
          checkSponsorship(node.id, depth + 1);
        }
      });
    };
    
    // Verilen ID'den başlayarak tüm soy ağacını kontrol et
    checkSponsorship(nodeId);
    
    return result;
  };
  
  // 2. Direkt Komisyon Hesaplama
  const calculateDirectCommission = (nodeId) => {
    if (!nodeId) return 0;
    
    // Kişinin direkt getirdiği üyelerin listesini bul
    const directReferrals = getDirectReferrals(nodeId);
    let totalDirectCommission = 0;
    
    // Her seviye için ayrı komisyon oranları kullanılır
    directReferrals.forEach(referral => {
      const referralVolume = referral.volume * tokenPrice; // USD cinsinden hacim
      
      if (referral.level === 1) {
        totalDirectCommission += referralVolume * (commissionRates.directCommissionRates.level1 / 100);
      } else if (referral.level === 2) {
        totalDirectCommission += referralVolume * (commissionRates.directCommissionRates.level2 / 100);
      } else if (referral.level === 3) {
        totalDirectCommission += referralVolume * (commissionRates.directCommissionRates.level3 / 100);
      } else if (referral.level === 4) {
        totalDirectCommission += referralVolume * (commissionRates.directCommissionRates.level4 / 100);
      }
    });
    
    return totalDirectCommission;
  };
  
  // 3. Takım Komisyonu Hesaplama
  const calculateTeamCommission = (nodeId) => {
    if (!nodeId) return 0;
    
    // Takım üye sayısı
    const teamMemberCount = countTeamMembers(nodeId) - 1; // Kendisi hariç
    
    // Takım üye sayısı eşiği geçmişse
    if (teamMemberCount >= commissionRates.teamCommissionThreshold) {
      // Takım toplam hacmi
      const teamTotalVolume = getTotalVolumeForId(nodeId) * tokenPrice; // USD cinsinden
      
      // Takım komisyonu hesapla
      return teamTotalVolume * (commissionRates.teamCommissionRate / 100);
    }
    
    return 0;
  };
  
  // 4. Eşleşme Bonusu Hesaplama
  const calculateMatchingBonus = (nodeId) => {
    if (!nodeId) return 0;
    
    // Direkt sponsorluğundaki üyeleri bul (sadece 1. seviye)
    const directReferrals = getDirectReferrals(nodeId, 1);
    let totalMatchingBonus = 0;
    
    // Her bir direkt üyenin cycle kazancından pay al
    directReferrals.forEach(referral => {
      const referralCycleCommission = calculateCycleCommission(referral.id);
      totalMatchingBonus += referralCycleCommission * (commissionRates.matchingBonusRate / 100);
    });
    
    return totalMatchingBonus;
  };
  
  // Tüm komisyonları hesaplama
  const calculateTotalCommissions = (nodeId) => {
    if (!nodeId) return {
      cycleCommission: 0, 
      directCommission: 0,
      teamCommission: 0,
      matchingBonus: 0,
      total: 0
    };
    
    const cycleCommission = calculateCycleCommission(nodeId);
    const directCommission = calculateDirectCommission(nodeId);
    const teamCommission = calculateTeamCommission(nodeId);
    const matchingBonus = calculateMatchingBonus(nodeId);
    
    return {
      cycleCommission,
      directCommission,
      teamCommission,
      matchingBonus,
      total: cycleCommission + directCommission + teamCommission + matchingBonus
    };
  };
  
  // Ağaç görünümünü oluştur
  const renderBinaryTree = (nodeId = 'root', level = 0, side = 'root') => {
    if (!nodeId) return null;
    
    const node = nodeMap[nodeId];
    if (!node) return null;
    
    const leftChildId = node.leftChild;
    const rightChildId = node.rightChild;
    
    const hasLeftChild = leftChildId && nodeMap[leftChildId];
    const hasRightChild = rightChildId && nodeMap[rightChildId];
    
    // Kişinin kazanç yüzdesini hesapla
    const leftVolume = getTotalVolumeForId(leftChildId);
    const rightVolume = getTotalVolumeForId(rightChildId);
    const weakerLeg = Math.min(leftVolume, rightVolume);
    const totalVolume = leftVolume + rightVolume;
    
    // Kazanç yüzdesi (zayıf kol üzerinden bonusRates.earningsRate)
    const earningsPercentage = weakerLeg > 0 ? bonusRates.earningsRate : 0;
    
    // Kişiye özel bonusları hesapla
    const personBonuses = calculateBonusForId(nodeId);
    
    // Yeni komisyon sistemine göre hesaplama
    const commissionsInfo = calculateTotalCommissions(nodeId);
    
    // Toplam gelir (eski ve yeni sistem)
    const totalIncome = calculateEarningsForId(nodeId) + personBonuses.totalBonus + commissionsInfo.total;
    
    // Düğüm kartı
    const nodeCard = (
      <NodeCard 
        side={side === 'root' ? 'left' : side} 
        isActive={activeNode && node.id === activeNode.id}
        onClick={() => setActiveNode(node)}
      >
        {isLeader(nodeId, nodeMap) && (
          <LeaderBadge title={getText('leaderBadgeTitle')}>
            <FaTrophy />
          </LeaderBadge>
        )}
        <NodeHeader>
          <NodeTitle>{node.name}</NodeTitle>
          <NodeSide>{side === 'root' ? 'Root' : side === 'left' ? getText('leftLeg') : getText('rightLeg')}</NodeSide>
        </NodeHeader>
        
        <NodeInfo side={side === 'root' ? 'left' : side}>
          <div className="info-row">
            <span className="info-label">{getText('volume')}:</span>
            <span className="info-value">
              {formatNumber(node.volume || 0)} MFT
              <span className="usd-value">
                (${formatNumber((node.volume || 0) * tokenPrice, 2)})
              </span>
            </span>
          </div>
          <div className="info-row">
            <span className="info-label">{getText('leftLeg')}:</span>
            <span className="info-value">
              {formatNumber(leftVolume)} MFT
            </span>
          </div>
          <div className="info-row">
            <span className="info-label">{getText('rightLeg')}:</span>
            <span className="info-value">
              {formatNumber(rightVolume)} MFT
            </span>
          </div>
          
          {/* Eski Sistem Kazançları */}
          <div style={{ borderTop: '1px dotted rgba(255,255,255,0.2)', marginTop: '5px', paddingTop: '5px' }}>
            <div className="info-row">
              <span className="info-label">{getText('earningsPercentage')}:</span>
              <span className="info-value highlight">
                {earningsPercentage}%
              </span>
            </div>
            <div className="info-row">
              <span className="info-label">{getText('earnings')}:</span>
              <span className="info-value highlight">
                {formatNumber(calculateEarningsForId(nodeId), 2)} MFT
                <span className="usd-value">
                  (${formatNumber(calculateEarningsForId(nodeId) * tokenPrice, 2)})
                </span>
              </span>
            </div>
          </div>
          
          {/* Yeni Komisyon Sistemi */}
          {commissionsInfo.total > 0 && (
            <div style={{ borderTop: '1px dotted rgba(255,255,255,0.2)', marginTop: '5px', paddingTop: '5px' }}>
              <div className="info-row">
                <span className="info-label">{getText('cycleCommission')}:</span>
                <span className="info-value highlight">
                  ${formatNumber(commissionsInfo.cycleCommission, 2)}
                </span>
              </div>
              {commissionsInfo.directCommission > 0 && (
                <div className="info-row">
                  <span className="info-label">{getText('directCommission')}:</span>
                  <span className="info-value highlight">
                    ${formatNumber(commissionsInfo.directCommission, 2)}
                  </span>
                </div>
              )}
              {commissionsInfo.teamCommission > 0 && (
                <div className="info-row">
                  <span className="info-label">{getText('teamCommission')}:</span>
                  <span className="info-value highlight">
                    ${formatNumber(commissionsInfo.teamCommission, 2)}
                  </span>
                </div>
              )}
              {commissionsInfo.matchingBonus > 0 && (
                <div className="info-row">
                  <span className="info-label">{getText('matchingBonus')}:</span>
                  <span className="info-value highlight">
                    ${formatNumber(commissionsInfo.matchingBonus, 2)}
                  </span>
                </div>
              )}
              <div className="info-row">
                <span className="info-label">{getText('totalCommission')}:</span>
                <span className="info-value highlight">
                  ${formatNumber(commissionsInfo.total, 2)}
                </span>
              </div>
            </div>
          )}
          
          {/* Bonus Bilgileri */}
          {personBonuses.totalBonus > 0 && (
            <div className="info-row">
              <span className="info-label">{getText('totalBonus')}:</span>
              <span className="info-value highlight">
                {formatNumber(personBonuses.totalBonus, 2)} MFT
              </span>
            </div>
          )}
          
          {/* Toplam Gelir */}
          {totalIncome > 0 && (
            <div className="info-row" style={{ borderTop: '1px solid rgba(255,255,255,0.2)', paddingTop: '5px', marginTop: '5px' }}>
              <span className="info-label">{getText('totalIncome')}:</span>
              <span className="info-value highlight">
                {formatNumber(totalIncome, 2)} MFT
                <span className="usd-value">
                  (${formatNumber(totalIncome * tokenPrice, 2)})
                </span>
              </span>
            </div>
          )}
        </NodeInfo>
      </NodeCard>
    );
    
    // Boş düğüm oluşturma
    const createEmptyNode = (side) => (
                <EmptyNode onClick={() => {
                  setActiveNode(node);
        setFormData({...formData, side});
                }}>
        <FaPlus /> {node.name}'s {side === 'left' ? 'Linkes' : 'Rechtes'} Bein hinzufügen
                </EmptyNode>
    );
    
    // Alt düğümleri oluştur
    const renderChildren = () => {
      // Her seviye için genişlik hesaplama
      const childSpacing = Math.max(10, 120 / Math.pow(2, level));
      const childStyle = {
        width: '50%',
        padding: `0 ${childSpacing}px`
      };

      if (!hasLeftChild && !hasRightChild) {
        return (
          <TreeLevel level={level+1}>
            <NodeWrapper level={level+1} style={childStyle}>
              <ConnectorHorizontal />
              {createEmptyNode('left')}
              </NodeWrapper>
            <NodeWrapper level={level+1} style={childStyle}>
              <ConnectorHorizontal />
              {createEmptyNode('right')}
              </NodeWrapper>
          </TreeLevel>
        );
      }
      
      return (
        <TreeLevel level={level+1}>
          <NodeWrapper level={level+1} style={childStyle}>
            <ConnectorHorizontal />
            {hasLeftChild ? 
              renderBinaryTree(leftChildId, level + 1, 'left') : 
              createEmptyNode('left')
            }
            </NodeWrapper>
          <NodeWrapper level={level+1} style={childStyle}>
            <ConnectorHorizontal />
            {hasRightChild ? 
              renderBinaryTree(rightChildId, level + 1, 'right') : 
              createEmptyNode('right')
            }
          </NodeWrapper>
        </TreeLevel>
      );
    };
    
    // Her seviye için stil hesaplama
    const getNodeStyle = () => {
      if (level === 0) return {};
      
      // Her seviye için genişlik hesaplama
      const spacing = Math.max(20, 240 / Math.pow(2, level - 1));
      return {
        margin: `0 ${spacing}px`
      };
    };
    
    return (
      <div key={node.id} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TreeLevel isRoot={level === 0} level={level}>
          <NodeWrapper level={level} style={getNodeStyle()}>
            {level > 0 && <ConnectorVertical />}
            {nodeCard}
            </NodeWrapper>
          </TreeLevel>
        
        {(hasLeftChild || hasRightChild || level < 4) && (
          <>
            <ConnectorContainer />
            {renderChildren()}
          </>
        )}
      </div>
    );
  };
  
  // Toplam istatistikleri hesapla
  const calculateStats = () => {
    if (!rootNode) {
      return {
        totalNodes: 0,
        leftLegVolume: 0,
        rightLegVolume: 0,
        weakerLeg: 0,
        strongerLeg: 0,
        totalVolume: 0
      };
    }
    
    const countNodes = (nodeId) => {
      if (!nodeId) return 0;
      const node = nodeMap[nodeId];
      if (!node) return 0;
      
      return 1 + 
        (node.leftChild ? countNodes(node.leftChild) : 0) + 
        (node.rightChild ? countNodes(node.rightChild) : 0);
    };
    
    const leftVolume = rootNode.leftChild ? getTotalVolumeForId(rootNode.leftChild) : 0;
    const rightVolume = rootNode.rightChild ? getTotalVolumeForId(rootNode.rightChild) : 0;
    const weakerLeg = Math.min(leftVolume, rightVolume);
    const strongerLeg = Math.max(leftVolume, rightVolume);
    
    return {
      totalNodes: countNodes('root') - 1, // Kök düğümü sayma
      leftLegVolume: leftVolume,
      rightLegVolume: rightVolume,
      weakerLeg,
      strongerLeg,
      totalVolume: leftVolume + rightVolume
    };
  };
  
  const stats = calculateStats();
  
  // Sayı formatlamak için geliştirmiş fonksiyon
  const formatNumber = (value, decimals = 0) => {
    if (value === undefined || value === null) return '0';
    
    // Ondalık ayarları
    const options = {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    };
    
    return Number(value).toLocaleString('de-DE', options);
  };
  
  // Ağaç görünümünü minimize et/aç
  const toggleTreeView = () => {
    setIsTreeMinimized(!isTreeMinimized);
  };
  
  // Bilgi bölümünü minimize et/aç
  const toggleInfoSection = () => {
    setIsInfoMinimized(!isInfoMinimized);
  };
  
  // Test fonksiyonu - Lider oluştur
  const testCreateLeader = () => {
    // Aktif düğüm yoksa kök düğümü kullan
    const parentNode = activeNode || rootNode;
    
    // Önce parent'ın lider olup olmadığını kontrol et
    const wasLeaderBefore = isLeader(parentNode.id, nodeMap);
    
    if (wasLeaderBefore) {
      alert(`${parentNode.name} ist bereits ein Teamleiter!`);
      return;
    }
    
    // Yeni nodeMap oluştur
    const updatedNodeMap = { ...nodeMap };
    const updatedParent = { ...parentNode };
    
    // Sol kola 3 kişi ekle
    if (!updatedParent.leftChild) {
      const leftNode1 = {
        id: 'test_left_1',
        name: 'Test Sol 1',
        volume: 1000,
        leftChild: null,
        rightChild: null,
        parent: parentNode.id
      };
      updatedNodeMap[leftNode1.id] = leftNode1;
      updatedParent.leftChild = leftNode1.id;
      
      const leftNode2 = {
        id: 'test_left_2',
        name: 'Test Sol 2',
        volume: 1000,
        leftChild: null,
        rightChild: null,
        parent: leftNode1.id
      };
      updatedNodeMap[leftNode2.id] = leftNode2;
      updatedNodeMap[leftNode1.id] = {
        ...leftNode1,
        leftChild: leftNode2.id
      };
      
      const leftNode3 = {
        id: 'test_left_3',
        name: 'Test Sol 3',
        volume: 1000,
        leftChild: null,
        rightChild: null,
        parent: leftNode1.id
      };
      updatedNodeMap[leftNode3.id] = leftNode3;
      updatedNodeMap[leftNode1.id] = {
        ...updatedNodeMap[leftNode1.id],
        rightChild: leftNode3.id
      };
    }
    
    // Sağ kola 2 kişi ekle
    if (!updatedParent.rightChild) {
      const rightNode1 = {
        id: 'test_right_1',
        name: 'Test Sağ 1',
        volume: 1000,
        leftChild: null,
        rightChild: null,
        parent: parentNode.id
      };
      updatedNodeMap[rightNode1.id] = rightNode1;
      updatedParent.rightChild = rightNode1.id;
      
      const rightNode2 = {
        id: 'test_right_2',
        name: 'Test Sağ 2',
        volume: 1000,
        leftChild: null,
        rightChild: null,
        parent: rightNode1.id
      };
      updatedNodeMap[rightNode2.id] = rightNode2;
      updatedNodeMap[rightNode1.id] = {
        ...rightNode1,
        leftChild: rightNode2.id
      };
    }
    
    // Parent düğümü güncelle
    updatedNodeMap[parentNode.id] = updatedParent;
    
    // Kök düğümü güncelle
    const updatedRoot = { ...updatedNodeMap.root };
    
    // Ekleme sonrası lider oldu mu kontrol et
    const isLeaderNow = isLeader(parentNode.id, updatedNodeMap);
    
    console.log("Test Teamleiter Erstellen:", {
      parentId: parentNode.id,
      parentName: parentNode.name,
      wasLeaderBefore,
      isLeaderNow,
      memberCountBefore: countTeamMembers(parentNode.id) - 1,
      memberCountAfter: countTeamMembers(parentNode.id, updatedNodeMap) - 1
    });
    
    // State'i güncelle
    setNodeMap(updatedNodeMap);
    setRootNode(updatedRoot);
    setActiveNode(updatedParent);
    
    // Eğer önceden lider değildi ve şimdi lider olduysa kutlama göster
    if (!wasLeaderBefore && isLeaderNow) {
      // Önce state'i güncelle, sonra kutlama göster
      setTimeout(() => {
        setCelebrationNode(updatedParent);
        setShowCelebration(true);
        setRunConfetti(true);
        
        // Confetti'yi 5 saniye sonra durdur
        setTimeout(() => {
          setRunConfetti(false);
        }, 5000);
      }, 100);
    }
  };
  
  // Kutlama modalını kapat
  const closeCelebration = () => {
    setShowCelebration(false);
  };
  
  // Takım üyesi sayısını hesapla
  const countTeamMembers = (nodeId, customNodeMap = null) => {
    const useNodeMap = customNodeMap || nodeMap;
    
    if (!nodeId) return 0;
    const node = useNodeMap[nodeId];
    if (!node) return 0;
    
    // Kendisi + alt üyeler
    return 1 + 
           (node.leftChild ? countTeamMembers(node.leftChild, useNodeMap) : 0) + 
           (node.rightChild ? countTeamMembers(node.rightChild, useNodeMap) : 0);
  };
  
  // Tooltip gösterme fonksiyonu
  const showTooltip = (textKey, e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    
    // Dinamik tooltip metinleri için
    let tooltipText = '';
    
    const texts = {
      // Tooltip açıklamaları
      totalMembersInfo: {
        de: 'Gesamtzahl der Mitglieder in Ihrem Netzwerk (ohne Sie)',
        tr: 'Ağınızdaki toplam üye sayısı (siz hariç)'
      },
      weakerLegInfo: {
        de: 'Das Bein mit dem geringeren Volumen. Ihr Verdienst wird auf Basis des schwächeren Beins berechnet.',
        tr: 'Daha düşük hacme sahip kol. Kazancınız zayıf kol üzerinden hesaplanır.'
      },
      strongerLegInfo: {
        de: 'Das Bein mit dem höheren Volumen. Dieses Volumen wird für zukünftige Auszahlungen aufbewahrt.',
        tr: 'Daha yüksek hacme sahip kol. Bu hacim gelecekteki ödemeler için saklanır.'
      },
      leftLegVolumeInfo: {
        de: 'Gesamtvolumen aller Mitglieder in Ihrem linken Bein.',
        tr: 'Sol kolunuzdaki tüm üyelerin toplam hacmi.'
      },
      rightLegVolumeInfo: {
        de: 'Gesamtvolumen aller Mitglieder in Ihrem rechten Bein.',
        tr: 'Sağ kolunuzdaki tüm üyelerin toplam hacmi.'
      },
      totalVolumeInfo: {
        de: 'Summe des Volumens beider Beine in Ihrem Netzwerk.',
        tr: 'Ağınızdaki her iki kolun toplam hacmi.'
      },
      
      // Yeni Komisyon Sistemi Tooltipleri
      cycleCommissionInfo: {
        de: 'Wenn beide Beine das Mindestvolumen von $1.000 erreichen, erhalten Sie $500 pro Cycle. Berechnet auf Basis des schwächeren Beins.',
        tr: 'Her iki kol da $1.000 minimum hacme ulaştığında, cycle başına $500 kazanırsınız. Zayıf kol baz alınarak hesaplanır.'
      },
      directCommissionInfo: {
        de: 'Sie erhalten Provision aus den direkten Sponsorschaften bis zur 4. Ebene: Level 1 (7%), Level 2 (4%), Level 3 (3%), Level 4 (1%).',
        tr: 'Doğrudan sponsorluğunu yaptığınız kişilerden 4. seviyeye kadar komisyon alırsınız: Seviye 1 (%7), Seviye 2 (%4), Seviye 3 (%3), Seviye 4 (%1).'
      },
      teamCommissionInfo: {
        de: 'Wenn Sie mindestens 20 Teammitglieder haben, erhalten Sie 6% Provision vom Gesamtvolumen Ihres Teams.',
        tr: 'En az 20 takım üyeniz varsa, takımınızın toplam hacminden %6 komisyon alırsınız.'
      },
      matchingBonusInfo: {
        de: 'Sie erhalten 3% der Cycle-Provisionen aller direkt gesponserten Mitglieder.',
        tr: 'Doğrudan sponsorluğunu yaptığınız tüm üyelerin Cycle komisyonlarından %3 pay alırsınız.'
      },
      totalCommissionInfo: {
        de: 'Summe aller Provisionen (Cycle + Direkt + Team + Matching).',
        tr: 'Tüm komisyonların toplamı (Cycle + Direkt + Takım + Eşleşme).'
      },
      totalIncomeInfo: {
        de: 'Ihr Gesamteinkommen aus dem MFT Token Verdienstmodell.',
        tr: 'MFT Token kazanç modelinden elde ettiğiniz toplam gelir.'
      },
      
      // Eski Bonus Bilgileri
      balanceBonusInfo: {
        de: (rate, ratio) => `${rate}% Bonus auf das schwächere Bein, wenn das Verhältnis zwischen schwächerem und stärkerem Bein mindestens ${ratio}% beträgt.`,
        tr: (rate, ratio) => `Zayıf kol ile güçlü kol arasındaki oran en az %${ratio} ise, zayıf kol üzerinden %${rate} bonus.`
      },
      volumeBonusInfo: {
        de: (rate, threshold) => `${rate}% Bonus auf das Gesamtvolumen, wenn es ${formatNumber(threshold)} MFT überschreitet.`,
        tr: (rate, threshold) => `Toplam hacim ${formatNumber(threshold)} MFT'yi aşarsa, toplam hacim üzerinden %${rate} bonus.`
      },
      leadershipBonusInfo: {
        de: (amount, threshold) => `${formatNumber(amount)} MFT pro Mitglied in Ihrem Netzwerk, wenn Sie mindestens ${threshold} Mitglieder haben.`,
        tr: (amount, threshold) => `En az ${threshold} üyeniz varsa, ağınızdaki her üye için ${formatNumber(amount)} MFT.`
      },
      totalBonusInfo: {
        de: 'Summe aller Boni (Balance, Volumen und Leadership).',
        tr: 'Tüm bonusların toplamı (Denge, Hacim ve Liderlik).'
      },
      totalEarningsInfo: {
        de: (rate) => `${rate}% des Volumens Ihres schwächeren Beins.`,
        tr: (rate) => `Zayıf kolunuzun hacminin %${rate}'u.`
      },
      
      // Bonus ayarları için tooltip açıklamaları
      earningsRateInfo: {
        de: 'Prozentsatz des Volumens des schwächeren Beins, der als Verdienst ausgezahlt wird.',
        tr: 'Zayıf kol hacminin kazanç olarak ödenen yüzdesi.'
      },
      balanceBonusRateInfo: {
        de: 'Prozentsatz des schwächeren Beins, der als Balance Bonus ausgezahlt wird, wenn das Verhältnis erreicht ist.',
        tr: 'Oran sağlandığında, zayıf kol hacminin denge bonusu olarak ödenen yüzdesi.'
      },
      balanceBonusRatioInfo: {
        de: 'Mindestprozentsatz des Verhältnisses zwischen schwächerem und stärkerem Bein, um den Balance Bonus zu erhalten.',
        tr: 'Denge bonusu almak için zayıf kol ile güçlü kol arasındaki minimum oran yüzdesi.'
      },
      volumeBonusRateInfo: {
        de: 'Prozentsatz des Gesamtvolumens, der als Volumen Bonus ausgezahlt wird, wenn der Schwellenwert erreicht ist.',
        tr: 'Eşik değerine ulaşıldığında, toplam hacmin hacim bonusu olarak ödenen yüzdesi.'
      },
      volumeBonusThresholdInfo: {
        de: 'Mindestvolumen (in MFT), das erreicht werden muss, um den Volumen Bonus zu erhalten.',
        tr: 'Hacim bonusu almak için ulaşılması gereken minimum hacim (MFT cinsinden).'
      },
      leadershipBonusAmountInfo: {
        de: 'MFT-Betrag, der pro Mitglied als Leadership Bonus ausgezahlt wird, wenn der Schwellenwert erreicht ist.',
        tr: 'Eşik değerine ulaşıldığında, her üye için liderlik bonusu olarak ödenen MFT miktarı.'
      },
      leadershipBonusThresholdInfo: {
        de: 'Mindestanzahl an Mitgliedern, die benötigt wird, um den Leadership Bonus zu erhalten.',
        tr: 'Liderlik bonusu almak için gereken minimum üye sayısı.'
      },
      
      // Komisyon ayarları
      price: {
        de: 'Preis ($):',
        tr: 'Fiyatı ($):'
      },
      firmDistributionRate: {
        de: 'Firmenverteilungsrate (%):',
        tr: 'Firma Dağıtım Oranı (%):'
      },
    };
    
    if (typeof texts[textKey].de === 'function') {
      // Fonksiyon ise parametreleri geçir
      switch(textKey) {
        case 'balanceBonusInfo':
          tooltipText = texts[textKey][language](bonusRates.balanceBonusRate, bonusRates.balanceBonusRatio);
          break;
        case 'volumeBonusInfo':
          tooltipText = texts[textKey][language](bonusRates.volumeBonusRate, bonusRates.volumeBonusThreshold);
          break;
        case 'leadershipBonusInfo':
          tooltipText = texts[textKey][language](bonusRates.leadershipBonusAmount, bonusRates.leadershipBonusThreshold);
          break;
        case 'totalEarningsInfo':
          tooltipText = texts[textKey][language](bonusRates.earningsRate);
          break;
        default:
          tooltipText = texts[textKey][language];
      }
    } else {
      // Normal string ise direkt al
      tooltipText = texts[textKey][language];
    }
    
    setTooltip({
      show: true,
      text: tooltipText,
      x: rect.left + rect.width / 2,
      y: rect.bottom + 5
    });
  };
  
  // Tooltip gizleme fonksiyonu
  const hideTooltip = () => {
    setTooltip({
      ...tooltip,
      show: false
    });
  };
  
  // Dil değiştirme fonksiyonu
  const toggleLanguage = () => {
    setLanguage(prev => prev === 'de' ? 'tr' : 'de');
  };
  
  // Metinleri dil seçimine göre getiren fonksiyon
  const getText = (key) => {
    const texts = {
      // Header ve genel metinler
      title: {
        de: 'Binary Network Marketing System',
        tr: 'İkili Ağ Pazarlama Sistemi'
      },
      german: {
        de: 'Deutsch',
        tr: 'Almanca'
      },
      turkish: {
        de: 'Türkisch',
        tr: 'Türkçe'
      },
      
      // İstatistik başlıkları
      networkOverview: {
        de: 'Netzwerk Übersicht',
        tr: 'Ağ Genel Bakışı'
      },
      bonusEarnings: {
        de: 'Bonus Verdienste',
        tr: 'Bonus Kazançları'
      },
      commissionsOverview: {
        de: 'Provisionsübersicht',
        tr: 'Komisyon Genel Bakışı'
      },
      
      // İstatistik öğeleri
      totalMembers: {
        de: 'Gesamtmitglieder:',
        tr: 'Toplam Üyeler:'
      },
      weakerLeg: {
        de: 'Schwächeres Bein:',
        tr: 'Zayıf Kol:'
      },
      strongerLeg: {
        de: 'Stärkeres Bein:',
        tr: 'Güçlü Kol:'
      },
      leftLegVolume: {
        de: 'Linkes Bein Volumen:',
        tr: 'Sol Kol Hacmi:'
      },
      rightLegVolume: {
        de: 'Rechtes Bein Volumen:',
        tr: 'Sağ Kol Hacmi:'
      },
      totalVolume: {
        de: 'Gesamtvolumen:',
        tr: 'Toplam Hacim:'
      },
      balanceBonus: {
        de: 'Balance Bonus:',
        tr: 'Denge Bonusu:'
      },
      volumeBonus: {
        de: 'Volumen Bonus:',
        tr: 'Hacim Bonusu:'
      },
      leadershipBonus: {
        de: 'Leadership Bonus:',
        tr: 'Liderlik Bonusu:'
      },
      totalBonus: {
        de: 'Gesamtbonus:',
        tr: 'Toplam Bonus:'
      },
      totalEarnings: {
        de: 'Gesamtverdienst:',
        tr: 'Toplam Kazanç:'
      },
      totalIncome: {
        de: 'Gesamteinkommen:',
        tr: 'Toplam Gelir:'
      },
      
      // Yeni komisyon sistemi metinleri
      cycleCommission: {
        de: 'Cycle Provision:',
        tr: 'Cycle Komisyonu:'
      },
      directCommission: {
        de: 'Direkte Provision:',
        tr: 'Direkt Komisyon:'
      },
      teamCommission: {
        de: 'Team Provision:',
        tr: 'Takım Komisyonu:'
      },
      matchingBonus: {
        de: 'Matching Bonus:',
        tr: 'Eşleşme Bonusu:'
      },
      totalCommission: {
        de: 'Gesamtprovision:',
        tr: 'Toplam Komisyon:'
      },
      
      // Tooltip açıklamaları artık burada değil, showTooltip fonksiyonunda tanımlanıyor
      
      // Diğer metinler
      howItWorks: {
        de: 'Wie funktioniert das Binary Network System?',
        tr: 'İkili Ağ Sistemi Nasıl Çalışır?'
      },
      howItWorksInfo: {
        de: 'Im Binary-System haben Sie zwei Beine in Ihrem Netzwerk: links und rechts. Jedes Mitglied kann nur zwei Personen direkt unter sich platzieren. Ihr Verdienst wird auf Basis des schwächeren Beins (mit geringerem Volumen) berechnet. Dieses System fördert ein ausgewogenes Wachstum Ihres Netzwerks.',
        tr: 'İkili Sistemde, ağınızda iki kol vardır: sol ve sağ. Her üye doğrudan altına sadece iki kişi yerleştirebilir. Kazancınız, zayıf kol (daha düşük hacimli) baz alınarak hesaplanır. Bu sistem, ağınızın dengeli büyümesini teşvik eder.'
      },
      testLeaderButton: {
        de: 'Test: Teamleiter erstellen',
        tr: 'Test: Lider Oluştur'
      },
      
      // Komisyon sistemi açıklaması
      commissionSystemTitle: {
        de: 'MFT Token Verdienstmodell',
        tr: 'MFT Token Kazanç Modeli'
      },
      commissionSystemInfo: {
        de: 'Die Firma verteilt 49% der Einnahmen als Provisionen an die Mitglieder. Es gibt vier Arten von Provisionen: Cycle Provision (25%), Direkte Provision (15%), Team Provision (6%) und Matching Bonus (3%).',
        tr: 'Firma, gelirin %49\'unu komisyon olarak üyelere dağıtır. Dört tür komisyon vardır: Cycle Komisyonu (%25), Direkt Komisyon (%15), Takım Komisyonu (%6) ve Eşleşme Bonusu (%3).'
      },
      
      // Kutlama modalı
      congratulations: {
        de: 'GLÜCKWUNSCH!',
        tr: 'TEBRİKLER!'
      },
      isNowLeader: {
        de: 'ist jetzt ein Teamleiter!',
        tr: 'artık bir lider!'
      },
      memberCount: {
        de: 'Anzahl der Teammitglieder:',
        tr: 'Takım üyesi sayısı:'
      },
      totalEarningsLeader: {
        de: 'Gesamtverdienst:',
        tr: 'Toplam kazanç:'
      },
      greatButton: {
        de: 'Großartig!',
        tr: 'Harika!'
      },
      
      // Floating card
      selectedPerson: {
        de: 'Ausgewählte Person:',
        tr: 'Seçilen Kişi:'
      },
      addNewPerson: {
        de: 'Neue Person hinzufügen',
        tr: 'Yeni Kişi Ekle'
      },
      name: {
        de: 'Name:',
        tr: 'İsim:'
      },
      enterName: {
        de: 'Name der Person eingeben',
        tr: 'Kişinin adını girin'
      },
      mftAmount: {
        de: 'MFT Menge:',
        tr: 'MFT Miktarı:'
      },
      purchasedMft: {
        de: 'Gekaufte MFT Menge',
        tr: 'Satın alınan MFT miktarı'
      },
      position: {
        de: 'Position:',
        tr: 'Pozisyon:'
      },
      leftLeg: {
        de: 'Linkes Bein',
        tr: 'Sol Kol'
      },
      rightLeg: {
        de: 'Rechtes Bein',
        tr: 'Sağ Kol'
      },
      add: {
        de: 'Hinzufügen',
        tr: 'Ekle'
      },
      
      // Kart içindeki metinler
      volume: {
        de: 'Volumen',
        tr: 'Hacim'
      },
      leftLeg: {
        de: 'Linkes Bein',
        tr: 'Sol Kol'
      },
      rightLeg: {
        de: 'Rechtes Bein',
        tr: 'Sağ Kol'
      },
      earnings: {
        de: 'Verdienst',
        tr: 'Kazanç'
      },
      earningsPercentage: {
        de: 'Verdienst %',
        tr: 'Kazanç %'
      },
      leaderBadgeTitle: {
        de: 'Teamleiter (5+ Mitglieder)',
        tr: 'Lider (5+ üye)'
      },
      totalBonus: {
        de: 'Gesamtbonus',
        tr: 'Toplam Bonus'
      },
      totalIncome: {
        de: 'Gesamteinkommen',
        tr: 'Toplam Gelir'
      },
      
      // Bonus ayarları sekmesi
      bonusSettings: {
        de: 'Bonus Einstellungen',
        tr: 'Bonus Ayarları'
      },
      commissionSettings: {
        de: 'Provisions Einstellungen',
        tr: 'Komisyon Ayarları'
      },
      addMember: {
        de: 'Mitglied hinzufügen',
        tr: 'Üye Ekle'
      },
      earningsRate: {
        de: 'Verdienst Rate (%):',
        tr: 'Kazanç Oranı (%):'
      },
      balanceBonusRate: {
        de: 'Balance Bonus Rate (%):',
        tr: 'Denge Bonusu Oranı (%):'
      },
      balanceBonusRatio: {
        de: 'Balance Bonus Schwelle (%):',
        tr: 'Denge Bonusu Eşiği (%):'
      },
      volumeBonusRate: {
        de: 'Volumen Bonus Rate (%):',
        tr: 'Hacim Bonusu Oranı (%):'
      },
      volumeBonusThreshold: {
        de: 'Volumen Bonus Schwelle (MFT):',
        tr: 'Hacim Bonusu Eşiği (MFT):'
      },
      leadershipBonusAmount: {
        de: 'Leadership Bonus pro Mitglied (MFT):',
        tr: 'Üye Başına Liderlik Bonusu (MFT):'
      },
      leadershipBonusThreshold: {
        de: 'Leadership Bonus Schwelle (Mitglieder):',
        tr: 'Liderlik Bonusu Eşiği (Üye):'
      },
      
      // Komisyon ayarları
      price: {
        de: 'Preis ($):',
        tr: 'Fiyatı ($):'
      },
      firmDistributionRate: {
        de: 'Firmenverteilungsrate (%):',
        tr: 'Firma Dağıtım Oranı (%):'
      },
      cycleCommissionRate: {
        de: 'Cycle Provisionsrate (%):',
        tr: 'Cycle Komisyon Oranı (%):'
      },
      cycleThreshold: {
        de: 'Cycle Schwellenwert ($):',
        tr: 'Cycle Eşik Değeri ($):'
      },
      cycleAmount: {
        de: 'Cycle Auszahlungsbetrag ($):',
        tr: 'Cycle Ödeme Miktarı ($):'
      },
      directLevel1Rate: {
        de: 'Direkte Provision Level 1 (%):',
        tr: 'Direkt Komisyon Seviye 1 (%):'
      },
      directLevel2Rate: {
        de: 'Direkte Provision Level 2 (%):',
        tr: 'Direkt Komisyon Seviye 2 (%):'
      },
      directLevel3Rate: {
        de: 'Direkte Provision Level 3 (%):',
        tr: 'Direkt Komisyon Seviye 3 (%):'
      },
      directLevel4Rate: {
        de: 'Direkte Provision Level 4 (%):',
        tr: 'Direkt Komisyon Seviye 4 (%):'
      },
      teamCommissionRate: {
        de: 'Team Provisionsrate (%):',
        tr: 'Takım Komisyonu Oranı (%):'
      },
      teamCommissionThreshold: {
        de: 'Team Provisionsschwelle (Mitglieder):',
        tr: 'Takım Komisyonu Eşiği (Üye):'
      },
      matchingBonusRate: {
        de: 'Matching Bonus Rate (%):',
        tr: 'Eşleşme Bonusu Oranı (%):'
      },
      
      saveSettings: {
        de: 'Einstellungen speichern',
        tr: 'Ayarları Kaydet'
      },
      resetSettings: {
        de: 'Zurücksetzen',
        tr: 'Sıfırla'
      },
      
      settingsSaved: {
        de: 'Die Einstellungen wurden erfolgreich gespeichert',
        tr: 'Ayarlar başarıyla kaydedildi'
      },
    };
    
    if (!texts[key]) {
      console.warn(`Missing translation key: ${key}`);
      return key;
    }
    
    return texts[key][language] || key;
  };
  
  // Bonus ayarlarını güncelleme fonksiyonu
  const handleBonusRateChange = (e) => {
    const { name, value } = e.target;
    setBonusRates(prev => ({
      ...prev,
      [name]: parseFloat(value) || 0
    }));
  };
  
  // Bonus ayarlarını varsayılana sıfırlama
  const resetBonusSettings = () => {
    setBonusRates({
      earningsRate: 10,
      balanceBonusRate: 2,
      volumeBonusRate: 1,
      leadershipBonusAmount: 100,
      volumeBonusThreshold: 10000,
      balanceBonusRatio: 80,
      leadershipBonusThreshold: 5
    });
  };
  
  return (
    <Container>
      {/* Confetti animasyonu */}
      {runConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={true}
          numberOfPieces={500}
          gravity={0.15}
          colors={['#F7941D', '#FFD700', '#FF4500', '#4a9cde', '#de4a7e', '#FFFFFF']}
          style={{ position: 'fixed', top: 0, left: 0, zIndex: 99998 }}
        />
      )}
      
      {/* Kutlama modalı */}
      {showCelebration && celebrationNode && (
        <CelebrationModal>
          <CelebrationContent>
            <CelebrationTitle>
              <FaStar /> {getText('congratulations')} <FaStar />
            </CelebrationTitle>
            <CelebrationIcon>
              <FaCrown />
            </CelebrationIcon>
            <CelebrationText>
              <span className="highlight">{celebrationNode.name}</span> {getText('isNowLeader')}
            </CelebrationText>
            <CelebrationText>
              {getText('memberCount')} <span className="highlight">{countTeamMembers(celebrationNode.id) - 1}</span>
            </CelebrationText>
            <CelebrationText>
              {getText('totalEarningsLeader')} <span className="highlight">{formatNumber(calculateEarningsForId(celebrationNode.id), 2)} MFT</span>
              <span className="highlight"> (${formatNumber(calculateEarningsForId(celebrationNode.id) * tokenPrice, 2)})</span>
            </CelebrationText>
            <CelebrationButton onClick={closeCelebration}>
              {getText('greatButton')}
            </CelebrationButton>
          </CelebrationContent>
        </CelebrationModal>
      )}
      
      {/* Tooltip */}
      <Tooltip 
        className={tooltip.show ? 'show' : ''} 
        style={{ 
          left: tooltip.x, 
          top: tooltip.y,
          transform: `translateX(-50%) translateY(${tooltip.show ? '0' : '5px'})` 
        }}
      >
        {tooltip.text}
      </Tooltip>
      
      <ModalContent>
        <ModalHeader style={{ padding: '8px 15px' }}>
          <Title style={{ fontSize: '18px' }}><FaBitcoin /> {getText('title')}</Title>
          <HeaderControls>
            <LanguageSwitch>
              <LanguageButton 
                active={language === 'de'} 
                onClick={() => setLanguage('de')}
                style={{ padding: '5px 10px', fontSize: '12px' }}
              >
                <FaGlobe /> {getText('german')}
              </LanguageButton>
              <LanguageButton 
                active={language === 'tr'} 
                onClick={() => setLanguage('tr')}
                style={{ padding: '5px 10px', fontSize: '12px' }}
              >
                <FaGlobe /> {getText('turkish')}
              </LanguageButton>
            </LanguageSwitch>
          </HeaderControls>
        </ModalHeader>
        
        <BinarySystemContainer>
          {/* İstatistik Şeritleri */}
          <StatsBar style={{ padding: '5px', marginBottom: '5px' }}>
            <StatsSection>
              <h3 style={{ margin: '5px 0', fontSize: '14px' }}><FaChartLine /> {getText('networkOverview')}</h3>
              <StatsRow style={{ marginBottom: '2px' }}>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('totalMembers')}</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('totalMembersInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>{stats.totalNodes}</span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('leftLegVolume')}</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('leftLegVolumeInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    {formatNumber(stats.leftLegVolume)} MFT
                  <span className="usd-value" style={{ fontSize: '11px' }}>
                      (${formatNumber(stats.leftLegVolume * tokenPrice, 2)})
                  </span>
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('rightLegVolume')}</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('rightLegVolumeInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    {formatNumber(stats.rightLegVolume)} MFT
                  <span className="usd-value" style={{ fontSize: '11px' }}>
                      (${formatNumber(stats.rightLegVolume * tokenPrice, 2)})
                  </span>
                </span>
              </StatItem>
              </StatsRow>
              <StatsRow>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('totalVolume')}</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('totalVolumeInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    {formatNumber(stats.totalVolume)} MFT
                  <span className="usd-value" style={{ fontSize: '11px' }}>
                      (${formatNumber(stats.totalVolume * tokenPrice, 2)})
                  </span>
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('weakerLeg')}</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('weakerLegInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    {formatNumber(stats.weakerLeg)} MFT
                  <span className="usd-value" style={{ fontSize: '11px' }}>
                      (${formatNumber(stats.weakerLeg * tokenPrice, 2)})
                  </span>
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('strongerLeg')}</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('strongerLegInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    {formatNumber(stats.strongerLeg)} MFT
                  <span className="usd-value" style={{ fontSize: '11px' }}>
                      (${formatNumber(stats.strongerLeg * tokenPrice, 2)})
                  </span>
                </span>
              </StatItem>
              </StatsRow>
            </StatsSection>
              
            <StatsSection>
              <h3 style={{ margin: '5px 0', fontSize: '14px' }}><FaGift /> {getText('commissionsOverview')}</h3>
              <StatsRow style={{ marginBottom: '2px' }}>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('cycleCommission')}:</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('cycleCommissionInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    ${formatNumber(calculateCycleCommission(rootNode.id), 2)}
                  {calculateCycleCommission(rootNode.id) > 0 && (
                      <Badge color="#28a745" style={{ fontSize: '10px', padding: '1px 3px' }}><FaTrophy />{commissionRates.cycleCommissionRate}%</Badge>
                  )}
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('directCommission')}:</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('directCommissionInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    ${formatNumber(calculateDirectCommission(rootNode.id), 2)}
                  {calculateDirectCommission(rootNode.id) > 0 && (
                      <Badge color="#17a2b8" style={{ fontSize: '10px', padding: '1px 3px' }}><FaCoins />15%</Badge>
                  )}
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('teamCommission')}:</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('teamCommissionInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    ${formatNumber(calculateTeamCommission(rootNode.id), 2)}
                  {calculateTeamCommission(rootNode.id) > 0 && (
                    <Badge color="#6f42c1" style={{ fontSize: '10px', padding: '1px 3px' }}><FaTrophy />{commissionRates.teamCommissionRate}%</Badge>
                  )}
                </span>
              </StatItem>
              </StatsRow>
              <StatsRow>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('matchingBonus')}:</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('matchingBonusInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value" style={{ fontSize: '13px' }}>
                    ${formatNumber(calculateMatchingBonus(rootNode.id), 2)}
                  {calculateMatchingBonus(rootNode.id) > 0 && (
                    <Badge color="#dc3545" style={{ fontSize: '10px', padding: '1px 3px' }}><FaCoins />{commissionRates.matchingBonusRate}%</Badge>
                  )}
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('totalCommission')}:</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('totalCommissionInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                <span className="value highlight" style={{ fontSize: '13px' }}>
                    ${formatNumber(calculateTotalCommissions(rootNode.id).total, 2)}
                </span>
              </StatItem>
              <StatItem style={{ padding: '3px 6px' }}>
                  <div className="label-container">
                    <span className="label" style={{ fontSize: '12px' }}>{getText('totalIncome')}:</span>
                    <FaInfoCircle 
                      className="info-icon" 
                      onMouseEnter={(e) => showTooltip('totalIncomeInfo', e)}
                      onMouseLeave={hideTooltip}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                  <span className="value highlight" style={{ fontSize: '13px' }}>
                    ${formatNumber(calculateTotalCommissions(rootNode.id).total, 2)}
                  </span>
                </StatItem>
              </StatsRow>
            </StatsSection>
          </StatsBar>
          
          <MainContent>
            {/* Test butonu */}
            <button 
              onClick={testCreateLeader}
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 1000,
                background: '#F7941D',
                color: 'white',
                border: 'none',
                padding: '8px 15px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
            >
              {getText('testLeaderButton')}
            </button>
            
            <BinaryTreeContainer zoomLevel={zoomLevel}>
              {renderBinaryTree('root')}
            </BinaryTreeContainer>
          </MainContent>
          
          {/* Bilgi Bölümü */}
          <InfoSection>
            <InfoTitle>
              <span className="title-text">
                <FaQuestionCircle /> {getText('howItWorks')}
              </span>
              <InfoToggleButton onClick={toggleInfoSection} title={isInfoMinimized ? "Erweitern" : "Minimieren"}>
                {isInfoMinimized ? <FaExpandAlt /> : <FaCompressAlt />}
              </InfoToggleButton>
            </InfoTitle>
            <InfoText isMinimized={isInfoMinimized}>
              {getText('howItWorksInfo')}
            </InfoText>
          </InfoSection>
          
          {/* Zoom Kontrolleri */}
          <ZoomControls>
            <ZoomButton onClick={zoomIn} disabled={zoomLevel >= 2.0} title="Vergrößern">
              <FaSearchPlus />
            </ZoomButton>
            <ZoomButton onClick={resetZoom} title="Normale Größe">
              1x
            </ZoomButton>
            <ZoomButton onClick={zoomOut} disabled={zoomLevel <= 0.6} title="Verkleinern">
              <FaSearchMinus />
            </ZoomButton>
          </ZoomControls>
          
          {/* Havada duran kart */}
          <FloatingCard>
            <FloatingCardHeader>
              <h3>
                {activeNode ? (
                  <>
                    <FaUserPlus /> {getText('selectedPerson')}: <span style={{ color: '#fff' }}>{activeNode.name}</span>
                  </>
                ) : (
                  <>
                    <FaUserPlus /> {activeTab === 'addMember' ? getText('addNewPerson') : getText('commissionSettings')}
                  </>
                )}
              </h3>
              <ToggleButton onClick={() => setIsFloatingCardOpen(!isFloatingCardOpen)}>
                {isFloatingCardOpen ? '−' : '+'}
              </ToggleButton>
            </FloatingCardHeader>
            
            {isFloatingCardOpen && (
              <>
                <div style={{ 
                  display: 'flex', 
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  background: 'rgba(0, 0, 0, 0.2)'
                }}>
                  <button 
                    style={{
                      flex: 1,
                      padding: '10px',
                      background: activeTab === 'addMember' ? 'rgba(247, 148, 29, 0.2)' : 'transparent',
                      border: 'none',
                      borderBottom: activeTab === 'addMember' ? '2px solid #F7941D' : 'none',
                      color: activeTab === 'addMember' ? '#F7941D' : 'rgba(255, 255, 255, 0.7)',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease'
                    }}
                    onClick={() => setActiveTab('addMember')}
                  >
                    <FaUserPlus style={{ marginRight: '5px' }} /> {getText('addMember')}
                  </button>
                  <button 
                    style={{
                      flex: 1,
                      padding: '10px',
                      background: activeTab === 'commissionSettings' ? 'rgba(247, 148, 29, 0.2)' : 'transparent',
                      border: 'none',
                      borderBottom: activeTab === 'commissionSettings' ? '2px solid #F7941D' : 'none',
                      color: activeTab === 'commissionSettings' ? '#F7941D' : 'rgba(255, 255, 255, 0.7)',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease'
                    }}
                    onClick={() => setActiveTab('commissionSettings')}
                  >
                    <FaCoins style={{ marginRight: '5px' }} /> {getText('commissionSettings')}
                  </button>
                </div>
                
                <FloatingCardContent style={{ 
                  width: activeTab === 'commissionSettings' ? '900px' : '350px',
                  maxHeight: activeTab === 'commissionSettings' ? '600px' : 'auto',
                  padding: activeTab === 'commissionSettings' ? '10px' : '15px'
                }}>
                  {activeTab === 'addMember' ? (
                    <AddPersonForm onSubmit={handleAddPerson}>
                      <FormGroup>
                        <label>{getText('name')}</label>
                        <Input
                          type="text"
                          placeholder={getText('enterName')}
                          value={formData.name}
                          onChange={(e) => setFormData({...formData, name: e.target.value})}
                          required
                        />
                      </FormGroup>
                      
                      <FormGroup>
                        <label>{getText('mftAmount')}</label>
                        <Input
                          type="text"
                          placeholder={getText('purchasedMft')}
                          value={formData.volume}
                          onChange={(e) => {
                            // Sadece sayılar und noktalar kabul et
                            const value = e.target.value.replace(/[^\d.,]/g, '');
                            
                            // Binlik ayırıcı formatını uygula
                            const numericValue = value.replace(/[.,]/g, '');
                            const formattedValue = numericValue ? parseInt(numericValue, 10).toLocaleString('de-DE') : '';
                            
                            setFormData({...formData, volume: formattedValue});
                          }}
                          required
                        />
                      </FormGroup>
                      
                      <FormGroup>
                        <label>{getText('position')}</label>
                        <Select
                          value={formData.side}
                          onChange={(e) => setFormData({...formData, side: e.target.value})}
                        >
                          <option value="left">{getText('leftLeg')}</option>
                          <option value="right">{getText('rightLeg')}</option>
                        </Select>
                      </FormGroup>
                      
                      <AddButton type="submit">
                        <FaUserPlus /> {getText('add')}
                      </AddButton>
                    </AddPersonForm>
                  ) : (
                    <div>
                      <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        marginBottom: '10px',
                        borderBottom: '1px solid rgba(255,255,255,0.1)',
                        paddingBottom: '8px'
                      }}>
                        <FormTitle style={{ fontSize: '15px', margin: 0 }}>
                          <FaCoins style={{ fontSize: '14px' }} /> {getText('commissionSettings')}
                        </FormTitle>
                        <AddButton 
                          onClick={saveCommissionSettings}
                          style={{
                            background: '#28a745',
                            padding: '6px 12px',
                            fontSize: '13px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        >
                          <FaSave style={{ fontSize: '12px' }} /> {getText('saveSettings')}
                        </AddButton>
                      </div>
                      
                      <SummarySection style={{ marginBottom: '8px', padding: '8px', background: 'rgba(0,0,0,0.2)', borderRadius: '4px' }}>
                        <SummaryTitle style={{ fontSize: '13px', marginBottom: '4px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                          <FaInfoCircle style={{ fontSize: '12px' }} /> {getText('commissionSystemTitle')}
                        </SummaryTitle>
                        <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.7)', margin: 0, lineHeight: '1.4' }}>
                          {getText('commissionSystemInfo')}
                        </p>
                      </SummarySection>
                      
                      <div style={{ 
                        display: 'grid', 
                        gridTemplateColumns: 'repeat(3, 1fr)', 
                        gap: '8px',
                        margin: '0',
                        padding: '8px',
                        background: 'rgba(255,255,255,0.03)',
                        borderRadius: '4px'
                      }}>
                        {/* Token Fiyatı ve Firma Dağıtım Oranı */}
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            MFT Token {getText('price')}
                          </label>
                          <Input
                            type="number"
                            step="0.0001"
                            min="0.0001"
                            value={tempTokenPrice}
                            onChange={(e) => setTempTokenPrice(parseFloat(e.target.value) || 0.005)}
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('firmDistributionRate')}
                          </label>
                          <Input
                            type="number"
                            name="firmDistributionRate"
                            value={tempCommissionRates.firmDistributionRate}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              firmDistributionRate: parseFloat(e.target.value) || 49
                            })}
                            min="0"
                            max="100"
                            step="1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        {/* Cycle Komisyonu */}
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('cycleCommissionRate')}
                          </label>
                          <Input
                            type="number"
                            name="cycleCommissionRate"
                            value={tempCommissionRates.cycleCommissionRate}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              cycleCommissionRate: parseFloat(e.target.value) || 25
                            })}
                            min="0"
                            max="100"
                            step="1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('cycleThreshold')}
                          </label>
                          <Input
                            type="number"
                            name="cycleThreshold"
                            value={tempCommissionRates.cycleThreshold}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              cycleThreshold: parseFloat(e.target.value) || 1000
                            })}
                            min="0"
                            step="100"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('cycleAmount')}
                          </label>
                          <Input
                            type="number"
                            name="cycleAmount"
                            value={tempCommissionRates.cycleAmount}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              cycleAmount: parseFloat(e.target.value) || 500
                            })}
                            min="0"
                            step="100"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                      
                        {/* Direkt Komisyon */}
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('directLevel1Rate')}
                          </label>
                          <Input
                            type="number"
                            name="directLevel1Rate"
                            value={tempCommissionRates.directCommissionRates.level1}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              directCommissionRates: {
                                ...tempCommissionRates.directCommissionRates,
                                level1: parseFloat(e.target.value) || 7
                              }
                            })}
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('directLevel2Rate')}
                          </label>
                          <Input
                            type="number"
                            name="directLevel2Rate"
                            value={tempCommissionRates.directCommissionRates.level2}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              directCommissionRates: {
                                ...tempCommissionRates.directCommissionRates,
                                level2: parseFloat(e.target.value) || 4
                              }
                            })}
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('directLevel3Rate')}
                          </label>
                          <Input
                            type="number"
                            name="directLevel3Rate"
                            value={tempCommissionRates.directCommissionRates.level3}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              directCommissionRates: {
                                ...tempCommissionRates.directCommissionRates,
                                level3: parseFloat(e.target.value) || 3
                              }
                            })}
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('directLevel4Rate')}
                          </label>
                          <Input
                            type="number"
                            name="directLevel4Rate"
                            value={tempCommissionRates.directCommissionRates.level4}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              directCommissionRates: {
                                ...tempCommissionRates.directCommissionRates,
                                level4: parseFloat(e.target.value) || 1
                              }
                            })}
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        {/* Takım Komisyonu */}
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('teamCommissionRate')}
                          </label>
                          <Input
                            type="number"
                            name="teamCommissionRate"
                            value={tempCommissionRates.teamCommissionRate}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              teamCommissionRate: parseFloat(e.target.value) || 6
                            })}
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('teamCommissionThreshold')}
                          </label>
                          <Input
                            type="number"
                            name="teamCommissionThreshold"
                            value={tempCommissionRates.teamCommissionThreshold}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              teamCommissionThreshold: parseInt(e.target.value) || 20
                            })}
                            min="0"
                            step="1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                        
                        {/* Eşleşme Bonusu */}
                        <FormGroup style={{ margin: 0 }}>
                          <label style={{ 
                            marginBottom: '4px', 
                            display: 'block', 
                            fontSize: '12px',
                            color: 'rgba(255,255,255,0.8)'
                          }}>
                            {getText('matchingBonusRate')}
                          </label>
                          <Input
                            type="number"
                            name="matchingBonusRate"
                            value={tempCommissionRates.matchingBonusRate}
                            onChange={(e) => setTempCommissionRates({
                              ...tempCommissionRates,
                              matchingBonusRate: parseFloat(e.target.value) || 3
                            })}
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ 
                              height: '28px', 
                              fontSize: '12px',
                              padding: '4px 8px',
                              width: '100%',
                              background: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.1)'
                            }}
                          />
                        </FormGroup>
                      </div>
                      
                      <div style={{ 
                        display: 'flex', 
                        gap: '8px', 
                        marginTop: '10px',
                        justifyContent: 'flex-end'
                      }}>
                        <AddButton 
                          type="button" 
                          onClick={() => {
                            const defaultRates = {
                              firmDistributionRate: 49,
                              cycleCommissionRate: 25,
                              cycleThreshold: 1000,
                              cycleAmount: 500,
                              directCommissionRates: {
                                level1: 7,
                                level2: 4,
                                level3: 3,
                                level4: 1
                              },
                              teamCommissionRate: 6,
                              teamCommissionThreshold: 20,
                              matchingBonusRate: 3
                            };
                            setTempCommissionRates(defaultRates);
                            setTempTokenPrice(0.005);
                          }}
                          style={{ 
                            background: 'rgba(255, 255, 255, 0.1)',
                            color: 'rgba(255, 255, 255, 0.8)',
                            padding: '6px 12px',
                            fontSize: '12px',
                            height: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        >
                          <FaTimes style={{ fontSize: '10px' }} /> {getText('resetSettings')}
                        </AddButton>
                      </div>
                    </div>
                  )}
                </FloatingCardContent>
              </>
            )}
          </FloatingCard>
        </BinarySystemContainer>
      </ModalContent>
    </Container>
  );
};

export default BinaryNetworkSystemV2Simulator; 