import React, { useState } from 'react';
import styled from 'styled-components';
import NetworkMarketingModalPackage from '../NetworkMarketingModalPackage';

const Container = styled.div`
  padding: 40px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
`;

const Title = styled.h2`
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: #F7941D;
    border-radius: 2px;
  }
`;

const LevelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 0 auto 30px;
  padding: 0 15px;
`;

const LevelCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 25px;
  width: 280px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
  border: 1px solid rgba(247, 148, 29, 0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(247, 148, 29, 0.08);
  }
`;

const LevelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const LevelTitle = styled.h3`
  color: #1a1a1a;
  font-size: 1.15rem;
  font-weight: 600;
  margin: 0;
`;

const MaxParticipants = styled.div`
  color: #F7941D;
  font-size: 0.9rem;
  font-weight: 500;
`;

const Percentage = styled.div`
  font-size: 2.8rem;
  color: #F7941D;
  font-weight: 600;
  line-height: 1.2;
  margin: 20px 0;

  .provision {
    display: block;
    font-size: 0.85rem;
    color: #666;
    font-weight: 400;
    margin-top: 5px;
  }
`;

const InvestmentInfo = styled.div`
  margin: 25px 0;
  padding: 15px;
  background: rgba(247, 148, 29, 0.03);
  border-radius: 8px;
  
  .mft-amount {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 4px;
  }
  
  .usd-amount {
    font-size: 0.95rem;
    color: #666;
  }
`;

const BonusInfo = styled.div`
  margin-top: 15px;
  padding: 8px 15px;
  background: rgba(76, 175, 80, 0.08);
  color: #43a047;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.9rem;
`;

const LevelDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 15px;
`;

const BonusSection = styled.div`
  background: white;
  padding: 30px;
  border-radius: 20px;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
`;

const BonusTitle = styled.h3`
  color: #1a1a1a;
  margin-bottom: 25px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background: #F7941D;
    border-radius: 2px;
  }
`;

const BonusList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 15px;
`;

const BonusItem = styled.div`
  background: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid transparent;

  &:hover {
    border-color: #F7941D;
    transform: translateY(-2px);
  }

  h4 {
    color: #F7941D;
    font-size: 1.1rem;
    margin-bottom: 10px;
    font-weight: 600;
  }

  p {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.4;
  }
`;

const JoinButton = styled.button`
  background: linear-gradient(135deg, #F7941D 0%, #F7941D 100%);
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  margin: 40px auto;
  display: block;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(247, 148, 29, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(247, 148, 29, 0.3);
    background: linear-gradient(135deg, #e88710 0%, #e88710 100%);
  }
`;

const SelectPackageButton = styled.button`
  background: linear-gradient(135deg, #F7941D 0%, #e88710 100%);
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 25px;
  font-weight: 500;
  font-size: 0.95rem;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
  width: 100%;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(247, 148, 29, 0.2);
  }
`;

const NetworkMarketingSystem = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const handlePackageSelect = (level) => {
    setSelectedLevel(level);
    setShowModal(true);
  };

  return (
    <Container>
      <div className="container">
        <Title>MyFutureToken (MFT) Network Marketing System</Title>
        
        <LevelContainer>
          <LevelCard>
            <LevelHeader>
              <LevelTitle>Level 1</LevelTitle>
              <MaxParticipants>Max. 100</MaxParticipants>
            </LevelHeader>
            <Percentage>
              20%
              <span className="provision">Provision</span>
            </Percentage>
            <InvestmentInfo>
              <div className="mft-amount">1.000.000 MFT</div>
              <div className="usd-amount">5.000 USD</div>
            </InvestmentInfo>
            <BonusInfo>+20% MFT Bonus</BonusInfo>
            <SelectPackageButton onClick={() => handlePackageSelect(1)}>
              Paket Wählen
            </SelectPackageButton>
          </LevelCard>

          <LevelCard>
            <LevelHeader>
              <LevelTitle>Level 2</LevelTitle>
              <MaxParticipants>Max. 200</MaxParticipants>
            </LevelHeader>
            <Percentage>
              10%
              <span className="provision">Provision</span>
            </Percentage>
            <InvestmentInfo>
              <div className="mft-amount">600.000 MFT</div>
              <div className="usd-amount">3.000 USD</div>
            </InvestmentInfo>
            <BonusInfo>+10% MFT Bonus</BonusInfo>
            <SelectPackageButton onClick={() => handlePackageSelect(2)}>
              Paket Wählen
            </SelectPackageButton>
          </LevelCard>

          <LevelCard>
            <LevelHeader>
              <LevelTitle>Level 3</LevelTitle>
              <MaxParticipants>Max. 300</MaxParticipants>
            </LevelHeader>
            <Percentage>
              5%
              <span className="provision">Provision</span>
            </Percentage>
            <InvestmentInfo>
              <div className="mft-amount">200.000 MFT</div>
              <div className="usd-amount">1.000 USD</div>
            </InvestmentInfo>
            <BonusInfo>+5% MFT Bonus</BonusInfo>
            <SelectPackageButton onClick={() => handlePackageSelect(3)}>
              Paket Wählen
            </SelectPackageButton>
          </LevelCard>

          <LevelCard>
            <LevelHeader>
              <LevelTitle>Level 4</LevelTitle>
              <MaxParticipants>Max. 300</MaxParticipants>
            </LevelHeader>
            <Percentage>
              5%
              <span className="provision">Provision</span>
            </Percentage>
            <InvestmentInfo>
              <div className="mft-amount">200.000 MFT</div>
              <div className="usd-amount">1.000 USD</div>
            </InvestmentInfo>
            <BonusInfo>+5% MFT Bonus</BonusInfo>
            <SelectPackageButton onClick={() => handlePackageSelect(4)}>
              Paket Wählen
            </SelectPackageButton>
          </LevelCard>
        </LevelContainer>

        <BonusSection>
          <BonusTitle>MFT Network Marketing Vorteile</BonusTitle>
          <BonusList>
            <BonusItem>
              <h4>Automatischer Aufstieg</h4>
              <p>Wenn Level 1 die maximale Kapazität erreicht, werden die Provisionen automatisch auf Level 2 übertragen</p>
            </BonusItem>
            <BonusItem>
              <h4>MFT Bonus</h4>
              <p>Erhalten Sie je nach gewähltem Paket zwischen 5% und 20% zusätzliche MFT Token</p>
            </BonusItem>
            <BonusItem>
              <h4>Großes Netzwerk</h4>
              <p>Bauen Sie je nach Level ein Netzwerk von 100 bis 300 Teilnehmern auf</p>
            </BonusItem>
            <BonusItem>
              <h4>Hohe Rendite</h4>
              <p>Verdienen Sie kontinuierlich 5% bis 20% Provision von Ihrem Downline-Netzwerk</p>
            </BonusItem>
          </BonusList>
        </BonusSection>

      </div>

      <NetworkMarketingModalPackage 
        show={showModal}
        onHide={() => setShowModal(false)}
        selectedLevel={selectedLevel}
      />
    </Container>
  );
};

export default NetworkMarketingSystem; 